import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { PiWarningCircleLight } from "react-icons/pi";
// import { IoMdClose } from "react-icons/io";

// Custom Component
import { PrimaryButton, DangerButton } from '../Button'

const MySwal = withReactContent(Swal)

const ConfirmAlert = (deleteFunction) => {
    MySwal.fire({
        html: (
            <div className='d-flex flex-column align-items-center border border-warning rounded p-3'>
                <div className="d-flex justify-content-between align-items-start w-100">
                    <div className="d-flex flex-column align-items-center flex-grow-1">
                        <PiWarningCircleLight className='text-warning me-2 mb-2' size={40} />
                        <span className='fs-24 fw-600 mb-2'>Are you sure?</span>
                        <span className='fw-600 mb-4'>You won't be able to revert this!</span>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='me-2'>
                                <PrimaryButton className='fs-14 fw-500' onClick={() => {
                                    deleteFunction();
                                }}>Yes, delete it!</PrimaryButton>
                            </div>
                            <div>
                                <DangerButton className='fs-14 fw-500' onClick={() => {
                                    MySwal.close();
                                }}>Cancel</DangerButton>
                            </div>
                        </div>
                    </div>
                    {/* <IoMdClose role='button' className='text-danger' size={20} onClick={() => {
                        MySwal.close();
                    }} /> */}
                </div>
            </div>
        ),
        position: 'top',
        // timer: 3000
    })
}

export default ConfirmAlert;