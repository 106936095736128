import { useState, useEffect } from 'react'
import { Row, Form, InputGroup } from 'react-bootstrap';
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { CSVLink } from "react-csv";
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { debounce } from "lodash";
import classNames from "classnames";

// Custom Component
import NoEarning from "./NoEarning";
import ErrorAlert from '../../components/Alert/ErrorAlert';

// Utils
import { convertEventType, convertAgeGroup } from '../../utils/helper';

// API Service
import { allEarningService } from '../../services/tournament.service';

const MyEarning = (props) => {
    let { tournamentId } = useParams();

    const [earningList, setEarningList] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [totalEntry, setTotalEntry] = useState(1)
    const [selectedGender, setSelectedGender] = useState('');
    const [csvData, setCSVData] = useState([]);

    const dispatch = useDispatch();
    const sidebarShow = useSelector((state) => state.sidebarShow);

    const csvHeaders = [
        {
            label: 'Date and Time',
            key: 'created_at'
        },
        {
            label: 'Username',
            key: 'username'
        },
        {
            label: 'Phone number',
            key: 'phone'
        },
        {
            label: 'Team ID',
            key: 'team_id'
        },
        {
            label: 'Events',
            key: 'event_type'
        },
        {
            label: 'Price',
            key: 'event_price'
        }
    ];

    const { register, watch, setValue } = useForm({
        mode: "onBlur",
        defaultValues: {
            event_type: '',
            keyword: ''
        }
    })

    const watchEventType = watch("event_type")
    const watchKeyword = watch("keyword")

    /**
        * @function handleGender
        * @params gender
        * @description set the value of gender
    */
    const handleGender = (gender) => {
        setSelectedGender(gender)
    }

    /**
        * @function fetchEarningList
        * @params page
        * @description fetch the list of earning
    */
    const fetchEarningList = async (page) => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await allEarningService({
                id: tournamentId,
                gender: selectedGender,
                event_type: watchEventType,
                paginate: 1,
                page,
                perPage: 10,
                keyword: watchKeyword
            });
            if (result?.data?.status) {
                setEarningList(result?.data?.data?.player_lists)
                setTotalEntry(result?.data?.data?.pagination?.total_pages || 1)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
            ErrorAlert(error)
        }
    }

    /**
        * @function fetchAllEarningList
        * @params
        * @description fetch the list of all earning
    */
    const fetchAllEarningList = async () => {
        try {
            const result = await allEarningService({
                id: tournamentId,
                gender: '',
                event_type: '',
                paginate: 0,
                page: '',
                perPage: '',
                keyword: ''
            });
            if (result?.data?.status) {
                if (result?.data?.data?.player_lists?.length > 0) {
                    setCSVData(result?.data?.data?.player_lists?.map(item => {
                        return {
                            created_at: item?.created_at ? moment(item?.created_at).format("MMM DD YYYY, hh:mm A") : '-',
                            username: item?.customer?.username || '-',
                            phone: item?.customer?.phone ? `=""${item?.customer?.phone}""` : '-',
                            team_id: item?.team_id || '-',
                            event_type: (!item?.team_event_type ? `Individual - ${convertEventType(item?.event_type)}` : 'Team - ' + item?.team_event_type.map((teamItem, teamIndex) => {
                                return `(${convertEventType(teamItem)}${(item?.team_event_age_group?.length > 0 && item?.team_event_age_value?.length > 0) ? ` (${convertAgeGroup(item?.team_event_age_group[teamIndex], item?.team_event_age_value[teamIndex])})` : ""})`
                            }).join(" , ")) || '-',
                            event_price: `RM${item?.event_price}` || '-'
                        }
                    }))
                } else {
                    setCSVData([])
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (tournamentId) {
            fetchEarningList(currentPage + 1);
        }
    }, [tournamentId, currentPage])

    useEffect(() => {
        if (tournamentId) {
            fetchAllEarningList();
        }
    }, [tournamentId])

    useEffect(() => {
        if (tournamentId) {
            if (currentPage === 0) {
                fetchEarningList(currentPage + 1);
            } else {
                setCurrentPage(0)
            }
        }
    }, [tournamentId, watchEventType, watchKeyword, selectedGender])

    const columns = [
        {
            name: 'Date and Time',
            selector: (row) => row?.created_at ? moment(row?.created_at).format("MMM DD YYYY, hh:mm A") : '-',
            sortable: true
        },
        {
            name: 'Name',
            selector: (row) => row?.customer?.username ? <span className='fs-14 fw-600 text-capitalize'>{row?.customer?.username}</span> : '-',
            sortable: true
        },
        {
            name: 'Phone number',
            selector: (row) => row?.customer?.phone ? <span className='fs-14 text-secondary'>{row?.customer?.phone}</span> : '-',
            sortable: true
        },
        {
            name: 'Gender',
            selector: (row) => row?.customer?.gender ? <span className='fs-14 text-secondary'>{row?.customer?.gender === 'MALE' ? "Male" : 'Female'}</span> : '-',
            sortable: true
        },
        {
            name: 'Events',
            selector: (row) => row.event_type ? <div className='d-flex flex-column align-items-start fs-14 fw-400 text-secondary'>
                {(props?.tournament?.category_type === "INDIVIDUAL") ?
                    <span>Individual - </span>
                    :
                    <span>Team - </span>
                }
                {(row?.team_event_type) ?
                    <span>{row?.team_event_type?.map((teamItem, teamIndex) => {
                        return `(${convertEventType(teamItem)}${(row?.team_event_age_group?.length > 0 && row?.team_event_age_value?.length > 0) ? ` (${convertAgeGroup(row?.team_event_age_group[teamIndex], row?.team_event_age_value[teamIndex])})` : ""})`
                    })?.join(", ")}</span>
                    :
                    <span>
                        {row?.event_type && `(${convertEventType(row?.event_type)}${(row?.age_group && row?.age_value) ? ` (${convertAgeGroup(row?.age_group, row?.age_value)})` : ""})`}
                    </span>
                }
            </div> : '-',
            sortable: true
        },
        {
            name: 'Price',
            selector: (row) => row?.event_price ? <span className='fs-14 fw-600 text-capitalize'>RM{row?.event_price}</span> : '-',
            sortable: true
        }
    ]

    /**
        * @function handlePagination
        * @params page
        * @description used to handle Pagination
    */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
    }

    /**
        * @function CustomPagination
        * @params
        * @description used for Custom Pagination
    */
    const CustomPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={totalEntry || 1}
            onPageChange={page => handlePagination(page)}
            containerClassName='d-none d-lg-flex pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
        />
    )

    return <Row className='my-3'>
        <div className='d-flex flex-column align-items-start mb-3'>
            <div className='d-flex justify-content-between justify-content-lg-start align-items-center mb-3 w-100'>
                <div className='d-flex align-items-center flex-grow-1'>
                    <span className='fs-24 fw-600 me-2'>Earnings</span>
                    <span className='fs-12 fw-600 text-orange px-2 py-1 bg-danger bg-opacity-10 rounded me-5'>{csvData?.length || earningList?.length || 0} earnings</span>
                </div>
                <CSVLink className="text-decoration-none" filename={`${props?.tournament?.name || "my"}-Earnings.csv`} data={csvData} headers={csvHeaders}>
                    <span role='button' className='fs-12 fw-600 text-orange px-2 py-1 bg-danger bg-opacity-10 rounded'>Export CSV</span>
                </CSVLink>
            </div>
            <div className='d-flex flex-column flex-md-row justify-content-md-between align-items-md-center flex-grow-1 w-100 mb-3'>
                <div className='d-flex flex-column flex-md-row align-items-md-center flex-grow-1 mb-3 mb-md-0'>
                    <div className='d-flex align-items-center fs-14 text-secondary mb-3 mb-md-0 me-3'>
                        <span role='button' className={classNames('me-2', {
                            "text-orange fw-600": selectedGender === ''
                        })} onClick={() => handleGender('')}>All</span>
                        <span role='button' className={classNames('me-2', {
                            "text-orange fw-600": selectedGender === 'MALE'
                        })} onClick={() => handleGender('MALE')}>Male</span>
                        <span role='button' className={classNames({
                            "text-orange fw-600": selectedGender === 'FEMALE'
                        })} onClick={() => handleGender('FEMALE')}>Female</span>
                    </div>
                    <Form.Group className='me-md-3'>
                        <Form.Select
                            {...register("event_type")}
                        >
                            <option value="">Events</option>
                            <option value="MEN_SINGLE">Men Single</option>
                            <option value="WOMEN_SINGLE">Women Single</option>
                            <option value="MEN_DOUBLE">Men Double</option>
                            <option value="WOMEN_DOUBLE">Women Double</option>
                            <option value="MIX_DOUBLE">Mix Double</option>
                            <option value="MEN_3_3">Men 3 on 3</option>
                            <option value="WOMEN_3_3">Women 3 on 3</option>
                            <option value="MIX_3_3">Mix 3 on 3</option>
                            <option value="JUNIOR_SINGLE">Junior Single</option>
                            <option value="JUNIOR_DOUBLE">Junior Double</option>
                        </Form.Select>
                    </Form.Group>
                </div>
                <div className='w-xs-100 tournament-input'>
                    <InputGroup className="border rounded-3">
                        <InputGroup.Text className='bg-transparent border-0'>
                            <FiSearch />
                        </InputGroup.Text>
                        <Form.Control
                            className='bg-transparent border-0 rounded-3 px-0 search-input'
                            placeholder='Search user name, phone number,'
                            {...register("keyword")}
                            onChange={debounce((e) => {
                                setValue('keyword', e.target.value)
                            }, 500)}
                        />
                    </InputGroup>
                </div>
            </div>
        </div>
        {earningList.length > 0 ?
            <>
                <DataTable
                    className='d-none d-lg-block'
                    columns={columns}
                    data={earningList}
                    pagination
                    paginationServer
                    paginationTotalRows={10}
                    paginationPerPage={10}
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    paginationComponent={CustomPagination}
                    paginationDefaultPage={currentPage + 1}
                />
                <div>
                    {earningList?.map((item, index) => {
                        return <div className='d-flex d-lg-none flex-column align-items-start border rounded px-2 py-3 mb-3' key={index}>
                            <div className='d-flex justify-content-between align-items-center w-100'>
                                <span className='fs-14 fw-600 text-capitalize text-truncate'>{item?.customer?.username}</span>
                            </div>
                            <div className='d-flex align-items-center w-100 mt-1'>
                                <span className='fs-14 fw-400 text-secondary'>{moment(item?.created_at).format("MMM DD YYYY, hh:mm A")}</span>
                            </div>
                            <div className='d-flex align-items-center w-100 mt-1'>
                                <span className='fs-14 fw-400 text-secondary me-4'>{item?.customer?.gender === 'MALE' ? "Male" : 'Female'}</span>
                            </div>
                            <div className='d-flex align-items-center w-100 mt-1 fs-14 fw-400 text-secondary'>
                                {(item?.team_event_type) ?
                                    <span>Team - </span>
                                    :
                                    <span>Individual - </span>
                                }
                                {(item?.team_event_type) ?
                                    <span>{item?.team_event_type?.map((teamItem, teamIndex) => {
                                        return `(${convertEventType(teamItem)}${(item?.team_event_age_group?.length > 0 && item?.team_event_age_value?.length > 0) ? ` (${convertAgeGroup(item?.team_event_age_group[teamIndex], item?.team_event_age_value[teamIndex])})` : ""})`
                                    })?.join(", ")}</span>
                                    :
                                    <span>
                                        {item?.event_type && `(${convertEventType(item?.event_type)}${(item?.age_group && item?.age_value) ? ` (${convertAgeGroup(item?.age_group, item?.age_value)})` : ""})`}
                                    </span>
                                }
                            </div>
                            <div className='d-flex justify-content-end align-items-center w-100 mt-1'>
                                <span className='fs-14 fw-600'>Price : RM{item?.event_price}</span>
                            </div>
                        </div>
                    })}
                    <ReactPaginate
                        nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                            <span className='fs-14 fw-600 me-2'>Next</span>
                            <AiOutlineArrowRight className='text-secondary' size={20} />
                        </div>}
                        breakLabel='...'
                        previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                            <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                            <span className='fs-14 fw-600'>Previous</span>
                        </div>}
                        pageRangeDisplayed={3}
                        forcePage={currentPage}
                        marginPagesDisplayed={3}
                        activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
                        pageClassName='page-item me-3 d-flex align-items-center text-secondary'
                        breakClassName='page-item me-3 d-flex align-items-center'
                        nextClassName='page-item next-item flex-grow-1 text-end'
                        previousClassName='page-item prev-item flex-grow-1 me-3'
                        disabledLinkClassName='btn disabled p-0 border-0'
                        pageCount={totalEntry || 1}
                        onPageChange={page => handlePagination(page)}
                        containerClassName='d-lg-none pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
                    />
                </div>
            </>
            :
            <NoEarning />
        }
    </Row>
};

export default MyEarning;