import { useState, useEffect } from 'react'
import { CContainer, CCol, CRow, CCard, CCardHeader } from '@coreui/react'
import { Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { FaTrashAlt, FaRegEdit } from "react-icons/fa";
import moment from 'moment'

// Custom Component
import Add from './Add'
import Edit from './Edit'
import { PrimaryButton } from '../../components/Button';
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';
import ConfirmAlert from '../../components/Alert/ConfirmAlert';

// API Service
import { allBankService, bankStatusService, deleteBankService } from '../../services/bank.service';

const Bank = () => {
    const [bankList, setBankList] = useState([])
    const [selectedData, setSelectedData] = useState(null);
    const [currentPage, setCurrentPage] = useState(0)
    const [totalEntry, setTotalEntry] = useState(1)
    const countPerPage = 10;

    const [addModal, setAddModal] = useState(false)
    const [editModal, setEditModal] = useState(false)

    const dispatch = useDispatch()
    const sidebarShow = useSelector((state) => state.sidebarShow)

    /**
        * @function fetchListedBank
        * @params page
        * @description fetch the list of bank
    */
    const fetchListedBank = async (page) => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await allBankService({
                paginate: 1,
                page: page + 1,
                perPage: 10,
                keyword: ""
            });
            if (result?.data?.status) {
                setBankList(result?.data?.data?.bank_list)
                setTotalEntry(result?.data?.data?.pagination?.total_pages)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    useEffect(() => {
        fetchListedBank(currentPage)
    }, [])

    /**
        * @function handleAddModal
        * @params
        * @description set the visibility of add modal
    */
    const handleAddModal = () => {
        setAddModal(!addModal)
    }

    /**
        * @function handleEditModal
        * @params row
        * @description set the visibility of edit modal
    */
    const handleEditModal = (row) => {
        setEditModal(!editModal)
        setSelectedData(row)
    }

    /**
        * @function statusUpdate
        * @params rowRecord
        * @description used to change status
    */
    const statusUpdate = async (rowRecord) => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await bankStatusService(rowRecord?.id);
            if (result?.data?.status) {
                SuccessAlert('Status updated successfully')
                fetchListedBank(currentPage)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    /**
        * @function deleteBank
        * @params id
        * @description delete the bank
    */
    const deleteBank = async (id) => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await deleteBankService(id);
            if (result?.data?.status) {
                SuccessAlert('Bank deleted successfully')
                fetchListedBank(currentPage)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    const columns = [
        {
            name: 'ID',
            selector: (row, index) => currentPage > 0 ? (currentPage * 10) + index + 1 : index + 1
        },
        {
            name: 'Image',
            selector: (row) => row?.image ? <img className='img-fluid' src={row?.image} alt={row?.name} width={80} /> : '--',
            sortable: true
        },
        {
            name: 'Name',
            selector: (row) => row?.name ? row?.name : '--',
            sortable: true
        },
        {
            name: 'Status',
            selector: (row) => (
                <Form.Check
                    type="switch"
                    id="success-switch"
                    checked={row?.is_enabled}
                    onChange={() => statusUpdate(row)}
                    defaultChecked={row?.is_enabled}
                />
            )
        },
        {
            name: 'Created Date',
            selector: (row) => moment(row.created_at).format("DD-MM-YYYY")
        },
        {
            name: 'Actions',
            allowOverflow: true,
            selector: (row) => <>
                <FaTrashAlt role='button' className='text-danger me-2' size={20} onClick={() => ConfirmAlert(() => deleteBank(row?.id))} />
                <FaRegEdit role='button' className='text-orange' size={20} onClick={() => handleEditModal(row)} />
            </>
        }
    ]

    /**
        * @function handlePagination
        * @params page
        * @description used to handle Pagination
    */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
        fetchListedBank(page.selected)
    }

    // ** Custom Pagination
    const CustomPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={totalEntry || 1}
            onPageChange={page => handlePagination(page)}
            containerClassName='pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
        />
    )

    return <div className="bg-light min-vh-100 d-flex flex-row">
        <CContainer fluid>
            <CRow>
                <CCol xs={12} className='px-0'>
                    <CCard className="mb-4">
                        <CCardHeader className='bg-transparent border-0 text-dark d-flex justify-content-between align-items-center'>
                            <h5>Bank Management</h5>
                            <div>
                                <PrimaryButton onClick={handleAddModal}>Add Bank</PrimaryButton>
                            </div>
                        </CCardHeader>
                    </CCard>
                    <div className='react-dataTable react-dataTable-selectable-rows'>
                        <DataTable
                            noHeader
                            columns={columns}
                            className='react-dataTable'
                            data={bankList}
                            pagination
                            paginationServer
                            paginationTotalRows={totalEntry}
                            paginationPerPage={countPerPage}
                            paginationComponentOptions={{ noRowsPerPage: true }}
                            paginationComponent={CustomPagination}
                            paginationDefaultPage={currentPage + 1}
                        />
                    </div>
                </CCol>
            </CRow>
        </CContainer>

        {/* Add Modal */}
        <Add show={addModal} handleClose={handleAddModal} pageCount={currentPage} fetchListedBank={fetchListedBank} />

        {/* Edit Modal */}
        <Edit show={editModal} handleClose={handleEditModal} selectedData={selectedData} pageCount={currentPage} fetchListedBank={fetchListedBank} />
    </div>
};

export default Bank;
