import axios from "axios"

/**
    * @function allSubOrganizerService
    * @param { organizerId, paginate, page, perPage, keyword }
    * @description This function is used to get all suboraganizer
*/
export const allSubOrganizerService = async({ organizerId, paginate, page, perPage, keyword }) =>{
    try {
        const result = await axios.get(`/admin/organizer/sub-organizer/list/${organizerId}?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        return result;
    } catch (error) {
        return error;
    }
}

