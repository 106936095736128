import { CContainer, CCol, CRow, CCard, CCardHeader } from '@coreui/react'
import { Link } from 'react-router-dom'

// Custom Component
import { PrimaryOutlineButton } from '../../components/Button'

const String = () => {
    return <div className="bg-light min-vh-100 d-flex flex-row">
        <CContainer fluid>
            <CRow>
                <CCol xs={12} className='px-0 text-center d-flex flex-column align-items-center'>
                    <CCard className="mb-4 w-100">
                        <CCardHeader className='bg-transparent border-0 text-dark d-flex justify-content-between align-items-center'>
                            <h5>String Management</h5>
                        </CCardHeader>
                    </CCard>
                    <Link to='/customer-variable/management'>
                        <div className='d-inline-block mt-5'>
                            <PrimaryOutlineButton className='px-5'>Customer</PrimaryOutlineButton>
                        </div>
                    </Link>
                    <Link to='/organizer-variable/management'>
                        <div className='d-inline-block mt-4'>
                            <PrimaryOutlineButton className='px-5'>Organizer</PrimaryOutlineButton>
                        </div>
                    </Link>
                </CCol>
            </CRow>
        </CContainer>
    </div>
};

export default String;
