import { useState, useEffect } from 'react'
import { CContainer, CCol, CRow, CCard, CCardHeader } from '@coreui/react'
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import { CSVLink } from "react-csv";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'

// Custom Component
import { PrimaryButton } from '../../components/Button';
import SearchInput from '../../components/SearchInput/SearchInput';
import ErrorAlert from '../../components/Alert/ErrorAlert';

// API Service
import { allTransactionService } from '../../services/transaction.service';

const Transaction = () => {
    let { organizerId } = useParams();

    const [transactionList, setTransactionList] = useState([])
    const [searchTag, setSearchTag] = useState("")
    const [currentPage, setCurrentPage] = useState(0)
    const [totalEntry, setTotalEntry] = useState(1)
    const [csvData, setCSVData] = useState([]);
    const countPerPage = 10;

    const dispatch = useDispatch()
    const sidebarShow = useSelector((state) => state.sidebarShow)

    const csvHeaders = [
        {
            label: 'Id',
            key: 'id'
        },
        {
            label: 'Tournament',
            key: 'tournament'
        },
        {
            label: 'Organizer',
            key: 'organizer'
        },
        {
            label: 'User',
            key: 'user'
        },
        {
            label: 'User Email',
            key: 'email'
        },
        {
            label: 'User Phone',
            key: 'phone'
        },
        {
            label: 'Amount',
            key: 'amount'
        },
        {
            label: 'Type',
            key: 'type'
        },
        {
            label: 'Created Date',
            key: 'created_at'
        }
    ];

    /**
        * @function fetchListedTransaction
        * @params page
        * @description fetch the list of transaction
    */
    const fetchListedTransaction = async (page) => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await allTransactionService({
                organizerId,
                paginate: 1,
                page: page + 1,
                perPage: 10,
                keyword: searchTag
            });
            if (result?.data?.status) {
                setTransactionList(result?.data?.data?.wallet_transactions?.data)
                setTotalEntry(result?.data?.data?.pagination?.total_pages)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    /**
        * @function fetchAllTransactionList
        * @params
        * @description fetch the list of all transaction
    */
    const fetchAllTransactionList = async () => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await allTransactionService({
                organizerId,
                paginate: 0,
                page: '',
                perPage: '',
                keyword: ''
            });
            if (result?.data?.status) {
                setCSVData(result?.data?.data?.wallet_transactions?.map((item, index) => {
                    return {
                        id: index + 1,
                        tournament: item?.tournament?.name,
                        organizer: item?.organizer?.name,
                        user: item?.customer?.username,
                        email: item?.customer?.email,
                        phone: item?.customer?.phone,
                        amount: item?.amount,
                        type: item?.transaction_type === 'DEPOSIT' ? 'Earned' : 'Withdrawn',
                        created_at: moment(item?.created_at).format("DD-MM-YYYY")
                    }
                }))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    useEffect(() => {
        fetchListedTransaction(currentPage)
    }, [searchTag])

    useEffect(() => {
        fetchAllTransactionList()
    }, [])

    const columns = [
        {
            name: 'ID',
            selector: (row, index) => currentPage > 0 ? (currentPage * 10) + index + 1 : index + 1,
            width: '80px'
        },
        {
            name: 'Tournament',
            selector: (row) => row?.tournament?.name ? row?.tournament?.name : '--',
            wrap: true,
            sortable: true
        },
        {
            name: 'Organizer',
            selector: (row) => row?.organizer?.name ? row?.organizer?.name : '--',
            wrap: true,
            sortable: true
        },
        {
            name: 'User',
            selector: (row) => row?.customer?.username ? row?.customer?.username : '--',
            wrap: true,
            sortable: true
        },
        {
            name: 'User Email',
            selector: (row) => row?.customer?.email ? row?.customer?.email : '--',
            wrap: true,
            sortable: true
        },
        {
            name: 'User Phone',
            selector: (row) => row?.customer?.phone ? row?.customer?.phone : '--',
            wrap: true,
            width: '150px',
            sortable: true
        },
        {
            name: 'Amount',
            selector: (row) => row?.amount ? 'RM' + parseFloat(row?.amount)?.toFixed(2) : '--',
            wrap: true,
            width: '100px',
            sortable: true
        },
        {
            name: 'Type',
            selector: (row) => row?.transaction_type ? row.transaction_type === 'DEPOSIT' ? 'Earned' : 'Withdrawn' : '--',
            sortable: true
        },
        {
            name: 'Created Date',
            selector: (row) => moment(row.created_at).format("DD-MM-YYYY")
        }
    ]

    /**
        * @function handlePagination
        * @params page
        * @description used to handle Pagination
    */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
        fetchListedTransaction(page.selected)
    }

    /**
        * @function searchTransaction
        * @params searchTerm
        * @description used to set the value of search tag
    */
    const searchTransaction = (searchTerm) => {
        setCurrentPage(0)
        setSearchTag(searchTerm)
    }

    // ** Custom Pagination
    const CustomPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            brealabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={totalEntry || 1}
            onPageChange={page => handlePagination(page)}
            containerClassName='pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
        />
    )

    return <div className="bg-light min-vh-100 d-flex flex-row">
        <CContainer fluid>
            <CRow>
                <CCol xs={12} className='px-0'>
                    <CCard className="mb-4">
                        <CCardHeader className='bg-transparent border-0 text-dark d-flex justify-content-between align-items-center'>
                            <h5>Transaction History Management</h5>
                            <div className='d-flex align-items-center'>
                                <div className='me-2'>
                                    {csvData?.length > 0 &&
                                        <CSVLink filename={`transaction.csv`} data={csvData} headers={csvHeaders}>
                                            <PrimaryButton>Export</PrimaryButton>
                                        </CSVLink>
                                    }
                                </div>
                                <Form id='search-form' noValidate>
                                    <SearchInput placeholder='Search Transaction' search={searchTransaction} />
                                </Form>
                            </div>
                        </CCardHeader>
                    </CCard>
                    <div className='react-dataTable react-dataTable-selectable-rows'>
                        <DataTable
                            noHeader
                            columns={columns}
                            className='react-dataTable'
                            data={transactionList}
                            pagination
                            paginationServer
                            paginationTotalRows={totalEntry}
                            paginationPerPage={countPerPage}
                            paginationComponentOptions={{ noRowsPerPage: true }}
                            paginationComponent={CustomPagination}
                            paginationDefaultPage={currentPage + 1}
                        />
                    </div>
                </CCol>
            </CRow>
        </CContainer>
    </div>
};

export default Transaction;
