import Dashboard from './pages/dashboard/Dashboard';
import User from './pages/user/User';
import Country from './pages/country/Country';
import Banner from './pages/banner/Banner';
import Organizer from './pages/organizer/Organizer';
import Tournament from './pages/tournament/Tournament';
import SingleTournament from './pages/singleTournament/SingleTournament';
import Bank from './pages/bank/Bank';
import Withdraw from './pages/withdraw/Withdraw';
import Transaction from './pages/transaction/Transaction';
import SubOrganizer from './pages/suborganizer/SubOrganizer';
import String from './pages/string/String';
import OrganizerString from './pages/organizerString/OrganizerString';
import CustomerString from './pages/customerString/CustomerString';
import Message from './pages/message/Message';

const routes = [
    {
        path: '/',
        exact: true,
        name: 'Home'
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        element: Dashboard
    },
    {
        path: '/user/management',
        name: 'User',
        element: User,
        exact: true
    },
    {
        path: '/country/management',
        name: 'Country',
        element: Country,
        exact: true
    },
    {
        path: '/banner/management',
        name: 'Banner',
        element: Banner,
        exact: true
    },
    {
        path: '/organizer/management',
        name: 'Organizer',
        element: Organizer,
        exact: true
    },
    {
        path: '/tournament/management',
        name: 'Tournament',
        element: Tournament,
        exact: true
    },
    {
        path: '/tournament/single/:tournamentId',
        name: 'Tournament Details',
        element: SingleTournament,
        exact: true
    },
    {
        path: '/bank/management',
        name: 'Bank',
        element: Bank,
        exact: true
    },
    {
        path: '/withdraw/management',
        name: 'Withdraw',
        element: Withdraw,
        exact: true
    },
    {
        path: '/transaction/:organizerId',
        name: 'Transaction',
        element: Transaction,
        exact: true
    },
    {
        path: '/sub-organizer/:organizerId',
        name: 'SubOrganizer',
        element: SubOrganizer,
        exact: true
    },
    {
        path: '/string/management',
        name: 'String',
        element: String,
        exact: true
    },
    {
        path: '/organizer-variable/management',
        name: 'Organizer String',
        element: OrganizerString,
        exact: true
    },
    {
        path: '/customer-variable/management',
        name: 'Customer String',
        element: CustomerString,
        exact: true
    },
    {
        path: '/message/management',
        name: 'Message',
        element: Message,
        exact: true
    }
]

export default routes
