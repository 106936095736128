const LoginSVG = () => {
    return <svg width="747px" height="547px" viewBox="0 0 747 547" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
            <path d="M5.388768,0 L130.412508,0 C133.388643,-1.43488544e-15 135.801276,2.41263361 135.801276,5.388768 L135.801276,46.0387744 C135.801276,49.0149088 133.388643,51.4275424 130.412508,51.4275424 L5.388768,51.4275424 C2.41263361,51.4275424 2.55312201e-15,49.0149088 0,46.0387744 L0,5.388768 C-1.25264976e-15,2.41263361 2.41263361,-2.11782824e-15 5.388768,0 Z" id="path-1"></path>
            <filter x="-28.4%" y="-72.9%" width="168.5%" height="280.8%" filterUnits="objectBoundingBox" id="filter-2">
                <feOffset dx="8" dy="9" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="14" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0.0755212756   0 0 0 0 0.582569014   0 0 0 0 0.878793025  0 0 0 0.229458042 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
                <feGaussianBlur stdDeviation="7.5" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0.50398247   0 0 0 0 0.700178215   0 0 0 0 1  0 0 0 0.233828671 0" type="matrix" in="shadowBlurOuter2" result="shadowMatrixOuter2"></feColorMatrix>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                    <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
                </feMerge>
            </filter>
            <path d="M5,0 L163.949198,0 C166.710622,3.80913106e-16 168.949198,2.23857625 168.949198,5 L168.949198,137.679592 C168.949198,140.441016 166.710622,142.679592 163.949198,142.679592 L5,142.679592 C2.23857625,142.679592 3.38176876e-16,140.441016 0,137.679592 L0,5 C-3.38176876e-16,2.23857625 2.23857625,1.39544373e-15 5,0 Z" id="path-3"></path>
            <filter x="-22.8%" y="-26.3%" width="155.0%" height="165.2%" filterUnits="objectBoundingBox" id="filter-5">
                <feOffset dx="8" dy="9" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="14" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0.0755212756   0 0 0 0 0.582569014   0 0 0 0 0.878793025  0 0 0 0.229458042 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
                <feGaussianBlur stdDeviation="7.5" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0.50398247   0 0 0 0 0.700178215   0 0 0 0 1  0 0 0 0.233828671 0" type="matrix" in="shadowBlurOuter2" result="shadowMatrixOuter2"></feColorMatrix>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                    <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
                </feMerge>
            </filter>
            <path d="M22.6524064,109.608163 C36.0424523,105.75851 45.6894817,99.9049002 51.5934946,92.0473343 C60.4495139,80.2609855 63.4351742,68.8366467 78.6666599,68.8366467 C93.8981456,68.8366467 95.8510232,86.3337998 112.316621,86.3337998 C128.782219,86.3337998 129.682684,64.0202266 135.155236,55.1601133 C138.803604,49.2533711 143.775923,46.3 150.072193,46.3" id="path-6"></path>
            <filter x="-24.7%" y="-28.4%" width="149.0%" height="198.7%" filterUnits="objectBoundingBox" id="filter-7">
                <feMorphology radius="2.002" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
                <feOffset dx="0" dy="13" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                <feMorphology radius="2.002" operator="erode" in="SourceAlpha" result="shadowInner"></feMorphology>
                <feOffset dx="0" dy="13" in="shadowInner" result="shadowInner"></feOffset>
                <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"></feComposite>
                <feGaussianBlur stdDeviation="7.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.144394668 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
            </filter>
            <path d="M7.4844,0 L232.6968,0 C236.83032,-1.64749372e-15 240.1812,3.35088002 240.1812,7.4844 L240.1812,158.000266 C240.1812,162.133786 236.83032,165.484666 232.6968,165.484666 L7.4844,165.484666 C3.35088002,165.484666 -7.48739558e-15,162.133786 0,158.000266 L0,7.4844 C3.81968218e-16,3.35088002 3.35088002,-5.45793364e-15 7.4844,0 Z" id="path-8"></path>
            <filter x="-8.7%" y="-12.7%" width="122.5%" height="132.6%" filterUnits="objectBoundingBox" id="filter-9">
                <feOffset dx="6" dy="6" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0.701879529   0 0 0 0 0.701879529   0 0 0 0 0.701879529  0 0 0 0.208724869 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
            </filter>
            <linearGradient x1="45.1359293%" y1="-28.1859254%" x2="62.0176018%" y2="121.546626%" id="linearGradient-10">
                <stop stopColor="#FFAFAF" offset="0%"></stop>
                <stop stopColor="#F07777" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="31.7132608%" y1="-6.20629719%" x2="55.1442779%" y2="90.7204601%" id="linearGradient-11">
                <stop stopColor="#FDC6C6" offset="0%"></stop>
                <stop stopColor="#FAB2B2" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="42.3142644%" y1="-3.85998276%" x2="50%" y2="78.2784683%" id="linearGradient-12">
                <stop stopColor="#FDC6C6" offset="0%"></stop>
                <stop stopColor="#FAB2B2" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="28.5029159%" y1="98.6431024%" x2="77.0614974%" y2="9.20431052%" id="linearGradient-13">
                <stop stopColor="#28324F" offset="0%"></stop>
                <stop stopColor="#222735" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="44.359463%" y1="-28.1859254%" x2="50%" y2="91.0504812%" id="linearGradient-14">
                <stop stopColor="#FDC6C6" offset="0%"></stop>
                <stop stopColor="#FAB2B2" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="42.3142644%" y1="28.0624985%" x2="50%" y2="61.5179936%" id="linearGradient-15">
                <stop stopColor="#FDC6C6" offset="0%"></stop>
                <stop stopColor="#FAB2B2" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="70.8290542%" y1="38.1822589%" x2="32.5096572%" y2="84.8636514%" id="linearGradient-16">
                <stop stopColor="#4D63A0" offset="0%"></stop>
                <stop stopColor="#222735" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="68.6694733%" y1="-6.66432423%" x2="68.6694733%" y2="100%" id="linearGradient-17">
                <stop stopColor="#FE62BE" offset="0%"></stop>
                <stop stopColor="#FDB3A8" offset="100%"></stop>
            </linearGradient>
            <path d="M2.67368445,0.442901691 L33.3255838,0.442901691 C34.4389898,0.442901691 35.3415838,1.34549564 35.3415838,2.45890169 C35.3415838,2.51748756 35.33903,2.57604559 35.3339297,2.63440903 L29.4270645,70.2271862 C29.3360564,71.2685997 28.4641012,72.0676788 27.4187187,72.0676788 L8.58054957,72.0676788 C7.53516702,72.0676788 6.66321178,71.2685997 6.57220369,70.2271862 L0.665338569,2.63440903 C0.568408544,1.52523022 1.38899831,0.547485836 2.49817711,0.450555811 C2.55654055,0.445455488 2.61509858,0.442901691 2.67368445,0.442901691 Z" id="path-18"></path>
            <linearGradient x1="42.3142644%" y1="-3.80155206%" x2="50%" y2="78.24779%" id="linearGradient-20">
                <stop stopColor="#FDC6C6" offset="0%"></stop>
                <stop stopColor="#FAB2B2" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="43.4874564%" y1="-28.1859254%" x2="50%" y2="91.0504812%" id="linearGradient-21">
                <stop stopColor="#FDC6C6" offset="0%"></stop>
                <stop stopColor="#FAB2B2" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="45.2488919%" y1="-28.1859254%" x2="61.7385065%" y2="121.546626%" id="linearGradient-22">
                <stop stopColor="#FFAFAF" offset="0%"></stop>
                <stop stopColor="#F07777" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="31.7132608%" y1="-6.14532105%" x2="55.1442779%" y2="90.676284%" id="linearGradient-23">
                <stop stopColor="#FDC6C6" offset="0%"></stop>
                <stop stopColor="#FAB2B2" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="42.3142644%" y1="8.58120408%" x2="50%" y2="71.7463884%" id="linearGradient-24">
                <stop stopColor="#FDC6C6" offset="0%"></stop>
                <stop stopColor="#FAB2B2" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="42.3142644%" y1="22.4182939%" x2="50%" y2="64.4814083%" id="linearGradient-25">
                <stop stopColor="#FDC6C6" offset="0%"></stop>
                <stop stopColor="#FAB2B2" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="68.6694733%" y1="-22.7541031%" x2="68.6694733%" y2="100%" id="linearGradient-26">
                <stop stopColor="#9FF6EC" offset="0%"></stop>
                <stop stopColor="#7D9DFF" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="45.1633771%" y1="-28.1859254%" x2="50%" y2="91.0504812%" id="linearGradient-27">
                <stop stopColor="#FDC6C6" offset="0%"></stop>
                <stop stopColor="#A47070" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="44.1865682%" y1="-28.1859254%" x2="50%" y2="91.0504812%" id="linearGradient-28">
                <stop stopColor="#FDC6C6" offset="0%"></stop>
                <stop stopColor="#A47070" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="43.8358596%" y1="-28.1859254%" x2="50%" y2="91.0504812%" id="linearGradient-29">
                <stop stopColor="#FDC6C6" offset="0%"></stop>
                <stop stopColor="#A47070" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="68.6694733%" y1="-22.7541031%" x2="68.6694733%" y2="100%" id="linearGradient-30">
                <stop stopColor="#9FF6EC" offset="0%"></stop>
                <stop stopColor="#7D9DFF" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="68.6694733%" y1="-22.7541031%" x2="68.6694733%" y2="100%" id="linearGradient-31">
                <stop stopColor="#9FF6EC" offset="0%"></stop>
                <stop stopColor="#7D9DFF" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="46.1884345%" y1="-28.1859254%" x2="50%" y2="91.0504812%" id="linearGradient-32">
                <stop stopColor="#FDC6C6" offset="0%"></stop>
                <stop stopColor="#FFCCA1" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="46.5145022%" y1="-28.1859254%" x2="50%" y2="91.0504812%" id="linearGradient-33">
                <stop stopColor="#FDC6C6" offset="0%"></stop>
                <stop stopColor="#FAB2B2" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="45.6760244%" y1="-28.1859254%" x2="50%" y2="91.0504812%" id="linearGradient-34">
                <stop stopColor="#FDC6C6" offset="0%"></stop>
                <stop stopColor="#FAB2B2" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="44.1878124%" y1="-28.1859254%" x2="50%" y2="91.0504812%" id="linearGradient-35">
                <stop stopColor="#FDC6C6" offset="0%"></stop>
                <stop stopColor="#FFCCA1" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="42.3142644%" y1="-8.45265778%" x2="50%" y2="80.6897913%" id="linearGradient-36">
                <stop stopColor="#FDC6C6" offset="0%"></stop>
                <stop stopColor="#FFCCA1" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g id="📝-Pages-New" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Login-" transform="translate(-113.000000, -271.000000)">
                <g id="Illustration" transform="translate(113.500000, 271.500000)">
                    <g id="Shape" transform="translate(0.500000, 0.500000)">
                        <g id="Group-59" transform="translate(0.000000, 0.818995)">
                            <path d="M673.320496,164.42973 C682.697842,151.911489 700.453642,149.360857 712.979187,158.732736 C725.379475,168.010895 728.00481,185.49599 718.957248,197.991201 L718.679504,198.368303 L644.379725,297.554422 C634.916309,310.187563 617.340382,313.390522 604.040557,304.982961 L603.638854,304.724631 L556.864897,274.128494 L509.705425,342.774231 C500.937414,355.536823 483.564296,358.874417 470.701797,350.351376 L470.313418,350.089469 C457.54338,341.32657 454.203839,323.963582 462.731853,311.108582 L462.993913,310.72043 L524.792998,220.766562 C533.999402,207.36585 552.141193,203.672841 565.84142,212.325168 L566.255205,212.591141 L613.898286,243.755416 L673.320496,164.42973 Z" id="Path" fill="#7367F0" fillRule="nonzero" opacity="0.0845424107"></path>
                            <path d="M430.118007,3.47524559 C432.495605,0.0208430156 437.231582,-0.857710836 440.696106,1.51294064 C444.115045,3.85239933 445.018124,8.48187172 442.756323,11.9231258 L442.664161,12.060134 L424.147685,38.9600355 C421.433138,42.7508086 416.180742,43.663949 412.344581,41.0416044 L412.208184,40.9464449 L401.573583,33.3756019 L390.048198,51.6348483 C387.83905,55.1345722 383.234303,56.2178186 379.695669,54.0995121 L379.556547,54.0142301 C376.046569,51.8115364 374.960148,47.2202425 377.084662,43.6919474 L377.170193,43.5532325 L392.455068,19.3389947 C394.97306,15.3500003 400.257491,14.151554 404.258175,16.6621902 L404.467404,16.7977781 L404.672581,16.9393898 L415.53035,24.6692771 L430.118007,3.47524559 Z" id="Path" fill="#7367F0" fillRule="nonzero" opacity="0.0845424107"></path>
                            <path d="M634.903005,126.988904 L634.903005,109.011803 L634.903005,109.011803 L652.892053,109.011803 C654.272765,109.011803 655.392053,107.892514 655.392053,106.511803 L655.392053,97.8570026 C655.392053,96.4762907 654.272765,95.3570026 652.892053,95.3570026 L634.903005,95.3570026 L634.903005,95.3570026 L634.903005,77.2473306 C634.903005,75.8666188 633.783717,74.7473306 632.403005,74.7473306 L623.607591,74.7473306 C622.226879,74.7473306 621.107591,75.8666188 621.107591,77.2473306 L621.107591,95.3570026 L621.107591,95.3570026 L603.118543,95.3570026 C601.737831,95.3570026 600.618543,96.4762907 600.618543,97.8570026 L600.618543,106.511803 C600.618543,107.892514 601.737831,109.011803 603.118543,109.011803 L621.107591,109.011803 L621.107591,109.011803 L621.107591,126.988904 C621.107591,128.369616 622.226879,129.488904 623.607591,129.488904 L632.403005,129.488904 C633.783717,129.488904 634.903005,128.369616 634.903005,126.988904 Z" id="+" fill="#00CFE8" opacity="0.070265997"></path>
                            <path d="M91.602649,20.1810047 C142.194127,20.1810047 183.206623,61.1695874 183.206623,111.731567 C183.206623,162.293546 142.194127,203.282128 91.602649,203.282128 C41.0111714,203.282128 -0.00132450331,162.293546 -0.00132450331,111.731567 C-0.00132450331,61.1695874 41.0111714,20.1810047 91.602649,20.1810047 Z M91.602649,65.4843755 C66.0461294,65.4843755 45.3284768,86.1899482 45.3284768,111.731567 C45.3284768,137.273185 66.0461294,157.978758 91.602649,157.978758 C117.159169,157.978758 137.876821,137.273185 137.876821,111.731567 C137.876821,86.1899482 117.159169,65.4843755 91.602649,65.4843755 Z" id="Oval" fill="#00CFE8" opacity="0.0739397321"></path>
                        </g>
                    </g>
                    <g id="Meeting" transform="translate(60.500000, 86.500000)">
                        <g id="Group-63" transform="translate(0.596257, 0.000000)">
                            <path d="M16,0 L486.128342,0 C494.964898,2.79941475e-14 502.128342,7.163444 502.128342,16 L502.128342,220.22449 C502.128342,229.061046 494.964898,236.22449 486.128342,236.22449 L16,236.22449 C7.163444,236.22449 -6.94190838e-16,229.061046 0,220.22449 L0,16 C-2.85852284e-15,7.163444 7.163444,1.58341037e-14 16,0 Z" id="Rectangle" fill="#E7FCFF"></path>
                            <path d="M23.5508021,0 L486.128342,0 C494.964898,-3.39960584e-15 502.128342,7.163444 502.128342,16 L502.128342,220.22449 C502.128342,229.061046 494.964898,236.22449 486.128342,236.22449 L23.5508021,236.22449 C14.7142461,236.22449 7.55080214,229.061046 7.55080214,220.22449 L7.55080214,16 C7.55080214,7.163444 14.7142461,1.58341037e-14 23.5508021,0 Z" id="Rectangle" fill="#00CFE8" opacity="0.383370536"></path>
                            <path d="M25.1016043,7.55918367 L484.57754,7.55918367 C490.100388,7.55918367 494.57754,12.0363362 494.57754,17.5591837 L494.57754,218.665306 C494.57754,224.188154 490.100388,228.665306 484.57754,228.665306 L25.1016043,228.665306 C19.5787568,228.665306 15.1016043,224.188154 15.1016043,218.665306 L15.1016043,17.5591837 C15.1016043,12.0363362 19.5787568,7.55918367 25.1016043,7.55918367 Z" id="Rectangle" fill="#E7FCFF"></path>
                            <path d="M220.254011,208.822449 L289.425134,208.822449 C292.738842,208.822449 295.425134,211.50874 295.425134,214.822449 L295.425134,228.665306 L295.425134,228.665306 L214.254011,228.665306 L214.254011,214.822449 C214.254011,211.50874 216.940302,208.822449 220.254011,208.822449 Z" id="Rectangle" fill="#D4EFFF"></path>
                            <path d="M227.804813,208.822449 L296.975936,208.822449 C300.289644,208.822449 302.975936,211.50874 302.975936,214.822449 L302.975936,236.22449 L302.975936,236.22449 L221.804813,236.22449 L221.804813,214.822449 C221.804813,211.50874 224.491104,208.822449 227.804813,208.822449 Z" id="Rectangle" fill="#91D6FF"></path>
                        </g>
                        <g id="ElementCard20" transform="translate(29.855615, 17.008163)">
                            <g id="Rectangle">
                                <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1"></use>
                                <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1"></use>
                            </g>
                            <path d="M41.7084527,29.0234645 L67.1360158,29.0234645 C67.9796588,29.0234645 68.6635666,29.7073723 68.6635666,30.5510153 C68.6635666,31.3946583 67.9796588,32.0785661 67.1360158,32.0785661 L41.7084527,32.0785661 C40.8648097,32.0785661 40.1809019,31.3946583 40.1809019,30.5510153 C40.1809019,29.7073723 40.8648097,29.0234645 41.7084527,29.0234645 Z" id="Rectangle" fill="#C7C1FF" opacity="0.454078311"></path>
                            <path d="M41.7084527,20.3673435 L56.9636356,20.3673435 C57.8072786,20.3673435 58.4911864,21.0512513 58.4911864,21.8948943 C58.4911864,22.7385373 57.8072786,23.4224451 56.9636356,23.4224451 L41.7084527,23.4224451 C40.8648097,23.4224451 40.1809019,22.7385373 40.1809019,21.8948943 C40.1809019,21.0512513 40.8648097,20.3673435 41.7084527,20.3673435 Z" id="Rectangle" fill="#8478FF"></path>
                            <g id="Group-9" transform="translate(78.327328, 13.238773)">
                                <path d="M36.6295477,5.03689567 C38.7994071,2.18249759 42.8698069,1.62952194 45.7210401,3.80178999 C46.3769307,4.30149313 46.9316989,4.92174054 47.3556628,5.62934682 C47.5741328,5.99397883 47.4559729,6.46687306 47.0917452,6.68558564 C46.7275175,6.90429821 46.2551476,6.78600714 46.0366775,6.42137513 C45.7132263,5.88152693 45.2899816,5.40832672 44.7895884,5.02709234 C42.6587139,3.40364372 39.6352878,3.77534548 37.9566359,5.83825039 L37.8534914,5.96938129 L35.4687921,9.10639601 C31.7436714,14.0067029 25.4410378,16.1673621 19.5099378,14.6014645 L19.2559838,14.5320702 L17.3372317,13.9899562 C17.2137371,13.9550647 17.0892539,13.9237802 16.9639403,13.8961425 C13.455904,13.1224504 9.98496469,15.2939492 9.12580636,18.7637546 L9.08566972,18.9354403 L8.69002978,20.7333159 C8.54999268,21.3696756 8.27837427,21.9696184 7.89266748,22.4945064 C6.36723397,24.5703883 3.44965692,25.0152422 1.37607674,23.4881154 C1.03395538,23.2361538 0.960640019,22.7542465 1.2123223,22.4117454 C1.46400458,22.0692442 1.94537755,21.9958475 2.28749891,22.2478092 C3.67683636,23.2710126 5.63166748,22.9729522 6.65373642,21.5820725 C6.87986333,21.2743484 7.04746484,20.9281224 7.14868906,20.5607376 L7.18798406,20.402042 L7.583624,18.6041663 C8.55188231,14.2041857 12.8997449,11.4230964 17.2948469,12.3924295 L17.5257582,12.4468966 L17.754988,12.5080698 L19.6737401,13.0501838 C24.9884209,14.5517652 30.674073,12.6987409 34.0888647,8.37520901 L34.2448484,8.1739104 L36.6295477,5.03689567 Z" id="Path" fill="#8478FF"></path>
                                <path d="M29.698819,4.10617156 C31.2691888,0.609638026 35.3735778,-0.950421733 38.8662367,0.621680611 C39.5678762,0.937499912 40.2121258,1.36801027 40.7725861,1.89557059 C42.0919058,3.13744405 43.3133923,5.10045816 44.4585527,7.78779457 C44.6252108,8.17888932 44.4436204,8.63118725 44.0529593,8.79803028 C43.6622982,8.96487332 43.2105018,8.78308139 43.0438437,8.39198664 C41.9748655,5.88342617 40.859415,4.09081936 39.7189992,3.0173479 C39.2828603,2.60681102 38.7815181,2.27179647 38.2355163,2.0260322 C35.5670151,0.824896591 32.4396295,1.97332138 31.1702097,4.59067958 L31.1016126,4.73759403 L30.6067842,5.83937059 C30.4915657,6.09591425 30.3592172,6.34439723 30.2106374,6.58313234 C28.3832433,9.51935332 24.5242095,10.4165907 21.5912442,8.58716818 C21.2419656,8.3693074 20.8589107,8.21114734 20.4577723,8.11916737 C18.506103,7.67165492 16.5614218,8.85484773 16.047107,10.7748945 L16.0135633,10.9100355 L15.1574947,14.6517725 C14.8995652,15.7791404 14.3523199,16.8196434 13.5698151,17.6705017 C11.129209,20.3242998 7.0313574,20.5384942 4.32966527,18.1868561 L4.18628476,18.0584203 L0.699797313,14.8448964 C0.387336147,14.5568984 0.367245966,14.0698489 0.654924639,13.7570409 C0.923424734,13.4650867 1.36512854,13.4281029 1.67687155,13.6586224 L1.74157394,13.7121184 L5.22806139,16.9256423 C7.30133139,18.8365921 10.5294621,18.70314 12.4382931,16.6275686 C12.9933121,16.0240675 13.394301,15.2964013 13.60859,14.5066862 L13.6582538,14.3080006 L14.5143223,10.5662637 C15.1613838,7.73806301 17.9760982,5.97048258 20.801163,6.61826226 C21.2976871,6.73211389 21.7746255,6.9176428 22.2171066,7.1687086 L22.4045883,7.28030279 C24.6165921,8.66002915 27.5270245,7.98334453 28.9052211,5.76888538 C28.9799257,5.64885147 29.0491897,5.52555012 29.1128122,5.39935837 L29.2039898,5.20795007 L29.698819,4.10617156 Z" id="Path" fill="#C7C1FF"></path>
                            </g>
                            <ellipse id="Oval" fill="#8478FF" cx="22.633546" cy="26.2229548" rx="10.4266897" ry="10.4382636"></ellipse>
                        </g>
                        <g id="ElementCard3" transform="translate(29.855615, 75.591837)">
                            <mask id="mask-4" fill="white">
                                <use xlinkHref="#path-3"></use>
                            </mask>
                            <g id="Rectangle">
                                <use fill="black" fillOpacity="1" filter="url(#filter-5)" xlinkHref="#path-3"></use>
                                <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-3"></use>
                            </g>
                            <g id="Path-3" strokeLinecap="round" mask="url(#mask-4)">
                                <use fill="black" fillOpacity="1" filter="url(#filter-7)" xlinkHref="#path-6"></use>
                                <use stroke="#8EEAF6" strokeWidth="4.004" xlinkHref="#path-6"></use>
                            </g>
                            <path d="M22.6524064,108.389866 C36.0424523,104.556837 45.6894817,98.7285067 51.5934946,90.9048741 C60.4495139,79.1694253 63.4351742,67.7944231 78.6666599,67.7944231 C93.8981456,67.7944231 95.8510232,85.2160139 112.316621,85.2160139 C128.782219,85.2160139 129.682684,62.9988029 135.155236,54.1769525 C138.803604,48.2957189 143.775923,45.355102 150.072193,45.355102 L150.072193,119.756735 C150.072193,121.457772 148.69323,122.836735 146.992193,122.836735 L25.7324064,122.836735 C24.0313694,122.836735 22.6524064,121.457772 22.6524064,119.756735 L22.6524064,108.389866 L22.6524064,108.389866 Z" id="Path-3" fill="#CEF9FF" opacity="0.414736793" mask="url(#mask-4)"></path>
                            <rect id="Rectangle" fill="#8EEAF6" mask="url(#mask-4)" x="13.2139037" y="14.1734694" width="52.855615" height="5.66938776"></rect>
                            <path d="M16.0485976,28.3469388 L34.9193168,28.3469388 C36.484875,28.3469388 37.7540107,29.6160745 37.7540107,31.1816327 C37.7540107,32.7471909 36.484875,34.0163265 34.9193168,34.0163265 L16.0485976,34.0163265 C14.4830394,34.0163265 13.2139037,32.7471909 13.2139037,31.1816327 C13.2139037,29.6160745 14.4830394,28.3469388 16.0485976,28.3469388 Z" id="Rectangle" fillOpacity="0.401934003" fill="#8EEAF6" mask="url(#mask-4)"></path>
                            <path d="M136.386364,20.7877551 C138.210823,20.7877551 139.68984,19.3070968 139.68984,17.4806122 C139.68984,15.6541277 138.210823,14.1734694 136.386364,14.1734694 C134.561904,14.1734694 133.082888,15.6541277 133.082888,17.4806122 C133.082888,19.3070968 134.561904,20.7877551 136.386364,20.7877551 Z" id="Oval" fillOpacity="0.451704545" fill="#8EEAF6" mask="url(#mask-4)"></path>
                            <path d="M145.824866,20.7877551 C147.649326,20.7877551 149.128342,19.3070968 149.128342,17.4806122 C149.128342,15.6541277 147.649326,14.1734694 145.824866,14.1734694 C144.000407,14.1734694 142.52139,15.6541277 142.52139,17.4806122 C142.52139,19.3070968 144.000407,20.7877551 145.824866,20.7877551 Z" id="Oval" fillOpacity="0.451704545" fill="#8EEAF6" mask="url(#mask-4)"></path>
                            <path d="M155.263369,20.7877551 C157.087828,20.7877551 158.566845,19.3070968 158.566845,17.4806122 C158.566845,15.6541277 157.087828,14.1734694 155.263369,14.1734694 C153.43891,14.1734694 151.959893,15.6541277 151.959893,17.4806122 C151.959893,19.3070968 153.43891,20.7877551 155.263369,20.7877551 Z" id="Oval" fillOpacity="0.451704545" fill="#8EEAF6" mask="url(#mask-4)"></path>
                        </g>
                        <g id="ElementCard11" transform="translate(225.232620, 30.236735)">
                            <g id="Rectangle">
                                <use fill="black" fillOpacity="1" filter="url(#filter-9)" xlinkHref="#path-8"></use>
                                <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-8"></use>
                            </g>
                            <g id="Group-26" transform="translate(31.788688, 84.863931)">
                                <polygon id="Path" fill="#E7E4FF" points="8.83019118 1.72489101e-14 8.83019118 59.1864269 0 59.1864269 0 1.72489101e-14"></polygon>
                                <polygon id="Path" fill="#C7C1FF" points="8.83019118 14.1439886 8.83019118 59.1864269 0 59.1864269 0 14.1439886"></polygon>
                                <polygon id="Path" fill="#8478FF" points="8.83019118 42.4319657 8.83019118 59.1864269 0 59.1864269 0 42.4319657"></polygon>
                            </g>
                            <g id="Group-26" transform="translate(52.981147, 70.719943)">
                                <polygon id="Path" fill="#E7E4FF" points="8.83019118 1.03801722e-13 8.83019118 73.3304155 0 73.3304155 0 1.03801722e-13"></polygon>
                                <polygon id="Path" fill="#C7C1FF" points="8.83019118 21.2159829 8.83019118 73.3304155 0 73.3304155 0 21.2159829"></polygon>
                                <polygon id="Path" fill="#8478FF" points="8.83019118 56.5759543 8.83019118 73.3304155 0 73.3304155 0 56.5759543"></polygon>
                            </g>
                            <g id="Group-26" transform="translate(74.173606, 81.327934)">
                                <polygon id="Path" fill="#E7E4FF" points="8.83019118 1.69084619e-13 8.83019118 62.722424 0 62.722424 0 1.69084619e-13"></polygon>
                                <polygon id="Path" fill="#C7C1FF" points="8.83019118 21.2159829 8.83019118 62.722424 0 62.722424 0 21.2159829"></polygon>
                                <polygon id="Path" fill="#8478FF" points="8.83019118 49.50396 8.83019118 62.722424 0 62.722424 0 49.50396"></polygon>
                            </g>
                            <g id="Group-26" transform="translate(95.366065, 63.647949)">
                                <polygon id="Path" fill="#E7E4FF" points="8.83019118 1.14928181e-13 8.83019118 80.4024098 0 80.4024098 0 1.14928181e-13"></polygon>
                                <polygon id="Path" fill="#C7C1FF" points="8.83019118 21.2159829 8.83019118 80.4024098 0 80.4024098 0 21.2159829"></polygon>
                                <polygon id="Path" fill="#8478FF" points="8.83019118 49.50396 8.83019118 80.4024098 0 80.4024098 0 49.50396"></polygon>
                            </g>
                            <g id="Group-26" transform="translate(116.558524, 49.503960)">
                                <polygon id="Path" fill="#E7E4FF" points="8.83019118 -6.56047427e-14 8.83019118 94.5463983 0 94.5463983 0 -6.56047427e-14"></polygon>
                                <polygon id="Path" fill="#C7C1FF" points="8.83019118 28.2879771 8.83019118 94.5463983 0 94.5463983 0 28.2879771"></polygon>
                                <polygon id="Path" fill="#8478FF" points="8.83019118 70.7199429 8.83019118 94.5463983 0 94.5463983 0 70.7199429"></polygon>
                            </g>
                            <g id="Group-26" transform="translate(137.750982, 70.719943)">
                                <polygon id="Path" fill="#E7E4FF" points="8.83019118 1.00748731e-13 8.83019118 73.3304155 0 73.3304155 0 1.00748731e-13"></polygon>
                                <polygon id="Path" fill="#C7C1FF" points="8.83019118 21.2159829 8.83019118 73.3304155 0 73.3304155 0 21.2159829"></polygon>
                                <polygon id="Path" fill="#8478FF" points="8.83019118 53.7471566 8.83019118 73.3304155 0 73.3304155 0 53.7471566"></polygon>
                            </g>
                            <g id="Group-26" transform="translate(158.943441, 77.791937)">
                                <polygon id="Path" fill="#E7E4FF" points="8.83019118 1.10342437e-14 8.83019118 66.2584212 0 66.2584212 0 1.10342437e-14"></polygon>
                                <polygon id="Path" fill="#C7C1FF" points="8.83019118 14.1439886 8.83019118 66.2584212 0 66.2584212 0 14.1439886"></polygon>
                                <polygon id="Path" fill="#8478FF" points="8.83019118 41.7247663 8.83019118 66.2584212 0 66.2584212 0 41.7247663"></polygon>
                            </g>
                            <g id="Group-26" transform="translate(180.135900, 63.647949)">
                                <polygon id="Path" fill="#E7E4FF" points="8.83019118 1.67371137e-14 8.83019118 80.4024098 0 80.4024098 0 1.67371137e-14"></polygon>
                                <polygon id="Path" fill="#C7C1FF" points="8.83019118 28.2879771 8.83019118 80.4024098 0 80.4024098 0 28.2879771"></polygon>
                                <polygon id="Path" fill="#8478FF" points="8.83019118 62.9407491 8.83019118 80.4024098 0 80.4024098 0 62.9407491"></polygon>
                            </g>
                            <g id="Group-26" transform="translate(201.328359, 77.791937)">
                                <polygon id="Path" fill="#E7E4FF" points="8.83019118 1.56318453e-14 8.83019118 66.2584212 0 66.2584212 0 1.56318453e-14"></polygon>
                                <polygon id="Path" fill="#C7C1FF" points="8.83019118 14.1439886 8.83019118 66.2584212 0 66.2584212 0 14.1439886"></polygon>
                                <polygon id="Path" fill="#8478FF" points="8.83019118 48.7967606 8.83019118 66.2584212 0 66.2584212 0 48.7967606"></polygon>
                            </g>
                            <path d="M31.0846253,28.2879771 L101.72145,28.2879771 C102.893176,28.2879771 103.843048,29.2378491 103.843048,30.4095754 C103.843048,31.5813018 102.893176,32.5311737 101.72145,32.5311737 L31.0846253,32.5311737 C29.912899,32.5311737 28.9630271,31.5813018 28.9630271,30.4095754 C28.9630271,29.2378491 29.912899,28.2879771 31.0846253,28.2879771 Z" id="Rectangle" fill="#8C81FF" opacity="0.575730097"></path>
                            <rect id="Rectangle" fill="#8478FF" x="28.9630271" y="16.2655869" width="39.5592565" height="4.24319657"></rect>
                        </g>
                        <g id="Chair4" transform="translate(411.171123, 275.910204)">
                            <polygon id="Path" fill="#BFE7FF" transform="translate(89.590267, 134.175510) scale(-1, 1) translate(-89.590267, -134.175510) " points="92.3120497 88.9715918 80.8690909 179.379429 86.8684851 179.299822 98.3114439 89.8180661"></polygon>
                            <path d="M0,93.7376571 C-7.58852369e-15,88.5212172 4.22876008,84.2924571 9.4452,84.2924571 L47.2075641,84.2924571 C52.9290431,84.2924571 57.7110568,79.9391217 58.2468403,74.2427845 L64.2846873,10.0496727 C64.8204709,4.35333543 69.6024846,1.05101865e-15 75.3239636,0 L99.0241302,0 C105.147864,6.51445281e-16 110.11213,4.96426669 110.11213,11.088 C110.11213,11.3714703 110.10126,11.6548363 110.079542,11.9374734 L103.855155,92.9443305 C103.411247,98.7215453 98.5939868,103.182857 92.7997427,103.182857 L9.4452,103.182857 C4.22876008,103.182857 6.38829645e-16,98.9540971 0,93.7376571 Z" id="Path" fill="#91D6FF"></path>
                            <path d="M98.770362,-4.54747351e-13 C99.3452728,-4.53831714e-13 99.9099641,0.0437545685 100.461288,0.128115346 C100.550032,0.689963394 100.595546,1.26569242 100.595546,1.85205195 C100.595546,2.13631442 100.584615,2.42047176 100.562776,2.70389411 L94.3431684,83.4221279 C93.8980924,89.1983365 89.0812691,93.6582857 83.2879385,93.6582857 L0.15785132,93.6582857 L0.00174853035,93.6569132 C0.000584601796,93.5959378 7.5014265e-18,93.5348205 0,93.4735668 C-6.37170347e-16,88.270676 4.21777629,84.0528997 9.42066701,84.0528997 L47.0706042,84.0528997 C52.790954,84.0528997 57.572413,79.7012309 58.1096041,74.0061603 L64.1426164,10.0467394 C64.6798075,4.35166889 69.4612665,-4.63857922e-13 75.1816164,-4.54747351e-13 L98.770362,-4.54747351e-13 Z" id="Combined-Shape" fill="#BFE7FF"></path>
                        </g>
                        <g id="Wom1.4" transform="translate(456.000000, 330.500000) scale(-1, 1) translate(-456.000000, -330.500000) translate(384.000000, 203.000000)">
                            <g id="Head" transform="translate(32.719433, 0.465078)">
                                <path d="M10.0056596,56.8752086 C-2.19250823,54.0329972 -2.99586491,37.2806692 6.27808458,31.4695688 C-3.28604708,11.4097845 18.8310926,-8.50697349 33.9811336,3.77601323 C43.913154,-0.477638777 52.1667918,16.1209846 42.9249766,29.012535 C46.7999031,51.1477026 38.9136463,56.8694212 24.6974465,57.1124882 L10.0056596,56.8752086 Z" id="Path" fill="#422851"></path>
                                <path d="M10.1574171,52.8742814 C10.1574171,52.8742814 16.0912443,51.4804941 16.2970984,41.7523604 C16.5029524,32.0242267 16.3584456,28.7035394 16.3584456,28.7035394 L29.4289127,28.4675653 C29.4289127,28.4675653 29.5713892,39.3094143 29.5734195,43.2309494 C29.5788726,53.7636554 36.9139613,52.8742814 36.9139613,52.8742814 C36.9139613,52.8742814 35.3891376,61.4145937 23.5457126,62.0809136 C14.2986391,62.6019662 10.1574171,52.8742814 10.1574171,52.8742814 Z" id="Path" fill="url(#linearGradient-10)"></path>
                                <path d="M13.3115714,23.8457333 C7.48085733,23.6199893 8.43191455,32.1603221 14.4889318,32.148728 C14.4889318,32.148728 18.0069398,44.2807768 30.2210123,44.2807768 C40.1404142,44.2807768 40.7856882,30.1406488 40.8756641,27.3198719 C40.9724564,24.3122254 40.9847259,14.4313223 40.9847259,14.4313223 L13.0845866,14.4183642 L13.3115714,23.8457333 Z" id="Path" fill="url(#linearGradient-11)"></path>
                                <path d="M28.0824059,35.1582406 C28.0824059,35.1582406 30.0876252,38.4012871 34.0052272,37.2475322" id="Path" stroke="#F77B7B" strokeWidth="1.5" strokeLinecap="round" transform="translate(31.043817, 36.326987) rotate(-18.000000) translate(-31.043817, -36.326987) "></path>
                                <path d="M29.7818223,39.0292407 C30.7010721,39.4152636 31.5729746,39.4152801 32.33593,38.9717159" id="Path" stroke="#F49494" strokeLinecap="round" transform="translate(31.058876, 39.141832) rotate(2.000000) translate(-31.058876, -39.141832) "></path>
                                <path d="M13.5801623,28.5824793 C13.9467413,27.2611372 13.7845131,26.1070952 12.4730282,25.5799499" id="Path-8" stroke="#F77B7B" strokeLinecap="round" transform="translate(13.111813, 27.081215) rotate(-13.000000) translate(-13.111813, -27.081215) "></path>
                                <path d="M12.3890484,22.7905357 C12.3890484,22.7905357 15.5849403,27.0812197 16.2323068,27.1040094 C16.8796733,27.1267991 17.1063588,21.5237582 17.1063588,21.5237582 C21.0267394,21.2241043 30.9484107,19.247287 33.380515,14.6420253 C35.0813558,18.7054434 38.3326977,20.5103363 40.4689448,20.1637408 L40.8738366,28.7964755 L41.8709726,11.4410101 L11.6073573,11.4410101 L12.3890484,22.7905357 Z" id="Path" fill="#422851"></path>
                            </g>
                            <g id="LLeg" transform="translate(36.894034, 131.693412)">
                                <path d="M65.7021733,93.1980305 C65.7021733,93.1980305 60.4713359,103.768624 64.7594396,107.099676 C69.0539916,110.431374 80.397795,116.674758 80.397795,116.674758 L50.3443073,114.785033 L57.0247215,91.7308964 L65.7021733,93.1980305 Z" id="Path" fill="url(#linearGradient-12)"></path>
                                <path d="M53.2513328,103.257643 C54.7344364,103.431196 53.1505072,108.427219 56.3617488,108.807229 C59.5665421,109.186593 61.1241757,104.45325 64.7610035,105.953841 C68.3978313,107.454432 81.6615188,115.212693 82.1838292,118.384387 C82.7125879,121.555435 77.7708615,122.488549 70.9937231,121.772401 C64.2165847,121.056254 62.9674615,118.643933 57.8797715,119.61557 C52.7985298,120.586563 48.9920117,119.038694 49.1596669,115.32376 C49.327322,111.609473 51.7682293,103.084735 53.2513328,103.257643 Z" id="Path" fill="#222D4E"></path>
                                <path d="M66.4198249,106.723489 L65.7597068,107.713936 C65.453412,108.173503 65.5776637,108.794356 66.0372308,109.100651 C66.0590481,109.115192 66.0814289,109.12887 66.1043213,109.141651 L75.0699217,114.147582 C75.3606632,114.309918 75.7131358,114.316882 76.0100628,114.166158 L77.4019447,113.459621 L77.4019447,113.459621 C75.2160499,111.87556 73.4285878,110.685366 71.4533439,109.516708 C69.9369242,108.619514 68.2580621,107.694559 66.4198249,106.723489 Z" id="Path-14" fillOpacity="0.54097465" fill="#FFFFFF"></path>
                                <path d="M20.4297468,0.0315435604 C29.5282647,0.438005503 80.1680515,11.644498 89.3684437,13.5484027 C98.5688359,15.4523074 106.347172,25.754471 99.3314473,37.6838064 C91.4516537,51.0751145 71.1203434,89.106119 66.1840592,97.6792482 C63.7408052,101.922588 51.4149997,99.5434516 54.3102757,92.4213265 C59.7075898,79.1444076 62.2141077,45.7915218 75.9747293,33.6305676 C75.9747293,33.6305676 29.6979111,41.6888813 9.70825463,32.4583242 C-4.8197118,25.7484762 -1.53308213,-0.951062056 20.4297468,0.0315435604 Z" id="Path" fill="url(#linearGradient-13)"></path>
                            </g>
                            <g id="LArm" transform="translate(64.335218, 56.189189)">
                                <path d="M57.4555354,21.7037492 C57.4555354,21.7037492 57.3527218,14.2965681 59.942338,10.4255399 C62.5319541,6.55451181 62.6347677,2.44263115 64.5946509,2.57722521 C66.5545341,2.71181926 64.6139284,12.2692853 64.6139284,12.2692853 C64.6139284,12.2692853 67.100731,11.3187551 68.8871164,10.1164244 C70.6735017,8.91409376 76.0904903,2.9868033 77.3563821,4.03715214 C79.5026149,5.82680231 76.0455094,15.9220006 74.1627364,17.9402675 C72.2735375,19.9591784 61.953628,26.5980785 61.953628,26.5980785 L57.4555354,21.7037492 Z" id="Path" fill="url(#linearGradient-14)"></path>
                                <path d="M1.54220616,17.2652177 C5.77462022,27.0871682 13.5242759,45.130442 16.7191037,50.51357 C20.722897,57.2550903 27.0363457,60.2252538 34.4284151,55.2233671 C42.195657,49.9675151 63.5829982,29.4681252 64.2102397,24.8246055 C64.831619,20.1810857 59.5967911,14.6096831 54.8602391,17.4942139 C50.1236871,20.3787447 30.442208,36.7665931 30.442208,36.7665931 C30.442208,36.7665931 22.499104,16.3942278 16.1094485,4.5927549 C10.575655,-5.61747525 -4.90020804,2.33345995 1.54220616,17.2652177 Z" id="Path" fill="#6A5EE3"></path>
                                <path d="M30.524277,36.7771505 C31.9465183,41.6037469 32.8238746,43.877314 33.1563461,43.5978517 C33.4888175,43.3183895 33.361806,40.4141123 32.7753116,34.8850203 L30.524277,36.7771505 Z" id="Path" fillOpacity="0.302802666" fill="#000000"></path>
                            </g>
                            <g id="Body" transform="translate(23.579496, 51.612781)">
                                <path d="M0,10.0068988 C0,10.0068988 10.1994164,44.1777383 10.5014785,50.6460197 C10.8035406,57.1149441 2.15942275,76.1268945 3.63117218,80.9322893 C7.02455079,92.0368476 50.3479697,96.8184502 52.0031766,81.8801218 C52.7751121,74.9133635 51.1686594,68.6606239 53.0195932,60.4516488 C54.8641001,52.2426737 62.2782438,40.0440221 58.0730475,28.5751555 C53.8678513,17.1062889 54.9945847,6.49580412 51.9868173,4.49030809 C48.9790499,2.48481206 41.9480723,0.3549549 41.9480723,0.3549549 C38.5289863,5.38926996 26.9863575,5.31596405 21.4785441,-5.67113923e-14 C21.4785441,-5.67113923e-14 7.7957732,2.65573141 0,10.0068988 Z" id="Path" fill="#7367F0"></path>
                                <path d="M9.13993678,15.5412316 C15.9386181,26.3364714 15.9181784,45.1319964 19.5695599,47.5935645 C23.2209414,50.0551327 37.4324646,47.6395139 37.4324646,49.5889193 C37.4324646,51.5383247 17.7930336,69.4035203 21.0171234,77.9439199 C22.7053166,82.4158315 22.2473825,86.4165047 19.643321,89.9459395 L18.7933156,89.7606111 C11.05522,88.0108765 4.83413692,84.8688946 3.63117218,80.9322893 C2.15942275,76.1268945 11.8006767,57.1149441 11.4986145,50.6460197 C11.2422035,45.1552986 3.13546325,19.7027258 0.690954066,12.1347725 C2.68117146,8.62160914 5.49701697,9.75684617 9.13993678,15.5412316 Z" id="Path" fillOpacity="0.199355332" fill="#000000"></path>
                            </g>
                            <g id="RArm" transform="translate(0.000000, 58.763197)">
                                <path d="M44.3127259,37.0311967 C44.3127259,37.0311967 52.9036214,29.4236106 55.5334874,28.5407597 C58.1633534,27.6579088 73.5659264,26.5973287 73.468524,28.5922755 C73.3581345,30.7295345 65.0854203,30.6065407 64.2282788,31.6581056 C63.3711373,32.7090266 63.1763324,39.8954715 59.9360778,40.7023367 C56.7023167,41.5092019 47.0075268,42.9001267 47.0075268,42.9001267 L44.3127259,37.0311967 Z" id="Path" fill="url(#linearGradient-15)"></path>
                                <path d="M21.4575573,4.8202439 C12.4065222,17.6012139 4.67897106,37.0697477 1.71276397,44.6599699 C-1.25344311,52.2501922 1.73872457,63.6986607 14.7818288,61.4510963 C27.8014848,59.2093971 48.5168693,47.029025 50.8089385,43.4758555 C53.1010076,39.922686 49.6189384,33.4573353 45.2341105,34.3036911 C41.1716965,35.0867022 21.0212518,43.6594379 21.0212518,43.6594379 C21.0212518,43.6594379 33.9847995,20.6687405 37.1503169,14.505534 C42.4379034,4.21260382 29.4299716,-6.44103936 21.4575573,4.8202439 Z" id="Path" fill="#6A5EE3"></path>
                                <path d="M21.2264243,43.5022491 C16.6998549,45.7523724 14.7126134,46.4055938 15.2646998,45.4619135 C15.8167861,44.5182332 18.6677058,42.3887279 23.8174589,39.0733976 L21.2264243,43.5022491 Z" id="Path" fillOpacity="0.302802666" fill="#000000"></path>
                            </g>
                        </g>
                        <g id="Men3.2" transform="translate(108.000000, 113.000000)">
                            <g id="LLeg" transform="translate(43.000000, 123.000000)">
                                <path d="M23.0613011,188.194225 C23.0613011,188.194225 22.9251662,196.905565 28.2716109,199.99974 C33.6174558,203.094518 41.9000971,208.844376 41.9000971,208.844376 L10.3917566,209.91256 L11.2901272,188.115816 L23.0613011,188.194225 Z" id="Path" fill="#F8CFCF"></path>
                                <path d="M9.29171139,197.79385 L8.77909455,211.926019 L16.0975355,211.926019 L16.5991338,210.105398 C16.5991338,210.105398 19.354576,209.992348 20.9466863,210.77864 C22.6297496,211.61049 22.8558327,212.01376 22.8558327,212.01376 L46.0696323,212.062693 C46.0696323,212.062693 47.2147737,207.495111 40.1386317,204.191333 C33.0624897,200.886712 27.9102199,196.196799 26.9313925,195.716756 C25.9456354,195.233338 23.9567969,194.959992 22.556121,196.096404 C21.1554451,197.231972 20.0475511,200.218532 16.8737252,200.312179 C12.788637,200.432822 10.4420501,195.403758 9.29171139,197.79385 Z" id="Path" fill="#4B4B4B"></path>
                                <line x1="29.1306047" y1="197.249534" x2="26.7239399" y2="199.577514" id="Path-38" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"></line>
                                <line x1="32.6397846" y1="199.014188" x2="30.2331198" y2="201.342168" id="Path-38" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"></line>
                                <line x1="36.1489645" y1="200.778841" x2="33.7422997" y2="203.106821" id="Path-38" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"></line>
                                <path d="M-3.11677788e-14,0 C-3.11677788e-14,0 8.7534163,96.3795493 9.02568616,103.079373 C9.29795603,109.779197 6.2142299,121.341641 6.27420124,133.873956 C6.31418213,142.228832 6.6896027,161.129322 7.40046294,190.575426 L26.7100339,190.983933 C32.1202481,137.772679 34.8545412,107.177396 34.9129133,99.1980835 C34.9998718,87.2291144 35.7441161,1.15202233 35.7441161,1.15202233 L-3.11677788e-14,0 Z" id="Path" fill="#383838"></path>
                                <path d="M13.9185899,106.079432 L13.9399211,106.317885 C14.2306112,110.403569 11.6930271,123.846005 6.32716879,146.645194 L6.27420124,133.873956 L6.27420124,133.873956 C6.21530082,121.565433 9.18882541,110.192451 9.0370986,103.442922 L9.02568616,103.079373 C8.93393122,100.821533 7.87898095,88.3788552 6.52044953,72.8723417 L7.15454831,75.1433999 C10.877563,88.5953247 13.1321777,98.9076027 13.9185899,106.079432 Z" id="Path" fillOpacity="0.192498907" fill="#FFFFFF" opacity="0.283551897"></path>
                            </g>
                            <g id="RLeg" transform="translate(12.000000, 123.000000)">
                                <polygon id="Path" fill="#F8CFCF" points="7.47719198 186.966267 3.75324095 209.525021 17.4811359 209.456327 17.9153696 186.612557"></polygon>
                                <path d="M3.63716179,199.556444 L3.52640023,202.909638 L3.52640023,202.909638 C2.15491931,204.430979 1.16668381,207.474074 0.561693709,212.038924 C0.561693709,215.228514 21.7769965,215.133798 22.7965812,212.038924 C22.0558397,207.067333 20.8865988,203.956544 19.2888583,202.706559 L19.2764999,199.384379 C19.2714658,198.031123 18.3610565,196.848769 17.0540303,196.498043 L14.9909229,195.944432 L14.9909229,195.944432 C12.9841748,193.017296 10.2492921,193.120703 7.90576542,196.310441 L5.99994787,196.723585 C4.65625302,197.014872 3.68255244,198.182289 3.63716179,199.556444 Z" id="Path" fill="#4B4B4B"></path>
                                <line x1="8.68547009" y1="198.748505" x2="14.2427341" y2="198.748505" id="Path-39" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"></line>
                                <line x1="8.68547009" y1="201.392936" x2="14.2427341" y2="201.392936" id="Path-39" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"></line>
                                <line x1="9.56912534" y1="204.037367" x2="13.3590788" y2="204.037367" id="Path-39" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"></line>
                                <path d="M14.4006735,1.264748 C14.4006735,1.264748 8.26686347,67.3308488 8.35022391,79.9824545 C8.43358436,92.6346627 10.2379152,98.1909809 7.670051,107.266923 C5.95773884,113.317551 4.37153164,140.490516 2.91142942,188.785816 L21.9025959,190.864345 C29.9909179,139.185631 34.4748355,109.763781 35.3543486,102.598796 C36.6736183,91.8513189 52.9796464,0.353056276 52.9796464,0.353056276 L14.4006735,1.264748 Z" id="Path" fill="#2E2E2E"></path>
                                <path d="M19.5299423,46.1047812 L18.3716067,50.2981049 C14.9798201,62.6734466 11.8491006,75.7604202 12.66862,94.4549496 L12.7086043,95.3085799 C12.910397,99.3582089 10.3341735,122.860821 4.97993357,165.816415 L4.4706526,168.835497 C3.94546163,171.914647 3.47800526,174.541945 3.06828349,176.717393 L3.18465114,172.912893 C3.86736989,151.914506 5.70364348,114.215423 7.670051,107.266923 C10.2379152,98.1909809 8.43358436,92.6346627 8.35022391,79.9824545 C8.2918716,71.1263305 11.2799328,36.0971039 13.0780213,15.8766191 L14.4006735,1.264748 L22.4171791,1.07558316 L22.4365617,3.32614801 C22.5778189,24.0988086 21.6089457,38.358353 19.5299423,46.1047812 L19.5299423,46.1047812 Z" id="Path" fillOpacity="0.192498907" fill="#FFFFFF" opacity="0.283551897"></path>
                            </g>
                            <g id="LArm" transform="translate(62.000000, 17.000000)">
                                <g id="Group-6" transform="translate(56.695443, 0.000000)">
                                    <path d="M1.13909035,25.5658399 C1.13909035,25.5658399 -0.446375009,16.8275723 0.123679951,15.0580716 C0.610601897,13.528772 5.21260809,11.3235032 5.21260809,11.3235032 C5.21260809,11.3235032 13.2171298,-1.182221 14.9688612,0.0910207571 C16.3939986,1.12347418 10.8003343,10.3433184 10.8003343,10.3433184 C10.8003343,10.3433184 15.9011386,15.6359636 15.8298817,18.0015622 C15.7586248,20.3677481 7.50470407,27.7740451 7.50470407,27.7740451 L1.13909035,25.5658399 Z" id="Path" fill="#F8CFCF"></path>
                                    <path d="M5.38481219,11.1978234 C5.38481219,11.1978234 8.92984147,10.720945 9.49395836,11.8121273 C9.97494223,12.7418052 7.02965827,15.3786874 7.02965827,16.1198895 C7.02965827,16.8610915 7.07175728,22.4715306 7.30874768,22.5225101 C7.54573808,22.5734897 10.859715,13.3672676 10.1530844,11.4198185 C9.5711533,9.82121885 5.38481219,11.1978234 5.38481219,11.1978234 Z" id="Path" fill="#F28585"></path>
                                </g>
                                <path d="M15.5457164,36.6838138 C6.26047077,28.6618138 -7.6827809,43.1241071 7.86469163,56.5693719 C13.7962758,61.6985647 32.4279299,74.4934826 36.995159,77.3066328 C44.2662699,81.7853932 50.2414554,77.743848 52.6970525,70.6974967 C54.7796169,64.7232209 67.2382363,32.7431131 65.6528471,28.0457452 C64.6191345,24.9822444 59.0706183,22.0013096 56.2855896,24.4119823 C52.2155357,27.9354574 39.7656539,58.8393828 39.7656539,58.8393828 C39.7656539,58.8393828 26.1032702,45.8048409 15.5457164,36.6838138 Z" id="Path" fill="#877BFF"></path>
                                <path d="M39.7656539,58.8393828 C42.4841741,62.1368163 43.9415857,63.5337088 44.1378885,63.0300602 C44.3341913,62.5264117 43.256272,60.155867 40.9041305,55.9184261 L39.7656539,58.8393828 Z" id="Path" fillOpacity="0.159855769" fill="#000000"></path>
                                <path d="M55.2041008,26.3933461 C58.4435853,24.8933286 61.9826716,26.7476498 63.9332769,29.7234923" id="Path-41" stroke="#5386BF" strokeLinecap="round"></path>
                            </g>
                            <g id="Head" transform="translate(37.000000, 0.000000)">
                                <path d="M0.741268861,44.4314962 C0.741268861,44.4314962 6.07954955,43.2029083 6.26474314,34.6278072 C6.44993673,26.0527061 6.31993328,23.1256052 6.31993328,23.1256052 L18.9756462,22.9176001 C18.9756462,22.9176001 19.1033962,32.474413 19.1056496,35.9311456 C19.1105554,43.4566024 24.8114961,45.4899095 24.8114961,45.4899095 C24.8114961,45.4899095 24.3376751,53.7184172 13.6829115,54.3057611 C5.36391737,54.7650557 0.741268861,44.4314962 0.741268861,44.4314962 Z" id="Path" fill="#F8C5C5"></path>
                                <g id="Head1" transform="translate(0.526168, 0.000000)">
                                    <path d="M29.7245,13.881477 L29.4420072,21.9856771 C31.8335875,19.2301157 33.4487931,15.8137863 33.9273013,11.4325521 L29.7245,13.881477 Z" id="Path" fill="url(#linearGradient-16)"></path>
                                    <path d="M4.63170636,18.7910774 C-0.613810064,18.5920899 0.241794619,26.1201748 5.69090135,26.1099549 C5.69090135,26.1099549 11.5470553,37.6535199 20.7410957,37.6835531 C25.169798,37.6979009 26.6157798,33.8305688 27.9434093,31.1156812 C29.2710389,28.4007936 30.2454515,24.3398844 30.326397,21.8534418 C30.4134748,19.2022783 30.4245128,10.4925149 30.4245128,10.4925149 L4.42750284,10.4810927 L4.63170636,18.7910774 Z" id="Path" fill="#F8CFCF"></path>
                                    <path d="M30.4245128,14.4889488 L12.1413786,14.4889488 C11.9493601,17.7279604 10.852007,20.4173454 8.06544558,22.4012747 C8.14443472,27.1462445 9.76371216,30.6003607 12.5713374,33.0226604 C15.0156126,26.1551462 29.9682574,28.0340301 28.880747,32.5149825 C26.5961069,41.9285597 5.18099304,42.1420461 6.47992739,20.4758434 C5.62581926,19.3340011 2.57027147,19.1205147 1.56447638,20.262357 C-0.153098664,15.5286497 0.579907739,8.83240996 5.54744721,7.86383661 C1.64801649,-2.22301443 54.6699781,-4.35682137 30.4245128,14.4889488 Z" id="Path" fill="#424242"></path>
                                    <path d="M18.8157844,31.3779383 C18.8157844,31.3779383 20.6729444,33.6518571 24.1872746,32.6513011" id="Path" stroke="#CC6969" strokeLinecap="round" transform="translate(21.501529, 32.141810) rotate(-15.000000) translate(-21.501529, -32.141810) "></path>
                                    <path d="M5.16239396,23.2191675 C5.4865848,22.0546831 5.3361938,21.0357183 4.15544257,20.5653482" id="Path-8" stroke="#DE6767" strokeLinecap="round" transform="translate(4.733298, 21.892258) rotate(-13.000000) translate(-4.733298, -21.892258) "></path>
                                </g>
                            </g>
                            <g id="Body" transform="translate(19.000000, 43.000000)">
                                <path d="M21.0887197,1.99958568e-13 L36.1914147,9.79574142 L43.0011786,1.8874427 C43.0011786,1.8874427 57.3479279,6.32708326 60.0092853,11.5177208 C63.8498326,19.0082179 58.7898088,79.0218277 60.9957037,82.6433045 C65.6960429,90.3602735 59.4466012,95.6576336 38.7566137,97.6040573 C18.0666262,99.5504811 -0.28884638,97.9565524 3.04196022,87.6583547 C6.37362817,77.3592953 -6.48128373,10.1471097 4.85179928,6.44459063 C8.39299888,5.28768047 21.0887197,1.99958568e-13 21.0887197,1.99958568e-13 Z" id="Path" fill="#7367F0"></path>
                                <path d="M10.8965667,18.5293115 C11.8111756,20.1436981 11.3396982,25.7368403 9.48213456,35.308738 C7.89957478,43.4635594 9.70904936,51.8994216 14.4866096,58.7049866 L14.7550805,59.0813842 C16.3707536,61.311011 17.8602196,64.2466979 19.2234783,67.888445 C22.5670796,76.8203888 14.8550314,84.2660709 20.7650575,98.2389941 L20.0502516,98.2052744 C8.45447899,97.6060802 0.789503104,94.6225083 3.04196022,87.6583547 C5.77363123,79.2140432 -2.37619004,32.5096577 0.846492493,13.9208411 C6.42575861,15.0148833 9.77563718,16.550746 10.8965667,18.5293115 Z" id="Path" fillOpacity="0.159855769" fill="#000000"></path>
                                <path d="M21.0887197,0 L34.5976454,8.79513302 C32.6173088,11.0928518 31.5553511,13.7664826 31.1543811,16.7091146 C25.9627825,12.6559903 20.5659999,6.99237131 18.8938324,0.901907668 L21.0887197,0 Z" id="Path" fill="#5E54C7"></path>
                                <path d="M37.2590028,8.5661186 C39.5433593,10.1085318 41.3655344,12.839163 42.8490002,15.9439418 C44.6273114,12.3759186 45.3084138,7.66379413 45.3084138,2.63944245 L43.07763,1.75885417 L37.2590028,8.5661186 Z" id="Path" fill="#5E54C7"></path>
                                <path d="M34.5976454,8.79427083 L34.7743323,8.89279233 C35.5838841,9.34420258 36.593645,9.21144359 37.2590028,8.5661186 L37.2590028,8.5661186 L37.2590028,8.5661186 C42.3521995,27.2066914 45.9341595,47.7241189 47.017313,68.5758623 L41.701931,77.3895833 L35.4847645,69.1409106 C36.294599,47.4695461 36.2892131,26.3391488 34.5976454,8.79427083 Z" id="Path" fill="#CBC6FF"></path>
                            </g>
                            <g id="RArm" transform="translate(0.000000, 50.000000)">
                                <g id="Example-Book-Back" transform="translate(80.380968, 61.179493) rotate(6.000000) translate(-80.380968, -61.179493) translate(65.880968, 47.179493)">
                                    <g id="Group-3" transform="translate(-0.000000, -0.000000)">
                                        <path d="M2.56360174,0.805804249 L14.9901149,1.30852939 C16.3111173,1.36197166 17.3545131,2.44843244 17.3545131,3.77051548 L17.3545131,24.9001232 C17.3545131,26.2609528 16.2513428,27.3641232 14.8905131,27.3641232 C14.8573024,27.3641232 14.824095,27.3634518 14.7909114,27.3621093 L2.36439826,26.8593841 C1.04339581,26.8059419 6.05997686e-16,25.7194811 0,24.3973981 L0,3.26779034 C7.21524855e-16,1.90696071 1.10317038,0.803790336 2.464,0.803790336 C2.49721076,0.803790336 2.53041812,0.804461777 2.56360174,0.805804249 Z" id="Rectangle" fill="#6182EC" fillRule="nonzero"></path>
                                        <path d="M4.09613338,0.239292387 L17.1566604,1.40418313 L17.1566604,1.40418313 L25.8978367,0.337205883 C27.2486404,0.17232226 28.4773477,1.13370044 28.6422314,2.48450415 C28.6543225,2.58356079 28.6603849,2.6832605 28.6603849,2.78305236 L28.6603849,23.8801147 C28.6603849,25.1254835 27.7311267,26.1750672 26.4949331,26.3259611 L17.1566604,27.465822 L17.1566604,27.465822 L3.65833456,26.2618832 C2.38738117,26.1485247 1.41323397,25.0836246 1.41323397,23.8076259 L1.41323397,2.69354972 C1.41323397,1.33272009 2.51640434,0.229549718 3.87723397,0.229549718 C3.95030881,0.229549718 4.02334748,0.232800488 4.09613338,0.239292387 Z" id="Rectangle" fill="#96EFFA"></path>
                                        <path d="M5.96163498,5.7483406 L11.9476558,6.20585477 C12.781583,6.2695922 13.4257227,6.96484243 13.4257227,7.80120186 L13.4257227,10.2086014 C13.4257227,11.092257 12.7093783,11.8086014 11.8257227,11.8086014 C11.785034,11.8086014 11.74436,11.8070493 11.7037896,11.8039485 L5.71776883,11.3464343 C4.88384159,11.2826969 4.23970191,10.5874467 4.23970191,9.75108723 L4.23970191,7.34368769 C4.23970191,6.46003209 4.95604631,5.74368769 5.83970191,5.74368769 C5.88039064,5.74368769 5.92106457,5.74523979 5.96163498,5.7483406 Z" id="Rectangle" fill="#74CFDB" fillRule="nonzero"></path>
                                        <path d="M17.0521906,1.40418313 L17.5720416,1.40418313 C18.0138694,1.40418313 18.3720416,1.76235533 18.3720416,2.20418313 L18.3720416,26.581571 C18.3720416,27.0233988 18.0138694,27.381571 17.5720416,27.381571 L17.0521906,27.381571 C16.6103628,27.381571 16.2521906,27.0233988 16.2521906,26.581571 L16.2521906,2.20418313 C16.2521906,1.76235533 16.6103628,1.40418313 17.0521906,1.40418313 Z" id="Rectangle" fill="#EAEFFF" fillRule="nonzero"></path>
                                        <path d="M17.7588076,1.40418313 L18.2786586,1.40418313 C18.7204864,1.40418313 19.0786586,1.76235533 19.0786586,2.20418313 L19.0786586,26.581571 C19.0786586,27.0233988 18.7204864,27.381571 18.2786586,27.381571 L17.7588076,27.381571 C17.3169798,27.381571 16.9588076,27.0233988 16.9588076,26.581571 L16.9588076,2.20418313 C16.9588076,1.76235533 17.3169798,1.40418313 17.7588076,1.40418313 Z" id="Rectangle" fill="#7AD2DD" fillRule="nonzero"></path>
                                    </g>
                                </g>
                                <path d="M53.8898098,58.414051 C53.8898098,58.414051 58.4763723,57.4976502 60.3535648,56.0335299 C62.2301182,54.5694095 67.9147457,53.5344817 67.9147457,53.5344817 L66.0963552,57.3398221 C66.0963552,57.3398221 80.0286138,56.0403919 80.3584168,57.4059477 C80.6888589,58.7715035 76.6589988,60.25621 76.6589988,60.25621 C76.6589988,60.25621 74.6718719,63.9442711 72.7480212,66.0821238 C70.9277132,68.1051925 61.8843364,67.8643955 58.7141372,66.6017711 C55.9747271,65.5113227 52.9962738,66.0565469 52.9962738,66.0565469 L53.8898098,58.414051 Z" id="Path" fill="#F8CFCF"></path>
                                <path d="M17.0015287,5.23908666 C21.9834717,-5.45829648 39.0420787,1.74928363 34.743032,13.2085801 C30.5786324,24.3103172 20.4891562,50.2297688 20.4891562,50.2297688 C20.4891562,50.2297688 52.4615046,54.26311 54.9539856,56.1438961 C58.279105,58.6528107 56.2237743,65.8129962 53.3973926,67.0878512 C51.0063497,68.1659285 33.2444036,68.7964568 15.8658696,67.4352117 C7.26838005,66.7620882 0.91037982,63.0917058 0.023399541,53.5587901 C-0.597909314,46.8833495 12.2019329,15.5441144 17.0015287,5.23908666 Z" id="Path" fill="#877BFF"></path>
                                <path d="M20.5012322,50.2237695 C18.7297277,53.5697693 18.0971438,55.2769113 18.6034806,55.3451955 C19.1098174,55.4134797 20.6499107,53.8287238 23.2237605,50.5909278 L20.5012322,50.2237695 Z" id="Path" fillOpacity="0.159855769" fill="#000000"></path>
                                <path d="M52.5389078,55.5928642 C53.6332373,58.6849545 53.0689577,62.3753167 51.7927016,65.0138233" id="Path-40" stroke="#5386BF" strokeLinecap="round" transform="translate(52.456541, 60.303344) rotate(2.000000) translate(-52.456541, -60.303344) "></path>
                            </g>
                        </g>
                        <g id="Vase2" transform="translate(25.000000, 297.161224)">
                            <g id="Group-39" transform="translate(0.000000, 0.838776)">
                                <path d="M4.42970395,56.0038809 C13.4502152,55.9751349 20.7860877,63.2644008 20.8148337,72.2849121 C20.814944,72.3195125 20.8149443,72.3541132 20.8148346,72.3887136 L20.7923745,79.4771546 L20.7923745,79.4771546 C11.7718633,79.5059006 4.43599079,72.2166347 4.40724477,63.1961234 C4.40713451,63.161523 4.40713419,63.1269223 4.40724383,63.0923219 L4.42970395,56.0038809 L4.42970395,56.0038809 Z" id="Rectangle" fill="#02D3AF" transform="translate(12.611039, 67.740518) rotate(-24.000000) translate(-12.611039, -67.740518) "></path>
                                <path d="M12.1742786,64.301643 C20.4236209,70.1719909 25.7729468,76.7138347 28.2380644,83.9255523 C30.7152393,91.1725434 31.7301524,103.320986 31.2692096,120.346848 C31.262072,120.61049 31.46819,120.83005 31.7295871,120.83743 C31.9909841,120.844448 32.2086745,120.63656 32.2158121,120.372917 C32.679621,103.241188 31.6566517,90.9963123 29.1333098,83.6142595 C26.5979108,76.196933 21.1216056,69.4998026 12.7202024,63.5212461 C12.5065368,63.3691986 12.2111174,63.4206376 12.0603647,63.6361383 C11.909612,63.851639 11.960613,64.1495956 12.1742786,64.301643 Z" id="Path" fill="#02B596"></path>
                                <path d="M49.965738,51.7697446 C58.9139163,51.7403537 66.1916741,58.9704594 66.221065,67.9186377 C66.2211812,67.9540183 66.2211815,67.9893992 66.2210659,68.0247798 L66.1960492,75.6832112 L66.1960492,75.6832112 C57.2478709,75.7126021 49.9701132,68.4824963 49.9407223,59.5343181 C49.9406061,59.4989375 49.9406057,59.4635566 49.9407213,59.428176 L49.965738,51.7697446 L49.965738,51.7697446 Z" id="Rectangle" fill="#02D3AF" transform="translate(58.080894, 63.726478) scale(-1, 1) rotate(-25.000000) translate(-58.080894, -63.726478) "></path>
                                <g id="Group-88" transform="translate(20.755961, 71.269687) rotate(-12.000000) translate(-20.755961, -71.269687) translate(11.755961, 37.269687)">
                                    <path d="M0.496079497,0.617868565 C8.51524346,0.617868565 15.0160603,7.11868541 15.0160603,15.1378494 L15.0160603,21.6298098 L15.0160603,21.6298098 C6.99689634,21.6298098 0.496079497,15.128993 0.496079497,7.10982904 L0.496079497,0.617868565 L0.496079497,0.617868565 Z" id="Rectangle" fill="#02D3AF"></path>
                                    <path d="M5.08517434,6.77290275 C10.1209125,12.1580639 13.1750379,17.4598366 14.2592226,22.6753182 L14.3243316,23.0028367 L14.3870588,23.3463785 C15.4028711,29.1400325 15.9908073,43.8744402 16.1430673,67.4920251 C16.1447676,67.7557599 16.3581246,67.9681689 16.6196136,67.9664644 C16.8811025,67.9647392 17.0917029,67.7495498 17.0900128,67.485815 L17.0686471,64.5718147 L17.0370261,61.1977762 C16.8160399,40.2528689 16.2012664,27.3631553 15.1860263,22.4793323 C14.0625858,17.0750102 10.9213764,11.622065 5.77406997,6.11759405 C5.59465241,5.92572695 5.29499119,5.91688378 5.10475792,6.09784228 C4.91452464,6.27880078 4.90575678,6.58103565 5.08517434,6.77290275 Z" id="Path" fill="#02B596"></path>
                                </g>
                                <path d="M42.7671178,35.4217776 C51.4118641,35.420482 58.4208659,42.4273833 58.4221614,51.0721295 C58.4221617,51.0736868 58.4221617,51.075244 58.4221614,51.0768012 L58.4210151,58.7912388 L58.4210151,58.7912388 C49.7762689,58.7925343 42.7672671,51.7856331 42.7659715,43.1408868 C42.7659713,43.1393296 42.7659713,43.1377724 42.7659715,43.1362151 L42.7671178,35.4217776 L42.7671178,35.4217776 Z" id="Rectangle" fill="#02D3AF" transform="translate(50.594066, 47.106508) scale(-1, 1) rotate(-1.000000) translate(-50.594066, -47.106508) "></path>
                                <path d="M52.9504381,38.6616979 C47.3893559,46.8224695 43.3260895,54.6993453 40.7622035,62.2934074 C38.202787,69.8742312 35.6500108,81.5813826 33.099617,97.423954 C33.0577079,97.6842851 33.2329769,97.929591 33.4910912,97.9718599 C33.7492055,98.0141289 33.9924225,97.8373546 34.0343315,97.5770235 L34.3478079,95.6507111 L34.5629391,94.3529656 C36.929381,80.1862598 39.2957501,69.5999184 41.6586137,62.6012717 C44.1929439,55.0947518 48.2164993,47.2948581 53.7308443,39.2026724 C53.8789582,38.9853184 53.8243284,38.6880168 53.6088252,38.5386308 C53.393322,38.3892448 53.098552,38.4443438 52.9504381,38.6616979 Z" id="Path" fill="#02B596"></path>
                                <g id="Group-87" transform="translate(33.142697, 13.911947)">
                                    <path d="M7.76208073,19.4044636 C4.75556294,27.9693943 2.58783229,38.5601356 1.25888876,51.1766874 C-0.0700547599,63.7932392 -0.34682576,76.4097911 0.428575762,89.0263429 L2.6292102,89.226475 C1.8413211,74.5011252 1.92054365,61.8457925 2.86687783,51.2604768 C3.81321201,40.6751611 5.81171872,30.0898453 8.86239795,19.5045296 L7.76208073,19.4044636 Z" id="Path" fill="#02B596"></path>
                                    <path d="M10.0734726,24.1767025 C14.0851052,24.1847711 17.330623,20.9146332 17.3225546,16.8726302 C17.3144561,12.8306273 11.6398756,1.90564808 10.028927,1.90240125 C8.41797838,1.89915587 2.78704939,12.8013542 2.79512417,16.8433571 C2.80321634,20.8853601 6.06184005,24.168604 10.0734726,24.1767025 Z" id="Oval" fill="#C2FFF4" transform="translate(10.058839, 13.039552) rotate(14.000000) translate(-10.058839, -13.039552) "></path>
                                    <path d="M10.2149519,20.9735245 C13.0056283,20.9791376 15.2632378,18.6432028 15.257475,15.7560578 C15.25169,12.8689128 10.9993082,6.45391871 9.87865819,6.45165997 C8.75800814,6.44940243 5.14575684,12.8485491 5.15152384,15.735694 C5.15730466,18.622839 7.42427554,20.9678911 10.2149519,20.9735245 Z" id="Oval" fill="#02D3AF" transform="translate(10.204499, 13.712592) rotate(14.000000) translate(-10.204499, -13.712592) "></path>
                                    <path d="M9.58270016,20.0933927 L13.6404689,7.42936872 C13.8817052,6.67648638 13.4721313,5.86891441 12.7256601,5.62560624 C11.9791889,5.38229806 11.1784937,5.79538954 10.9372574,6.54827187 L6.87948871,19.2122959 C6.6382524,19.9651782 7.04782623,20.7727502 7.79429746,21.0160584 C8.54076869,21.2593665 9.34146386,20.8462751 9.58270016,20.0933927 Z" id="Path" fill="url(#linearGradient-17)"></path>
                                </g>
                                <g id="Group-87" transform="translate(19.400683, 46.275460) scale(-1, 1) rotate(6.000000) translate(-19.400683, -46.275460) translate(9.400683, 1.275460)">
                                    <path d="M7.76208073,19.4044636 C4.75556294,27.9693943 2.58783229,38.5601356 1.25888876,51.1766874 C-0.0700547599,63.7932392 -0.34682576,76.4097911 0.428575762,89.0263429 L2.6292102,89.226475 C1.8413211,74.5011252 1.92054365,61.8457925 2.86687783,51.2604768 C3.81321201,40.6751611 5.81171872,30.0898453 8.86239795,19.5045296 L7.76208073,19.4044636 Z" id="Path" fill="#02B596"></path>
                                    <path d="M10.0734726,24.1767025 C14.0851052,24.1847711 17.330623,20.9146332 17.3225546,16.8726302 C17.3144561,12.8306273 11.6398756,1.90564808 10.028927,1.90240125 C8.41797838,1.89915587 2.78704939,12.8013542 2.79512417,16.8433571 C2.80321634,20.8853601 6.06184005,24.168604 10.0734726,24.1767025 Z" id="Oval" fill="#C2FFF4" transform="translate(10.058839, 13.039552) rotate(14.000000) translate(-10.058839, -13.039552) "></path>
                                    <path d="M10.2149519,20.9735245 C13.0056283,20.9791376 15.2632378,18.6432028 15.257475,15.7560578 C15.25169,12.8689128 10.9993082,6.45391871 9.87865819,6.45165997 C8.75800814,6.44940243 5.14575684,12.8485491 5.15152384,15.735694 C5.15730466,18.622839 7.42427554,20.9678911 10.2149519,20.9735245 Z" id="Oval" fill="#02D3AF" transform="translate(10.204499, 13.712592) rotate(14.000000) translate(-10.204499, -13.712592) "></path>
                                    <path d="M9.58270016,20.0933927 L13.6404689,7.42936872 C13.8817052,6.67648638 13.4721313,5.86891441 12.7256601,5.62560624 C11.9791889,5.38229806 11.1784937,5.79538954 10.9372574,6.54827187 L6.87948871,19.2122959 C6.6382524,19.9651782 7.04782623,20.7727502 7.79429746,21.0160584 C8.54076869,21.2593665 9.34146386,20.8462751 9.58270016,20.0933927 Z" id="Path" fill="url(#linearGradient-17)"></path>
                                </g>
                                <path d="M66.0193312,58.348269 C54.7919939,62.7417856 46.9632436,67.8189882 42.5366476,73.5989079 C38.1104374,79.3783238 35.1857174,88.3414786 33.7431904,100.490257 C33.7120965,100.752126 33.897369,100.989836 34.1570082,101.021197 C34.4166475,101.052558 34.6523333,100.865694 34.6834272,100.603825 C36.1074227,88.6111168 38.9814011,79.8034658 43.2860649,74.182756 C47.5903429,68.5625501 55.2810864,63.5748495 66.3618628,59.2386855 C66.6056507,59.1432857 66.7266016,58.8666223 66.6320141,58.6207405 C66.5374266,58.3748588 66.2631191,58.2528691 66.0193312,58.348269 Z" id="Path" fill="#02B596"></path>
                            </g>
                            <g id="Group-38" transform="translate(9.000000, 63.838776)">
                                <path d="M26.9292167,30.5952364 C23.3753227,17.930419 14.5810637,13.4510183 0.546439628,17.1570344 C11.0541171,20.5911081 18.9873228,25.0705088 24.3460566,30.5952364 L26.9292167,30.5952364 Z" id="Path-151" fill="#02B596"></path>
                                <path d="M49.9743878,27.6768516 C46.4204938,15.0120342 38.7206627,9.60560586 26.8748945,11.4575666 C34.0694451,17.3715401 40.9082228,22.7779685 47.3912277,27.6768516 L49.9743878,27.6768516 Z" id="Path-151" fill="#02B596" transform="translate(38.424641, 19.390013) scale(-1, 1) translate(-38.424641, -19.390013) "></path>
                                <path d="M21.1930289,29.1213891 C22.4560321,17.5137564 28.334791,8.61450338 38.8293056,2.42363012 C33.4963463,14.6204108 28.3658899,23.3795123 23.4379362,28.7009344 L21.1930289,29.1213891 Z" id="Path-151" fill="#02B596" transform="translate(30.011167, 15.772510) rotate(-22.000000) translate(-30.011167, -15.772510) "></path>
                                <path d="M22.1728688,27.6768516 C20.0656716,21.5873947 17.9696284,13.0820797 16.399272,4.83731807 C24.1619616,7.95933274 26.6553871,20.3973923 24.1619616,27.6768516 L22.1728688,27.6768516 Z" id="Path-114" fill="#02D3AF"></path>
                                <g id="Group-89" transform="translate(6.356670, 21.127769)">
                                    <mask id="mask-19" fill="white">
                                        <use xlinkHref="#path-18"></use>
                                    </mask>
                                    <use id="Rectangle" fill="#7569EC" xlinkHref="#path-18"></use>
                                    <path d="M-3.94736842,-4.02857143 L8.99697458,-4.02857143 C0.975573655,26.4241399 25.1229107,47.360379 8.99697458,77.8130904 L-3.94736842,77.8130904 L-3.94736842,-4.02857143 Z" id="Rectangle" fill="#8175FF" mask="url(#mask-19)"></path>
                                </g>
                            </g>
                        </g>
                        <g id="Chair4" transform="translate(571.625668, 275.910204)">
                            <polygon id="Path-25" fill="#91D6FF" points="47.1204989 88.9715918 35.6775401 179.379429 41.6769343 179.299822 53.119893 89.8180661"></polygon>
                            <polygon id="Path" fill="#91D6FF" points="13.8214615 88.9715918 2.37850267 179.379429 8.37789683 179.299822 19.8208556 89.8180661"></polygon>
                            <polygon id="Path" fill="#BFE7FF" transform="translate(89.590267, 134.175510) scale(-1, 1) translate(-89.590267, -134.175510) " points="92.3120497 88.9715918 80.8690909 179.379429 86.8684851 179.299822 98.3114439 89.8180661"></polygon>
                            <path d="M0,93.7376571 C-7.58852369e-15,88.5212172 4.22876008,84.2924571 9.4452,84.2924571 L47.2075641,84.2924571 C52.9290431,84.2924571 57.7110568,79.9391217 58.2468403,74.2427845 L64.2846873,10.0496727 C64.8204709,4.35333543 69.6024846,1.05101865e-15 75.3239636,0 L99.0241302,0 C105.147864,6.51445281e-16 110.11213,4.96426669 110.11213,11.088 C110.11213,11.3714703 110.10126,11.6548363 110.079542,11.9374734 L103.855155,92.9443305 C103.411247,98.7215453 98.5939868,103.182857 92.7997427,103.182857 L9.4452,103.182857 C4.22876008,103.182857 6.38829645e-16,98.9540971 0,93.7376571 Z" id="Path" fill="#91D6FF"></path>
                            <path d="M98.770362,-4.54747351e-13 C99.3452728,-4.53831714e-13 99.9099641,0.0437545685 100.461288,0.128115346 C100.550032,0.689963394 100.595546,1.26569242 100.595546,1.85205195 C100.595546,2.13631442 100.584615,2.42047176 100.562776,2.70389411 L94.3431684,83.4221279 C93.8980924,89.1983365 89.0812691,93.6582857 83.2879385,93.6582857 L0.15785132,93.6582857 L0.00174853035,93.6569132 C0.000584601796,93.5959378 7.5014265e-18,93.5348205 0,93.4735668 C-6.37170347e-16,88.270676 4.21777629,84.0528997 9.42066701,84.0528997 L47.0706042,84.0528997 C52.790954,84.0528997 57.572413,79.7012309 58.1096041,74.0061603 L64.1426164,10.0467394 C64.6798075,4.35166889 69.4612665,-4.63857922e-13 75.1816164,-4.54747351e-13 L98.770362,-4.54747351e-13 Z" id="Combined-Shape" fill="#BFE7FF"></path>
                        </g>
                        <g id="Wom2.4" transform="translate(601.000000, 329.500000) scale(-1, 1) translate(-601.000000, -329.500000) translate(517.000000, 200.000000)">
                            <g id="LLeg" transform="translate(36.000000, 133.000000)">
                                <path d="M65.8908818,93.4149994 C65.8908818,93.4149994 60.6450204,104.010202 64.9454404,107.349009 C69.2523271,110.688463 80.6287118,116.946382 80.6287118,116.946382 L50.4889052,115.052257 L57.1885068,91.9444498 L65.8908818,93.4149994 Z" id="Path" fill="url(#linearGradient-20)"></path>
                                <path d="M53.4746784,103.00254 C54.4231236,103.455293 52.8637117,107.712416 56.2007118,107.413122 C59.5367975,107.114781 61.631549,102.714356 64.0917865,103.000306 C66.5529383,103.285303 68.1147092,107.716637 72.7993087,110.802986 C77.4829939,113.890289 84.4279679,117.571673 83.9626164,120.675179 C83.4972649,123.778686 72.5683613,124.609846 67.031684,123.582334 C61.4950067,122.555775 58.9104316,120.330133 55.3183568,120.507422 C51.726282,120.684711 49.4690072,120.245302 48.3426554,117.938642 C47.2172179,115.631981 52.3801916,102.480071 53.4746784,103.00254 Z" id="Path" fill="#EEDFE8"></path>
                                <path d="M53.7411757,117.167073 L54.0483842,117.149485 C58.5001101,116.774046 61.5542762,119.573852 65.7912056,120.363097 C70.0290569,121.153254 81.2049262,121.426384 82.9896728,118.775474 C83.0742349,118.649938 83.1527414,118.521341 83.2256238,118.39065 C83.8250636,119.128483 84.1074632,119.91662 83.9626193,120.835855 C83.4972322,123.789381 72.5674914,124.580375 67.0303901,123.602518 C61.4932887,122.625569 58.9085157,120.507484 55.3161657,120.676205 L54.6776755,120.70015 C51.4543401,120.787711 49.3980991,120.29371 48.33993,118.231564 C48.0823304,117.729149 48.1401075,116.648533 48.3987591,115.283091 C49.9012095,116.311064 51.7976767,117.226371 53.7411757,117.167073 L53.7411757,117.167073 Z" id="Path" fill="#EC7AA5"></path>
                                <path d="M20.4884247,0.0316169952 C29.6130751,0.439025198 80.3983085,11.6716069 89.6251259,13.5799439 C98.8519433,15.488281 106.65262,25.8144285 99.616745,37.7715359 C91.7143193,51.1940195 71.3246138,89.3135618 66.3741517,97.9066496 C63.9238803,102.159868 51.5626728,99.775193 54.4662646,92.6364873 C59.8790807,79.3286591 62.3927978,45.8981264 76.1929423,33.7088609 C76.1929423,33.7088609 29.7832088,41.7859348 9.73613848,32.5338885 C-4.83355488,25.8084198 -1.53748542,-0.953276169 20.4884247,0.0316169952 Z" id="Path" fill="#413C75"></path>
                            </g>
                            <g id="LArm" transform="translate(63.520000, 56.320000)">
                                <path d="M56.620558,19.7542764 C56.620558,19.7542764 56.5174491,14.3298511 59.1145031,10.449811 C61.7115571,6.56977099 61.814666,2.4483177 63.7801783,2.5832251 C65.7456906,2.71813249 63.7995112,12.2978488 63.7995112,12.2978488 C63.7995112,12.2978488 66.2934564,11.3451056 68.0849725,10.1399759 C69.8764887,8.93484614 75.3090359,2.9937567 76.5785635,4.0465508 C78.7309606,5.84036735 75.2639258,15.9590677 73.3757451,17.9820332 C71.4811201,20.0056442 62.1315699,25.66 62.1315699,25.66 L34.5969693,53.3991758 L30.48,38.6733768 L56.620558,19.7542764 Z" id="Path" fill="url(#linearGradient-21)"></path>
                                <path d="M1.54663565,17.3054119 C5.79120596,27.1502283 13.56312,45.2355076 16.7671239,50.6311677 C20.7824169,57.3883826 27.1139989,60.3654607 34.5272997,55.3519295 C35.5612829,54.6526447 37.5630512,53.0800055 40.5326047,50.6340119 C39.8735337,44.2119674 37.4741133,38.8442735 33.3382109,34.5285872 C31.4658326,36.0776539 30.5296435,36.8521873 30.5296435,36.8521873 C30.5296435,36.8521873 22.5637255,16.4323943 16.1557177,4.60344703 C10.6060302,-5.63055297 -4.91428232,2.33889234 1.54663565,17.3054119 Z" id="Path" fill="#EA5455"></path>
                                <path d="M30.6119482,36.8627693 C32.0382744,41.7006022 32.9181507,43.9794623 33.2515771,43.6993494 C33.5850034,43.4192365 33.4576271,40.5081981 32.8694482,34.9662341 L30.6119482,36.8627693 Z" id="Path" fillOpacity="0.159855769" fill="#000000"></path>
                            </g>
                            <g id="Head" transform="translate(35.000000, 0.000000)">
                                <path d="M11.7218772,35.4114385 L11.7218772,44.1226204 L5.3248217,38.9719222 C5.3248217,38.9719222 3.35857854,30.6257151 1.75941921,24.4267409 C0.756609818,20.5394549 -1.68668917,7.38357181 8.14940566,8 C16.2069355,-6.20365389 39.6728533,2.58721143 39.8597256,19.9399601 C40.0844849,40.8108431 39.9010636,48.9367847 39.9010636,48.9367847 C26.6315656,49.6983072 19.1627172,47.7798245 11.7218772,35.4114385 Z" id="Path" fill="#422851"></path>
                                <path d="M5.18659098,51.997375 C5.18659098,51.997375 11.1374613,50.6003429 11.3439066,40.8495617 C11.5503519,31.0987804 11.40543,27.7703624 11.40543,27.7703624 L24.5134378,27.533839 C24.5134378,27.533839 24.6561105,38.4009284 24.6583597,42.3315929 C24.6638284,51.8888195 31.6921775,52.6723672 31.6921775,52.6723672 C31.6921775,52.6723672 30.4907816,60.5575695 18.6133402,61.2254406 C9.33970734,61.7477062 5.18659098,51.997375 5.18659098,51.997375 Z" id="Path" fill="url(#linearGradient-22)"></path>
                                <path d="M8.34980463,22.9012472 C2.50234369,22.6749776 3.45613252,31.2351927 9.53054658,31.2235716 C9.53054658,31.2235716 13.0586589,43.3838644 25.3078124,43.3838644 C35.2557047,43.3838644 35.902832,29.2108175 35.9930663,26.3834737 C36.0901367,23.3688253 36.1024413,13.464919 36.1024413,13.464919 L8.12216791,13.4519308 L8.34980463,22.9012472 Z" id="Path" fill="url(#linearGradient-23)"></path>
                                <path d="M7.89727546,22.8450133 C10.3205807,23.2445966 10.0265395,24.8489843 10.6855974,24.7087265 C14.0315837,23.9973471 27.5756504,24.9357728 29.319619,17.4879832 C31.4895477,22.141013 32.0199848,23.2849038 36.7982257,24.533839 L36.7982257,10.8988839 L6.57645026,8 C6.57645026,8 7.04556985,22.7037464 7.89727546,22.8450133 Z" id="Path" fill="#422851"></path>
                                <path d="M23.1629909,34.2395688 C23.1629909,34.2395688 25.174384,37.4906544 29.10285,36.3347801" id="Path" stroke="#F77B7B" strokeWidth="1.5" strokeLinecap="round" transform="translate(26.132920, 35.411438) rotate(-18.000000) translate(-26.132920, -35.411438) "></path>
                                <path d="M24.8673644,38.1201267 C25.7892466,38.5070311 26.6636527,38.5070311 27.4288074,38.0624194" id="Path" stroke="#F49494" strokeLinecap="round" transform="translate(26.148086, 38.232943) rotate(2.000000) translate(-26.148086, -38.232943) "></path>
                                <path d="M8.61933251,27.6491329 C8.98679677,26.3247221 8.82396936,25.1679422 7.50869093,24.6393988" id="Path-8" stroke="#F77B7B" strokeLinecap="round" transform="translate(8.149406, 26.144266) rotate(-13.000000) translate(-8.149406, -26.144266) "></path>
                            </g>
                            <g id="Body" transform="translate(20.000000, 50.000000)">
                                <path d="M21.5158423,1 C28.983973,5.57401367 37.6176024,7.25091256 45.6546351,2.28384364 C58.7704103,28.1454421 62.0390104,59.0018184 58.8841907,93.1062883 C39.7587424,100.67799 23.6846772,99.1888952 10,90.534496 L21.5158423,1 Z" id="Path" fill="#FFE4F4"></path>
                                <g id="RLeg" transform="translate(2.000000, 79.000000)">
                                    <path d="M112.584626,97.840369 C112.584626,97.840369 112.42648,110.31094 122.70222,114.973501 C132.971493,119.635414 140.288019,119.354755 139.3568,122.959348 C138.425581,126.563294 111.695624,126.417793 109.199441,123.598916 C107.071864,121.201028 102.445333,98.8718252 101.171374,100.57906 L112.584626,97.840369 Z" id="Path" fill="url(#linearGradient-24)"></path>
                                    <path d="M105.12081,115.451758 C106.695528,114.318583 108.019765,120.853164 112.29729,119.035754 C116.574814,117.219286 113.201686,111.213176 115.89361,110.105412 C118.585534,108.997649 123.783778,114.953405 130.687675,116.400932 C137.591572,117.847519 143.197124,119.11433 144.79026,121.801385 C146.162383,124.11103 144.79026,124.849393 144.79026,124.849393 C144.79026,124.849393 138.143796,128.168262 134.013922,128.898801 C128.014416,129.960062 110.674685,130.6668 108.774893,128.731743 C107.608128,127.544921 104.333451,116.019287 105.12081,115.451758 Z" id="Path" fill="#EEDFE8"></path>
                                    <path d="M145.164844,122.072983 C146.466472,124.195559 144.41258,125.299775 144.166441,125.422338 L143.176088,125.844279 C141.295406,126.62556 137.053938,128.301927 133.919083,128.901185 C128.170932,130 110.371438,130.651092 108.456615,128.76162 C108.1202,128.430106 107.70506,127.21704 107.302343,125.640944 L108.523897,125.774184 C114.401831,126.396594 127.522564,127.523521 133.997847,125.998065 C139.745316,124.644068 142.891763,122.416566 144.326431,121.116514 C144.675418,121.415794 144.957168,121.733643 145.164844,122.072983 Z" id="Path" fill="#EC7AA5"></path>
                                    <path d="M5.89797632,0.176460422 C-2.94573928,18.853434 -1.15345138,31.3985644 11.27484,37.8118516 C29.3036138,47.1151178 83.2756203,36.1118516 83.2756203,36.1118516 C83.2756203,36.1118516 82.3111872,43.8766427 83.2756203,50.8019006 C85.7944824,68.8889711 97.7739585,99.0229548 100.308519,102.809363 C103.813523,108.045529 114.490464,104.096649 114.691579,99.7367192 C114.892694,95.3767898 106.642757,39.6931486 105.388198,29.0746682 C104.140107,18.455541 100.199739,16.9130742 88.6909289,14.0102649 C86.0469405,13.3433849 54.7801562,5.5730831 51.9158504,5.7995642 C35.5084792,7.09689753 20.1691879,5.22252961 5.89797632,0.176460422 Z" id="Path" fill="#524D87"></path>
                                    <path d="M20.44,34.9205711 C38.2890882,36.9205711 85.5438118,32.798013 87.4416672,34.6713084 C89.2535985,36.4597915 88.9512823,67.5753949 93.0158622,85.5848345 L92.3977092,83.8841847 C88.5751277,73.2672348 84.605935,60.3544266 83.2756203,50.8019006 C82.3111872,43.8766427 83.2756203,36.1118516 83.2756203,36.1118516 L81.0994032,36.5330893 C70.8399213,38.4602214 27.1401609,45.9987259 11.27484,37.8118516 C2.67846611,33.3759229 -0.829449593,26.0064085 0.751092899,15.7033083 C3.47270775,27.3487456 10.0356597,33.7547592 20.44,34.9205711 Z" id="Path" fillOpacity="0.0561079545" fill="#FFFFFF"></path>
                                </g>
                                <path d="M21.8425791,1.02073112 C34.2109248,41.5374114 41.6283812,98.9791105 3,88.985834 C23.5197512,51.2055231 -4.37555616,34.2015512 1.94717213,14.6758084 C4.10889898,8 21.6811652,0.496984896 21.8425791,1.02073112 Z" id="Path" fill="#EA5455"></path>
                                <path d="M7.89213813,17.8454787 C21.8715353,19.9550268 10.3478768,44.3624052 14.9622087,48.0393418 C19.5765406,51.7162784 27.6081301,52.11297 28.4741625,54.359368 C30.2147182,58.8741883 21.800869,70.2712827 3.2326149,88.5506511 L3,88.985834 C20.8745494,56.0757843 2.0118623,38.9307734 1.0308512,22.1511913 C3.10193703,18.9026674 5.38916036,17.4677691 7.89213813,17.8454787 Z" id="Path" fillOpacity="0.159855769" fill="#000000"></path>
                                <path d="M45,2 C49.7198862,13.8383269 51.9886728,23.1267812 54.1055316,40.5033862 C55.2823565,50.1514197 55.5762854,64.9302169 54.9873184,84.8397778 L60.8200061,85.9867536 C63.5998226,55.2273917 64.5251361,37.8674287 63.5959466,33.9068646 C61.2562605,23.9379639 59.6712171,18.1134529 56.5820212,9 C54.8804313,3.99804952 45,2 45,2 Z" id="Path" fill="#EA5455"></path>
                            </g>
                            <g id="RArm" transform="translate(0.000000, 59.900000)">
                                <path d="M44.44,37.1174069 C44.44,37.1174069 52.0555701,29.49211 54.6929895,28.6072038 C57.3304089,27.7222976 71.7772209,26.6592484 71.6795387,28.6588395 C71.5688322,30.8010741 62.2723573,30.677794 61.412754,31.731807 C60.5531506,32.7851746 59.3577862,40.9883498 56.108225,41.7970934 C52.8651759,42.605837 46.1425409,45 46.1425409,45 L16.2182605,60.4615348 L19.4063845,45.0011933 L44.44,37.1174069 Z" id="Path" fill="url(#linearGradient-25)"></path>
                                <path d="M21.5191872,4.83146563 C12.442156,17.6421902 4.6924099,37.1560477 1.71768334,44.7639402 C-1.25704322,52.3718328 1.7437185,63.8469539 14.8242849,61.594157 C15.8625771,61.4154829 17.2261817,60.9975127 18.9150988,60.3402465 C23.7247511,53.747166 24.5683306,48.0868079 22.7733472,43.0469746 C21.6455348,43.5230441 21.0816287,43.7610789 21.0816287,43.7610789 C21.0816287,43.7610789 34.0824099,20.7168582 37.2570193,14.5393035 C42.5597927,4.22241094 29.5144997,-6.45603437 21.5191872,4.83146563 Z" id="Path" fill="#EA5455"></path>
                                <path d="M21.0816287,43.7610789 C16.6792327,45.9114041 14.7548707,46.513628 15.3085427,45.5677508 C15.8622147,44.6218735 18.6250363,42.5265452 23.5970073,39.2817657 L21.0816287,43.7610789 Z" id="Path" fillOpacity="0.159855769" fill="#000000"></path>
                            </g>
                        </g>
                        <g id="Group-3" transform="translate(544.885264, 274.915964) rotate(-14.000000) translate(-544.885264, -274.915964) translate(529.885264, 254.415964)">
                            <path d="M2.464,0 L26.651893,0 C28.0127227,-2.49980346e-16 29.115893,1.10317038 29.115893,2.464 L29.115893,37.6903837 C29.115893,39.0512133 28.0127227,40.1543837 26.651893,40.1543837 L2.464,40.1543837 C1.10317038,40.1543837 -1.60970328e-15,39.0512133 0,37.6903837 L0,2.464 C-6.10742774e-16,1.10317038 1.10317038,-1.52637649e-15 2.464,0 Z" id="Rectangle" fill="url(#linearGradient-26)"></path>
                            <path d="M3.21908021,0.755918367 L27.4069733,0.755918367 C28.7678029,0.755918367 29.8709733,1.85908874 29.8709733,3.21991837 L29.8709733,38.446302 C29.8709733,39.8071317 28.7678029,40.910302 27.4069733,40.910302 L3.21908021,40.910302 C1.85825059,40.910302 0.755080214,39.8071317 0.755080214,38.446302 L0.755080214,3.21991837 C0.755080214,1.85908874 1.85825059,0.755918367 3.21908021,0.755918367 Z" id="Rectangle" fill="#518DF8"></path>
                            <path d="M3.87105343,1.53621346 L26.6187411,1.53621346 C27.8434878,1.53621346 28.8363411,2.5290668 28.8363411,3.75381346 L28.8363411,35.6026951 C28.8363411,36.8274418 27.8434878,37.8202951 26.6187411,37.8202951 L3.87105343,37.8202951 C2.64630677,37.8202951 1.65345343,36.8274418 1.65345343,35.6026951 L1.65345343,3.75381346 C1.65345343,2.5290668 2.64630677,1.53621346 3.87105343,1.53621346 Z" id="Rectangle" fill="#FFFFFF" fillRule="nonzero"></path>
                        </g>
                        <g id="Group-26" transform="translate(198.804813, 310.871429)">
                            <g id="Group-25" transform="translate(11.570160, 0.000000)">
                                <polygon id="Rectangle" fill="#E9E9E9" points="112.829305 -4.47575684e-13 124.246118 -4.47575684e-13 124.246118 144.879314 112.829305 144.879314"></polygon>
                                <polygon id="Rectangle" fill="#D3D3D3" points="1 4.76228571 91.1887059 4.76228571 91.1887059 31.6708107 16.0280214 31.6708107 16.0280214 144.879314 1 144.879314"></polygon>
                                <polygon id="Rectangle" fill="#DBDBDB" points="0.416812834 4.76228571 8.41681283 4.76228571 8.41681283 144.879314 0.416812834 144.879314"></polygon>
                            </g>
                            <g id="Group-25">
                                <polygon id="Rectangle" fill="#E9E9E9" points="404.624909 4.76228571 420.624909 4.76228571 420.624909 144.879314 404.624909 144.879314"></polygon>
                                <polygon id="Rectangle" fill="#DBDBDB" points="404.624909 -3.33888846e-13 414.624909 -3.33888846e-13 414.624909 144.879314 404.624909 144.879314"></polygon>
                                <polygon id="Rectangle" fill="#D3D3D3" points="272.201583 4.76228571 411.201583 4.76228571 411.201583 31.3185168 290.909124 31.3185168 290.909124 144.879314 272.201583 144.879314"></polygon>
                                <polygon id="Rectangle" fill="#DBDBDB" points="23.7850267 -3.52990207e-13 272.674567 -5.07571128e-13 272.674567 40.2299755 23.7850267 40.2299755"></polygon>
                                <polygon id="Rectangle" fill="#E9E9E9" points="266.96616 4.76228571 278.382973 4.76228571 278.382973 40.2299755 266.96616 40.2299755"></polygon>
                                <polygon id="Rectangle" fill="#DBDBDB" points="272.237176 4.76228571 281.237176 4.76228571 281.237176 144.879314 272.237176 144.879314"></polygon>
                                <path d="M280.814701,0 L425.940379,0 C429.885586,-1.35972169e-14 433.083807,3.19822191 433.083807,7.14342857 C433.083807,11.0886352 429.885586,14.2868571 425.940379,14.2868571 L280.814701,14.2868571 C276.869495,14.2868571 273.671273,11.0886352 273.671273,7.14342857 C273.671273,3.19822191 276.869495,7.24722706e-16 280.814701,0 Z" id="Rectangle" fill="#E9E9E9"></path>
                                <polygon id="Rectangle" fill="#E9E9E9" points="20.9308235 2.85737143 269.820364 2.85737143 269.820364 17.1442286 20.9308235 17.1442286"></polygon>
                                <path d="M7.14342857,0 L283.607759,0 C287.552965,-1.35972169e-14 290.751187,3.19822191 290.751187,7.14342857 C290.751187,11.0886352 287.552965,14.2868571 283.607759,14.2868571 L7.14342857,14.2868571 C3.19822191,14.2868571 4.83148471e-16,11.0886352 0,7.14342857 C-4.83148471e-16,3.19822191 3.19822191,7.24722706e-16 7.14342857,0 Z" id="Rectangle" fill="#DBDBDB"></path>
                            </g>
                        </g>
                        <g id="Men2.8" transform="translate(261.096257, 330.153061) scale(-1, 1) translate(-261.096257, -330.153061) translate(174.596257, 203.153061)">
                            <g id="RLeg" transform="translate(99.474263, 195.228252) scale(-1, 1) translate(-99.474263, -195.228252) translate(54.474263, 136.728252)">
                                <path d="M45.7287959,80.2993067 L53.6261681,105.942582 L37.059015,106.543294 C37.059015,106.543294 38.9301244,97.7716142 36.2589544,89.5489719 C34.1168568,82.9476047 32.3683373,79.6533804 32.3683373,79.6533804 L45.7287959,80.2993067 Z" id="Path" fill="#92691F" transform="translate(42.997253, 93.098337) scale(-1, 1) translate(-42.997253, -93.098337) "></path>
                                <path d="M48.501397,99.4962375 C52.7170881,103.403169 56.8517875,106.080381 60.9054953,107.527873 C66.986057,109.69911 69.3139674,109.723076 69.7475174,110.539468 L69.8264927,110.695281 C71.0769706,113.277644 70.3188093,116.707591 56.4577598,116.897493 C53.0882765,116.943656 49.5857609,114.986551 40.2871115,112.915972 L39.6159316,116.535774 C34.4118509,116.466876 31.3753691,115.646549 30.5064861,114.074795 C29.1967095,111.723623 31.4226845,100.129245 32.9582846,99.9096304 C34.4938848,99.6900154 34.7971335,104.405278 38.9200263,104.508626 C43.0493713,104.611974 48.501397,99.4962375 48.501397,99.4962375 Z" id="Path" fill="#391B0B"></path>
                                <path d="M48.501397,99.4962375 C52.5996391,103.294322 56.6213395,105.930252 60.5664983,107.404028 C59.4499331,108.462611 57.9096449,108.768018 55.9454359,108.319507 C53.5436084,107.771069 50.4686592,105.204872 46.7205882,100.620916 L46.9051671,100.842076 C47.8830703,100.076425 48.501397,99.4962375 48.501397,99.4962375 Z" id="Combined-Shape" fill="#81411E"></path>
                                <path d="M2.50871085,0.031580163 C46.3707109,3.53883715 71.3234451,5.38612497 77.3669134,5.5734436 C86.432116,5.85377563 93.357986,18.7925107 86.3380996,30.7356887 C78.4536317,44.1425358 57.0326549,82.7399565 52.8452411,91.6640748 C49.3030374,99.2084944 33.2050443,96.117091 36.1020378,84.5918273 C39.5603642,70.7980703 46.0060135,39.8516471 59.774798,27.6765816 C59.774798,27.6765816 26.2879797,39.817268 7.42613387,28.0428218 C0.252011557,23.5643992 -1.38712945,14.2273186 2.50871085,0.031580163 Z" id="Path" fill="#7A3636"></path>
                                <path d="M86.3380996,30.7356887 L85.3125956,32.4945979 C81.3219507,39.3902007 74.8793305,51.0414489 68.7278179,62.3888793 L69.0516416,61.3492801 C73.808851,46.2104883 81.20024,28.3770426 84.3601975,22.9355453 C85.4677684,21.0282905 86.9758966,19.0844509 88.884582,17.1040264 C89.6937805,21.2582786 89.078789,26.0728581 86.3380996,30.7356887 Z" id="Path" fillOpacity="0.192498907" fill="#FFFFFF"></path>
                            </g>
                            <g id="LLeg" transform="translate(68.521390, 196.400000) scale(-1, 1) translate(-68.521390, -196.400000) translate(0.521390, 138.900000)">
                                <path d="M102.64994,78.4265306 C102.64994,78.4265306 98.2514508,97.9851802 92.5265933,100.077982 C90.3222949,100.883793 109.418195,103.184887 109.418195,103.184887 L114.212106,84.420727 L102.64994,78.4265306 Z" id="Path" fill="#92691F" transform="translate(103.280779, 90.805709) scale(-1, 1) translate(-103.280779, -90.805709) "></path>
                                <path d="M94.3850267,96.5778672 L94.0657321,113.088834 L100.647736,113.138455 L100.715412,109.426787 C100.715412,109.426787 105.845276,112.503303 109.319004,113.585046 C113.991569,115.04391 132.449797,114.279743 134.535194,112.056712 C136.621558,109.823757 136.666031,106.270877 132.384054,105.834211 C128.103045,105.397544 121.023137,104.097468 117.974803,101.76527 C114.288378,98.9537315 112.885545,94.4937757 111.814326,94.4897959 C110.174626,94.4838515 110.047974,99.7307999 101.728623,99.8052317 C98.5623389,99.8330196 94.3850267,96.5778672 94.3850267,96.5778672 Z" id="Path" fill="#391B0B"></path>
                                <path d="M110.111187,95.9620953 C110.111187,95.9620953 114.356215,102.586018 116.879815,103.545024 C119.403415,104.494343 121.43739,103.428781 121.43739,103.428781 C121.43739,103.428781 117.085922,101.69482 115.405134,98.6686235 C111.597479,91.8141519 110.835947,95.3014467 110.111187,95.9620953 Z" id="Path" fill="#81411E"></path>
                                <path d="M-0.000598121499,3.17448864 C-0.0616731693,17.1882776 4.61567266,25.9563979 14.0314394,29.4788495 C28.1550894,34.761881 74.1447467,32.7736275 74.1447467,32.7736275 C74.1447467,32.7736275 88.7394002,86.4723446 92.2364391,91.7024102 C95.7334781,96.9324757 112.315379,97.4735774 111.418537,85.4276971 C110.515243,73.3818168 104.043785,28.6597226 102.792077,18.0536121 C101.546822,7.44685571 97.6154077,4.90735077 86.1327512,3.00675805 C82.0972665,2.33881046 83.8099567,1.26860074 57.8660948,0.0539242355 C52.2937806,-0.20696827 42.9451872,1.87886493 34.3536986,2.82376289 C28.6260395,3.45369487 17.1746073,3.57060345 -0.000598121499,3.17448864 Z" id="Path" fill="#7A3636"></path>
                                <path d="M45.9024591,3.62842169 L45.9024591,1.18742641 L47.0524754,1.01475591 C51.2348547,0.393872187 55.0371271,-0.0785263824 57.8660948,0.0539242355 L60.8487447,0.198975476 C83.6821386,1.35357389 82.2586859,2.36552836 86.1327512,3.00675805 L86.8789586,3.13307656 C97.5417969,4.98040675 101.40038,7.56093861 102.706028,17.3658769 L103.071428,20.3167375 C104.800314,33.9087396 110.56944,74.1045697 111.418537,85.4276971 C111.486021,86.3341125 111.45454,87.169259 111.337113,87.9365019 L107.975516,71.0093919 C103.022084,45.9132883 100.24637,31.0431975 99.6475253,26.3986423 L99.5227516,25.4336815 C98.4039752,17.1906956 95.4755381,6.96270547 86.0271692,7.62985995 L85.2104777,7.69854756 C78.5583668,8.20478452 65.9423148,6.92871658 47.3623215,3.87034375 L45.9024591,3.62842169 Z" id="Path" fillOpacity="0.192498907" fill="#FFFFFF"></path>
                                <ellipse id="Oval" fillOpacity="0.192498907" fill="#FFFFFF" transform="translate(113.245275, 19.859058) rotate(13.000000) translate(-113.245275, -19.859058) " cx="113.245275" cy="19.8590576" rx="3.77561348" ry="6.6139146"></ellipse>
                            </g>
                            <g id="RArm" transform="translate(104.430481, 51.024490)">
                                <path d="M16.2918063,60.9380157 C16.2918063,60.9380157 4.53367481,71.4884134 5.34540554,72.6381488 C6.15793981,73.7954462 12.1964322,70.7131697 12.1964322,70.7131697 C12.1964322,70.7131697 5.13146234,83.8442556 6.76706775,84.7448466 C8.40187316,85.6454373 16.4118718,83.2597477 18.7657574,80.5084504 C21.120443,77.7571534 24.3442168,65.4031042 24.3442168,65.4031042 L16.2918063,60.9380157 Z" id="Path" fill="url(#linearGradient-27)" transform="translate(14.824724, 72.938131) rotate(28.866471) translate(-14.824724, -72.938131) "></path>
                                <path d="M21.7634434,17.5386176 C26.9186724,23.9009585 44.4964723,43.3352185 44.4964723,43.3352185 C44.4964723,43.3352185 26.9186724,55.854968 22.4925308,58.503266 C15.2339167,62.843891 22.6151207,74.3589122 27.9381044,73.079978 C35.1515538,71.3424361 53.3593692,58.781301 61.9148557,53.6978607 C70.4703423,48.6144204 67.1969886,40.4069359 60.8670605,32.9178421 C56.0046984,27.1650615 45.4340307,15.3795513 37.9969306,5.03348366 C30.5972125,-5.26058011 11.7596751,5.19243789 21.7634434,17.5386176 Z" id="Path" fill="#EA5455"></path>
                                <path d="M44.4964723,43.3352185 C47.7956123,46.514069 49.2737074,48.2265377 48.9307576,48.4726246 C48.5878079,48.7187115 46.428619,47.4949683 42.4531909,44.8013949 L44.4964723,43.3352185 Z" id="Path-15" fillOpacity="0.159855769" fill="#000000"></path>
                            </g>
                            <g id="Head" transform="translate(91.918950, 0.000000)">
                                <path d="M3.5827892,21.8539741 C-0.740118761,22.3274381 -0.433643943,29.9358045 4.82868999,30.3330492 C5.9042553,37.6675428 9.7400296,41.1620042 14.4165127,41.5663541 L14.1571382,52.660139 L34.8425755,52.6478664 C34.8425755,52.6478664 29.0176183,44.8108421 29.3079628,34.0820056 C29.5983074,23.3525232 29.1131094,13.2734886 29.1131094,13.2734886 L2.32075816,13.7547037 L3.5827892,21.8539741 Z" id="Path" fill="url(#linearGradient-28)"></path>
                                <path d="M12.0446399,1.16026676 C-3.89183289,5.18252953 2.68272105,22.1749995 2.68272105,22.1749995 C7.49792435,21.5180924 12.1316393,27.3604962 13.8891917,33.0911546 C18.9703137,38.3135614 25.5509042,38.305072 29.9273646,35.2625744 C33.9412632,22.8474968 35.2916782,14.5729035 33.9786096,10.4387947 C32.0090067,4.23763157 26.5705619,-2.5059821 12.0446399,1.16026676 Z" id="Path" fill="#2C272E"></path>
                            </g>
                            <g id="Body" transform="translate(80.216435, 46.620498)">
                                <path d="M61.4223458,8.5139871 C62.8530629,10.9499731 63.878175,20.7619438 61.4223458,59.6342717 C60.1506366,79.7636433 65.5602374,99.6024849 64.1622219,108.335776 C63.2302116,114.157969 59.5615928,117.416792 53.1563654,118.112245 L47.5532722,99.8833889 L44.9174217,118.112245 C18.4283029,115.635427 4.02306955,111.38182 1.70172178,105.351423 C-1.78763245,96.2867796 2.59402916,74.9761684 3.63637371,54.9097819 C4.68288092,34.7632593 3.89770925,14.0844282 5.82329734,10.9499731 C9.66616895,4.69417647 19.5584387,1.77200571 25.6976132,0.83670437 C33.4885257,-0.351154168 43.5462458,-0.396369012 49.5254082,1.49942479 C55.5045706,3.39457267 59.9916288,6.07800114 61.4223458,8.5139871 Z" id="Path" fill="#EA5455"></path>
                                <path d="M20.9931678,22.357053 C17.8575513,45.4914956 13.6645685,61.7244708 8.4142195,71.0559788 C6.24326124,74.9144489 4.03792025,76.5051918 1.79819652,75.8282077 L2.3303089,70.7014247 C2.86063276,65.5134689 3.36319019,60.1688932 3.63637371,54.9097819 C3.82688483,51.2422137 3.95669359,47.5570046 4.05320459,43.9632115 L20.9931678,22.357053 Z" id="Path" fillOpacity="0.159855769" fill="#000000"></path>
                                <path d="M49.5254082,1.49942479 C49.8879441,1.61433373 50.2384331,1.73363326 50.5775034,1.85720751 C41.9930936,5.60066454 27.818247,5.10007104 20.1122903,2.05099228 C22.1195814,1.4844041 24.0446389,1.08853449 25.6976132,0.83670437 C33.4885257,-0.351154168 43.5462458,-0.396369012 49.5254082,1.49942479 Z" id="Path" fill="#E5AA41"></path>
                            </g>
                            <g id="LArm" transform="translate(5.000000, 53.236735)">
                                <path d="M19.4417882,35.7781658 C19.4417882,35.7781658 6.65735592,27.2433776 4.39781608,25.5291563 C2.14563631,23.8221987 -0.680628509,10.8783752 1.07106687,9.06246285 C1.9174743,8.18356126 8.31337046,14.096172 9.45418047,14.8879098 C10.5876304,15.6723839 16.3873613,19.1661993 16.3873613,19.1661993 C16.3873613,19.1661993 14.7460669,9.25858139 16.1591993,8.45957994 C17.2043285,7.87848798 19.2945869,10.428029 19.5742693,13.1228429 C19.8539518,15.8176569 22.7611773,19.3332633 23.1365406,23.422698 C23.3058221,25.2095558 25.0648775,28.623471 25.0648775,28.623471 L19.4417882,35.7781658 Z" id="Path" fill="url(#linearGradient-29)"></path>
                                <path d="M95.5821368,19.4108665 C90.6831484,29.5906656 82.3627027,44.4728084 77.7927714,53.1992733 C72.3852089,63.5252281 65.9413376,67.9003681 52.9438853,60.2380249 C42.0591902,53.8212081 26.3630709,43.5002279 18.2527794,37.890819 C10.7061275,32.6712464 19.627077,19.7855037 26.7566491,24.1390473 C36.2154298,29.9071695 60.4025146,41.3382202 60.4025146,41.3382202 C60.4025146,41.3382202 72.84954,18.601613 78.0176732,7.6854579 C84.5923646,-6.18903987 105.062605,-0.288967519 95.5821368,19.4108665 Z" id="Path" fill="#EA5455"></path>
                                <path d="M60.4025146,41.3382202 C58.8851847,46.6823271 57.9474793,49.2488078 57.5893984,49.0376621 C57.2313174,48.8265165 57.3570347,45.8770152 57.96655,40.1891583 L60.4025146,41.3382202 Z" id="Path" fillOpacity="0.159855769" fill="#000000"></path>
                            </g>
                        </g>
                        <g id="Chair8" transform="translate(228.251337, 367.800000) scale(-1, 1) translate(-228.251337, -367.800000) translate(172.751337, 277.800000)">
                            <polygon id="Path" fill="#BFE7FF" transform="translate(54.705561, 138.900000) scale(-1, 1) translate(-54.705561, -138.900000) " points="57.4273438 98.4205714 45.984385 179.379429 51.9837792 179.299822 63.426738 99.2670457"></polygon>
                            <polygon id="Path" fill="#91D6FF" points="13.8214615 98.4205714 2.37850267 179.379429 8.37789683 179.299822 19.8208556 99.2670457"></polygon>
                            <polygon id="Path" fill="#BFE7FF" transform="translate(89.590267, 138.900000) scale(-1, 1) translate(-89.590267, -138.900000) " points="92.3120497 98.4205714 80.8690909 179.379429 86.8684851 179.299822 98.3114439 99.2670457"></polygon>
                            <path d="M0,93.7376571 C-7.58852369e-15,88.5212172 4.22876008,84.2924571 9.4452,84.2924571 L47.2075641,84.2924571 C52.9290431,84.2924571 57.7110568,79.9391217 58.2468403,74.2427845 L64.2846873,10.0496727 C64.8204709,4.35333543 69.6024846,-7.25338194e-16 75.3239636,0 L99.0241302,0 C105.147864,-4.67762524e-15 110.11213,4.96426669 110.11213,11.088 C110.11213,11.3714703 110.10126,11.6548363 110.079542,11.9374734 L103.855155,92.9443305 C103.411247,98.7215453 98.5939868,103.182857 92.7997427,103.182857 L9.4452,103.182857 C4.22876008,103.182857 6.38829645e-16,98.9540971 0,93.7376571 Z" id="Path" fill="#BFE7FF"></path>
                            <path d="M69.5030079,1.56342235 L63.1961096,83.4221279 C62.7510336,89.1983365 57.9342102,93.6582857 52.1408797,93.6582857 L0.15785132,93.6582857 L0.00172950146,93.6559136 C0.000578230162,93.5952699 7.4604351e-18,93.5344858 0,93.4735668 C-6.37170347e-16,88.270676 4.21777629,84.0528997 9.42066701,84.0528997 L47.0706042,84.0528997 C52.790954,84.0528997 57.572413,79.7012309 58.1096041,74.0061603 L64.1426164,10.0467394 C64.4856468,6.41007842 66.5593309,3.32120641 69.5030079,1.56342235 Z" id="Combined-Shape" fill="#91D6FF"></path>
                        </g>
                        <g id="Front-Laptop3" transform="translate(458.112299, 290.795918) scale(-1, 1) translate(-458.112299, -290.795918) translate(411.612299, 269.295918)">
                            <path d="M7.37748963,39.5003026 L91.5138558,39.5003026 C91.9261342,39.5003026 92.2603518,39.8345203 92.2603518,40.2467986 L92.2603518,41.3436192 C92.2603518,41.7558976 91.9261342,42.0901152 91.5138558,42.0901152 L7.37748963,42.0901152 C6.96521127,42.0901152 6.63099363,41.7558976 6.63099363,41.3436192 L6.63099363,40.2467986 C6.63099363,39.8345203 6.96521127,39.5003026 7.37748963,39.5003026 Z" id="Rectangle" fill="url(#linearGradient-30)"></path>
                            <path d="M7.36960659,39.5003026 L39.6964761,39.5003026 C40.1087545,39.5003026 40.4429721,39.8345203 40.4429721,40.2467986 L40.4429721,41.3436192 C40.4429721,41.7558976 40.1087545,42.0901152 39.6964761,42.0901152 L7.36960659,42.0901152 C6.95732823,42.0901152 6.62311059,41.7558976 6.62311059,41.3436192 L6.62311059,40.2467986 C6.62311059,39.8345203 6.95732823,39.5003026 7.36960659,39.5003026 Z" id="Rectangle" fill="#518DF8"></path>
                            <path d="M4.09042689,0 L59.9016996,0 C61.7131294,4.21725898e-15 63.2424397,1.34595173 63.4725376,3.14270786 L67.6234634,35.5558685 C67.8760189,37.5279879 66.482037,39.3314431 64.5099176,39.5839986 C64.3582608,39.6034202 64.2055208,39.6131606 64.0526255,39.6131606 L8.2413527,39.6131606 C6.42992297,39.6131606 4.90061265,38.2672089 4.67051471,36.4704528 L0.519588905,4.05729214 C0.267033431,2.08517278 1.66101539,0.281717491 3.63313475,0.0291620171 C3.78479153,0.00974039806 3.93753157,-4.16002795e-16 4.09042689,0 Z" id="Rectangle" fill="#518DF8"></path>
                            <path d="M5.78935737,0 L60.4209037,0 C62.2323334,-3.99458904e-15 63.7616437,1.34595173 63.9917417,3.14270786 L68.1426675,35.5558685 C68.3952229,37.5279879 67.001241,39.3314431 65.0291216,39.5839986 C64.8774648,39.6034202 64.7247248,39.6131606 64.5718295,39.6131606 L9.94028318,39.6131606 C8.12885345,39.6131606 6.59954313,38.2672089 6.36944519,36.4704528 L2.21851939,4.05729214 C1.96596391,2.08517278 3.35994587,0.281717491 5.33206523,0.0291620171 C5.48372201,0.00974039806 5.63646205,9.16264835e-16 5.78935737,0 Z" id="Rectangle" fill="url(#linearGradient-31)"></path>
                            <path d="M7.38112511,1.87381867 L59.1396533,1.87381867 C60.7701083,1.87381867 62.1465746,3.08541835 62.3534636,4.70269398 L66.0936069,33.9398368 C66.3206648,35.7147752 65.0658597,37.3377139 63.2909213,37.5647718 C63.1545741,37.5822139 63.017255,37.5909615 62.8797966,37.5909615 L11.1212685,37.5909615 C9.49081347,37.5909615 8.11434718,36.3793618 7.9074582,34.7620862 L4.16731484,5.52494335 C3.94025694,3.75000495 5.19506202,2.12706629 6.97000043,1.90000839 C7.10634769,1.88256625 7.24366674,1.87381867 7.38112511,1.87381867 Z" id="Rectangle" fill="#FFFFFF" fillRule="nonzero"></path>
                        </g>
                        <g id="Wom3.8" transform="translate(403.000000, 333.500000) scale(-1, 1) translate(-403.000000, -333.500000) translate(323.000000, 209.000000)">
                            <g id="RArm" transform="translate(100.000000, 54.000000)">
                                <path d="M18.766658,58.8685676 C18.766658,58.8685676 8.3873884,68.2130706 9.10927395,69.2373505 C9.83189239,70.2683542 15.1689068,67.5457872 15.1689068,67.5457872 C15.1689068,67.5457872 8.95176853,79.1975538 10.4023089,80.0027207 C11.8521411,80.8078851 18.9364833,78.7101072 21.0125123,76.2712515 C23.0892495,73.8323983 25.9080458,62.8603861 25.9080458,62.8603861 L39.339105,42.0951787 L28.3248475,37.2000412 L18.766658,58.8685676 Z" id="Path" fill="url(#linearGradient-32)" transform="translate(24.206198, 58.688901) rotate(28.866471) translate(-24.206198, -58.688901) "></path>
                                <path d="M19.2325829,15.6171326 C23.7883128,21.2824321 39.3219987,38.5875255 39.3219987,38.5875255 C39.3219987,38.5875255 38.2998793,39.354539 36.2556403,40.8885659 L46.2281444,52.9850753 C50.4734609,50.3839058 53.3023484,48.660502 54.7148067,47.8148638 C62.2753798,43.2883519 59.3826828,35.9800579 53.7888591,29.3114495 C49.4919387,24.1889286 40.1505289,13.6946079 33.5782856,4.48202838 C27.0390773,-4.68424474 10.3921481,4.623568 19.2325829,15.6171326 Z" id="Path" fill="#FFB66F"></path>
                                <path d="M39.3219987,38.5875255 C42.2374833,41.4181095 43.5436915,42.9429646 43.2406232,43.1620909 C42.9375549,43.3812172 41.029457,42.291544 37.5163296,39.8930715 L39.3219987,38.5875255 Z" id="Path-15" fillOpacity="0.159855769" fill="#000000"></path>
                                <polygon id="Path-76" fill="#FFEDC2" points="36.8733934 39.6230062 49.8712165 52.4910007 43.3723049 56.7440582 34.4439738 41.4587852"></polygon>
                            </g>
                            <g id="LLeg" transform="translate(97.500000, 190.500000) scale(-1, 1) translate(-97.500000, -190.500000) translate(55.000000, 132.000000)">
                                <path d="M46.5668594,77.2333195 L53.5975461,99.8820961 L41.779701,100.412659 C41.779701,100.412659 43.4454683,92.6652945 41.0674419,85.402853 C39.1604256,79.5723619 37.6037948,76.6628214 37.6037948,76.6628214 L46.5668594,77.2333195 Z" id="Path" fill="url(#linearGradient-33)" transform="translate(45.600670, 88.537740) scale(-1, 1) translate(-45.600670, -88.537740) "></path>
                                <path d="M50.1838,93.8380441 C50.1838,93.8380441 49.5351656,99.5735608 51.417145,101.531842 C53.2982544,103.489285 63.4827643,110.944734 64.2809041,112.358783 C65.0790444,113.773668 63.6535656,115.33305 63.6535656,115.33305 C63.6535656,115.33305 52.6573272,116.278104 47.7102193,113.286979 C44.138419,111.126304 41.6003668,109.388053 41.6003668,109.388053 L40.2593068,112.231228 L34.3797999,110.962694 C34.3797999,110.962694 32.8732712,106.197386 33.8209841,103.945998 C34.7686967,101.693774 39.5812242,91.2016888 39.5812242,91.2016888 C39.5812242,91.2016888 43.1154161,92.0804739 50.1838,93.8380441 Z" id="Path" fill="#391B0B" transform="translate(49.015819, 103.338220) rotate(-4.000000) translate(-49.015819, -103.338220) "></path>
                                <path d="M10.2477863,0.0306816209 C19.1366453,0.897941472 62.9208029,11.3263078 71.9359273,13.1781876 C80.9510517,15.0300673 88.5727556,25.0507205 81.6983063,36.6540824 C76.550894,45.3376728 66.7013172,63.1723284 52.149576,90.1580492 C52.0149746,90.1906354 51.8831412,90.2488399 51.7466491,90.2488399 C47.3070434,90.2488399 43.3487026,89.6409577 39.5378375,86.9846331 C43.3982104,58.6883787 49.8229051,40.5973676 58.8119214,32.7115998 C58.8119214,32.7115998 23.9942889,45.2313669 6.58158967,31.5713884 C-3.69005504,23.5134507 0.451902999,-0.925073932 10.2477863,0.0306816209 Z" id="Path" fill="#33373E"></path>
                                <path d="M81.6983063,36.6540824 C80.3026051,39.0086048 78.5082353,42.1462455 76.4763627,45.7562038 L78.8097267,16.4424489 C84.0550359,20.6851024 86.6743985,28.2549517 81.6983063,36.6540824 Z" id="Combined-Shape" fillOpacity="0.192498907" fill="#FFFFFF"></path>
                            </g>
                            <g id="RLeg" transform="translate(64.000000, 188.000000) scale(-1, 1) translate(-64.000000, -188.000000) translate(0.000000, 129.000000)">
                                <path d="M99.9147637,78.444262 C99.9147637,78.444262 96.0380697,95.7137655 90.9923559,97.5616251 C89.0495549,98.2731248 102.978186,100.304898 102.978186,100.304898 L107.203393,83.7368972 L99.9147637,78.444262 Z" id="Path" fill="url(#linearGradient-34)" transform="translate(99.004944, 89.374580) scale(-1, 1) translate(-99.004944, -89.374580) "></path>
                                <path d="M94.8069026,92.988282 L95.0579316,114.918808 L101.513184,114.920673 L101.571516,109.516708 C101.571516,109.516708 103.605297,114.554001 106.71714,114.843797 C109.8383,115.133589 125.656627,115.251423 125.656627,115.251423 C125.656627,115.251423 126.814244,110.191688 119.892532,107.897681 C114.135307,105.990778 105.863293,92.9832997 105.863293,92.9832997 L94.8069026,92.988282 Z" id="Path" fill="#391B0B" transform="translate(110.268128, 104.117361) rotate(-9.000000) translate(-110.268128, -104.117361) "></path>
                                <path d="M5.70513092,0.171188567 C-2.84942281,18.2901771 -1.11573713,30.4605146 10.906188,36.6822012 C28.3454771,45.7075272 74.748949,34.0628653 74.748949,34.0628653 C74.748949,34.0628653 73.8160498,41.5956789 74.748949,48.3140408 C77.1854521,65.8607499 89.1675938,87.4488306 90.2576217,91.0067558 C90.8064947,92.7983146 104.170401,90.1510133 104.170401,88.0259087 C104.170401,85.9008041 97.3520533,37.5371691 96.1385149,27.2359217 C94.9312318,16.9340469 91.1197014,15.4376622 79.9871939,12.6215759 C77.4296557,11.9746193 44.2832882,4.43645967 41.5126361,4.65617452 C25.6417348,5.91474929 13.7058997,4.41975397 5.70513092,0.171188567 Z" id="Path" fill="#42474F"></path>
                                <path d="M41.5126361,4.65617452 C44.2832882,4.43645967 77.4296557,11.9746193 79.9871939,12.6215759 L81.8147425,13.0906666 C91.3099724,15.5747962 94.8296588,17.4282373 96.0521092,26.5470471 L96.3810241,29.1647278 C97.3832478,36.8044038 100.091505,54.7687622 102.233934,69.7246215 C99.8160631,64.228401 97.8153236,58.1288369 96.2312129,51.4266386 C92.5395171,35.807479 93.3293035,22.9824205 92.3378596,20.7363731 C91.3464157,18.4903258 42.8586023,11.3197788 34.5270933,9.84022535 C30.9005951,9.1962126 28.8548532,7.59873024 28.3898677,5.04777828 C32.416347,5.16135961 36.7902976,5.03065962 41.5126361,4.65617452 Z" id="Path" fillOpacity="0.192498907" fill="#FFFFFF"></path>
                            </g>
                            <g id="Head" transform="translate(90.000000, 3.000000)">
                                <path d="M2.79727822,21.8774099 C-1.05475583,22.2993469 -0.781663866,29.0796955 3.90746893,29.4337081 C4.865878,35.9699895 8.28383955,39.0841492 12.4509355,39.4444937 L12.2198134,49.3309431 L30.6520838,49.3200062 C30.6520838,49.3200062 25.4616117,42.3358844 25.7203304,32.7746661 C25.9790491,23.2128721 25.5467014,14.2307377 25.5467014,14.2307377 L1.67271425,14.6595823 L2.79727822,21.8774099 Z" id="Path" fill="url(#linearGradient-35)"></path>
                                <path d="M31.0676971,22.3854082 C26.5812429,20.6090374 23.2670715,28.2571527 19.8342786,33.5728159 C16.4014857,38.888479 7.50768049,37.9853051 6.28190267,33.5728159 C1.64120255,23.9084351 -2.35803749,10.7693362 3.05747029,9.69093649 C3.81975489,-4.27674328 40.334047,-0.979145133 31.0676971,22.3854082 Z" id="Path" fill="#9E5229" transform="translate(16.513359, 19.310782) scale(-1, 1) translate(-16.513359, -19.310782) "></path>
                                <path d="M27.287924,4.40477125 C28.8511699,5.73513005 29.8497197,7.50078549 29.9692469,9.69093649 C35.3847547,10.7693362 31.3855147,23.9084351 26.7448146,33.5728159 C25.6050894,37.6755375 17.8361746,38.7443737 13.9916097,34.6013248 C10.4842666,24.0861806 11.9815008,12.3279855 19.0701185,4.4061531 L19.0701185,4.4061531 Z" id="Combined-Shape" fillOpacity="0.199355332" fill="#000000"></path>
                                <ellipse id="Oval" fill="#FB9934" cx="22.1323968" cy="4.4061531" rx="4.40079657" ry="4.4061531"></ellipse>
                            </g>
                            <g id="Body" transform="translate(77.000000, 49.000000)">
                                <path d="M47.3795038,5.00092388 C51.4601078,10.9868536 58.2609677,31.3028809 58.6466896,35.8733349 C59.0812738,41.1453686 53.4213319,49.5436113 53.3282067,57.1756364 C53.2454287,64.8087012 57.5498818,83.9148636 56.2564764,86.0617281 C54.9734183,88.2085925 12.3603561,100.620741 9.95979572,99.6007479 C7.55923534,98.5807545 -0.727991842,87.5809562 1.16555363,83.1104878 C3.06944634,78.6400195 10.9355521,68.4994367 10.035342,62.4081637 C9.14547911,56.3179303 3.97780258,33.8356331 1.41168632,26.1838547 C-1.1440827,18.5320763 -0.291864957,10.498543 4.47821406,7.20599108 C6.8384737,5.57681945 13.1366714,0.948331044 24.3526386,0.968912636 C35.5686058,0.990768133 47.0630568,4.53672064 47.3795038,5.00092388 Z" id="Path" fill="#FF9F43" transform="translate(29.335244, 50.314559) scale(-1, 1) translate(-29.335244, -50.314559) "></path>
                                <path d="M2.1427409,30.9662887 L21.6976699,16.9155054 L21.6917951,19.4832007 C21.6194534,35.3299425 20.6221004,43.724532 4.02453731,69.6948799 L4.40846648,67.0482022 C4.94945857,63.206071 5.36866291,59.6082266 5.34228232,57.1756364 C5.28352964,52.3605924 3.00899078,47.2405673 1.44706274,42.7930274 L2.1427409,30.9662887 Z" id="Path" fillOpacity="0.159855769" fill="#000000"></path>
                                <path d="M23.9551392,0.531763126 C23.640458,2.247835 24.1718956,3.10587094 25.549452,3.10587094 C27.6157866,3.10587094 41.4757318,3.56166728 43.3526833,2.74774121 C44.6039843,2.20512382 45.0419039,1.46646446 44.6664423,0.531763126 L23.9551392,0.531763126 Z" id="Path-68" fill="#FFF6E1"></path>
                            </g>
                            <g id="LArm" transform="translate(15.000000, 53.000000)">
                                <path d="M16.8755276,31.8605027 C16.8755276,31.8605027 5.58923738,24.3281478 3.59448143,22.8152663 C1.60622306,21.3087954 -0.888846271,9.88525798 0.657576908,8.28262928 C1.40479819,7.50695699 7.05119207,12.725116 8.05831641,13.4238622 C9.05894317,14.1161978 14.1790334,17.1996554 14.1790334,17.1996554 C14.1790334,17.1996554 12.7300738,8.45571318 13.9776085,7.75055655 C14.9002643,7.23771536 16.745576,9.48780606 16.9924839,11.8661071 C17.2393918,14.2444081 19.8059345,17.3470972 20.1373109,20.9562171 C20.2867552,22.5332037 20.870786,24.5764745 20.870786,24.5764745 L52.1862437,38.0250648 L48.9099678,52.6479082 L16.8755276,31.8605027 Z" id="Path" fill="url(#linearGradient-36)"></path>
                                <path d="M84.0933791,17.4155857 C79.768478,26.3997406 72.4230627,39.5339367 68.3886583,47.2354553 C63.6147803,56.3485996 57.9260332,60.209868 46.4516857,53.4474871 C45.5564365,52.919874 44.1405647,52.0680798 42.2040703,50.8921046 L49.3076691,35.1197966 C51.7934073,36.2182749 53.0362765,36.7675141 53.0362765,36.7675141 C53.0362765,36.7675141 64.0246988,16.7013812 68.587205,7.06735717 C74.391442,-5.17754438 92.4628796,0.0295489754 84.0933791,17.4155857 Z" id="Path" fill="#FFB66F"></path>
                                <path d="M53.0362765,36.7675141 C51.6967547,41.4839417 50.8689342,43.7489824 50.5528149,43.5626364 C50.2366956,43.3762903 50.3476807,40.7732157 50.8857701,35.7534126 L53.0362765,36.7675141 Z" id="Path" fillOpacity="0.159855769" fill="#000000"></path>
                                <polygon id="Path-75" fill="#FFEDC2" points="49.4084444 34.4233267 44.4606335 54.0580399 37.0989466 50.0774808 46.7589239 32.894335"></polygon>
                            </g>
                            <path d="M125.339469,4.14954723 C125.339469,4.14954723 121.191007,-1.16006118 116.400531,0.232944791 C111.610056,1.62595077 107.276807,10.1648695 109.334321,18.1912867 C111.381444,26.2187436 109.693237,30.7972621 107.209672,36.2071152 C100.538338,50.7598694 123.636235,60 123.636235,60 C122.434753,52.8110285 123.002498,47.046056 125.339469,42.7050824 C128.949677,35.9990465 131,27.3994236 131,22.1369484 C131,12.9856558 125.339469,4.14954723 125.339469,4.14954723 Z" id="Path" fill="#9E5229" transform="translate(118.500000, 30.000000) scale(-1, 1) translate(-118.500000, -30.000000) "></path>
                        </g>
                        <g id="Chair8" transform="translate(362.278075, 367.800000) scale(-1, 1) translate(-362.278075, -367.800000) translate(306.778075, 277.800000)">
                            <polygon id="Path" fill="#91D6FF" points="47.1204989 98.4205714 35.6775401 179.379429 41.6769343 179.299822 53.119893 99.2670457"></polygon>
                            <polygon id="Path" fill="#91D6FF" points="13.8214615 98.4205714 2.37850267 179.379429 8.37789683 179.299822 19.8208556 99.2670457"></polygon>
                            <polygon id="Path" fill="#BFE7FF" transform="translate(89.590267, 138.900000) scale(-1, 1) translate(-89.590267, -138.900000) " points="92.3120497 98.4205714 80.8690909 179.379429 86.8684851 179.299822 98.3114439 99.2670457"></polygon>
                            <path d="M0,93.7376571 C-7.58852369e-15,88.5212172 4.22876008,84.2924571 9.4452,84.2924571 L47.2075641,84.2924571 C52.9290431,84.2924571 57.7110568,79.9391217 58.2468403,74.2427845 L64.2846873,10.0496727 C64.8204709,4.35333543 69.6024846,-7.25338194e-16 75.3239636,0 L99.0241302,0 C105.147864,-4.67762524e-15 110.11213,4.96426669 110.11213,11.088 C110.11213,11.3714703 110.10126,11.6548363 110.079542,11.9374734 L103.855155,92.9443305 C103.411247,98.7215453 98.5939868,103.182857 92.7997427,103.182857 L9.4452,103.182857 C4.22876008,103.182857 6.38829645e-16,98.9540971 0,93.7376571 Z" id="Path" fill="#BFE7FF"></path>
                            <path d="M69.5030079,1.56342235 L63.1961096,83.4221279 C62.7510336,89.1983365 57.9342102,93.6582857 52.1408797,93.6582857 L0.15785132,93.6582857 L0.00172950146,93.6559136 C0.000578230162,93.5952699 7.4604351e-18,93.5344858 0,93.4735668 C-6.37170347e-16,88.270676 4.21777629,84.0528997 9.42066701,84.0528997 L47.0706042,84.0528997 C52.790954,84.0528997 57.572413,79.7012309 58.1096041,74.0061603 L64.1426164,10.0467394 C64.4856468,6.41007842 66.5593309,3.32120641 69.5030079,1.56342235 Z" id="Combined-Shape" fill="#91D6FF"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}

export default LoginSVG;