import Cookies from 'js-cookie'

// save the token and user into the Cookies
export const setUserSession = (token, user) => {
    Cookies.set('user', JSON.stringify(user));
    Cookies.set('token', JSON.stringify(token));
    localStorage.setItem("token", token);
}

// return the token from the Cookies
export const getToken = () =>  {
    const token = Cookies.get('token');
    if (token) return JSON.parse(token);
    return null;
}

// remove the token and user from the Cookies
export const removeUserSession = () => {
    Cookies.remove('token')
    Cookies.remove('user')
    localStorage.removeItem("token");
}