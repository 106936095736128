import { Container, Row, Col, Table, Form } from 'react-bootstrap';
import Linkify from "linkify-react";
import { CiLocationOn, CiCalendar } from "react-icons/ci";
import parse from 'html-react-parser';
import moment from 'moment';
import _ from 'lodash';

// Images
import { Badminton, PickleBall, User, Users, Men, Women } from '../../assets/images/svg'

// Utils
import { convertEventType, convertAgeGroup } from '../../utils/helper';

const OverviewSection = (props) => {
    /**
        * @function renderLink
        * @params { attributes, content }
        * @description used to render link
    */
    const renderLink = ({ attributes, content }) => {
        const { href } = attributes;
        return <a href={href} target="_blank" rel="noopener noreferrer">
            {content}
        </a>
    };

    return <Container fluid>
        {props?.tournament &&
            <Row className='my-4'>
                <Col xs={12} lg={7}>
                    <div className='d-flex flex-column align-items-start'>
                        {moment(new Date(props?.tournament?.registration_end_date)).diff(moment(new Date()), 'days') >= 0 ?
                            <span className='bg-success text-light px-2 rounded-pill fs-14 fw-500'>Open</span>
                            :
                            <span className='bg-danger text-light px-2 rounded-pill fs-14 fw-500'>Closed</span>
                        }
                        <div className='mt-2'>
                            <CiCalendar className='text-orange me-2' size={20} />
                            <span className='fs-14 fw-400 text-secondary'>Registration Date:&nbsp;</span>
                            <span className='fs-14 fw-400 text-secondary'>{moment(new Date(props?.tournament?.registration_start_date)).format("DD/MM/YYYY")} to {moment(new Date(props?.tournament?.registration_end_date)).format("DD/MM/YYYY")}</span>
                        </div>
                        <div className='mt-2'>
                            <CiCalendar className='text-orange me-2' size={20} />
                            <span className='fs-14 fw-400 text-secondary'>Tournament Date:&nbsp;</span>
                            <span className='fs-14 fw-400 text-secondary'>{moment(new Date(props?.tournament?.tournament_start_date)).format("DD/MM/YYYY")} to {moment(new Date(props?.tournament?.tournament_end_date)).format("DD/MM/YYYY")}</span>
                        </div>
                        <div className='mt-2'>
                            <CiLocationOn className='text-orange me-2' size={20} />
                            <span className='fs-14 fw-500 text-secondary'>{props?.tournament?.address}</span>
                        </div>

                        {props?.tournament?.description &&
                            <div className='mt-4 d-flex flex-column align-items-start'>
                                <span className='fs-18 fw-600 mb-2'>Description</span>
                                <span className='fs-14 fw-400 text-secondary white-break-spaces overview-rich-text'>
                                    <Linkify options={{
                                        render: renderLink
                                    }}>
                                        {parse(props?.tournament?.description)}
                                    </Linkify>
                                </span>
                            </div>
                        }

                        {props?.tournament?.prizes &&
                            <div className='mt-4 d-flex flex-column align-items-start'>
                                <span className='fs-18 fw-600 mb-2'>Prizes</span>
                                <span className='fs-14 fw-400 text-secondary white-break-spaces overview-rich-text'>
                                    <Linkify options={{
                                        render: renderLink
                                    }}>
                                        {parse(props?.tournament?.prizes)}
                                    </Linkify>
                                </span>
                            </div>
                        }

                        {props?.tournament?.terms_and_condition &&
                            <div className='mt-4 d-flex flex-column align-items-start'>
                                <span className='fs-18 fw-600 mb-2'>Terms & Conditions</span>
                                <span className='fs-14 fw-400 text-secondary white-break-spaces overview-rich-text'>
                                    <Linkify options={{
                                        render: renderLink
                                    }}>
                                        {parse(props?.tournament?.terms_and_condition)}
                                    </Linkify>
                                </span>
                            </div>
                        }
                    </div>
                </Col>
                <Col xs={12} lg={5} className='mt-3 mt-lg-0'>
                    <div className='d-flex flex-column align-items-start'>
                        <div className='d-flex flex-column align-items-start mb-4 border rounded w-100 px-3 py-2'>
                            <span className='fw-600'>Type of Sports</span>
                            {props?.tournament?.sports_type === "BADMINTON" &&
                                <div className='d-flex flex-column align-items-center text-light bg-orange rounded py-2 px-3 mt-3'>
                                    <Badminton className='img-fluid mt-2 mb-2' />
                                    <span>Badminton</span>
                                </div>
                            }
                            {props?.tournament?.sports_type === "PICKLEBALL" &&
                                <div className='d-flex flex-column align-items-center text-light bg-orange rounded py-2 px-3 mt-3'>
                                    <PickleBall className='img-fluid mt-2 mb-2' />
                                    <span>Pickleball</span>
                                </div>
                            }
                        </div>
                    </div>

                    <div className='d-flex flex-column align-items-start mb-4 border rounded w-100 px-3 py-2'>
                        <span className='fw-600'>Categories</span>
                        {props?.tournament?.category_type === "INDIVIDUAL" &&
                            <div className='d-flex align-items-center mt-2'>
                                <User className='me-2' />
                                Individuals Event
                            </div>
                        }
                        {props?.tournament?.category_type === "TEAM" &&
                            <div className='d-flex justify-content-between align-items-center mt-2 w-100'>
                                <div className='d-flex align-items-center'>
                                    <Users className='me-2' />
                                    Team Event
                                </div>
                                <span className='fw-600'>RM{props?.tournament?.team_event_price}</span>
                            </div>
                        }
                    </div>

                    <div className='d-flex flex-column align-items-start mb-4 border rounded w-100 px-3 py-2'>
                        <span className='fw-600'>Type of Individual Event ({_.uniqBy(props?.tournament?.event_details, 'event_type')?.length || 0})</span>
                        {_.uniqBy(props?.tournament?.event_details, 'event_type')?.length > 0 && _.uniqBy(props?.tournament?.event_details, 'event_type')?.map((eventItem, eventIndex) => {
                            return <div className='d-flex flex-wrap flex-column w-100' key={eventIndex}>
                                <div className='d-flex justify-content-between align-items-center mt-2'>
                                    <div className='d-flex align-items-center text-orange'>
                                        {(eventItem?.event_type === 'MEN_SINGLE' || eventItem?.event_type === 'MEN_DOUBLE' || eventItem?.event_type === 'MIX_DOUBLE' || eventItem?.event_type === 'MEN_3_3' || eventItem?.event_type === 'MIX_3_3' || eventItem?.event_type === 'JUNIOR_SINGLE' || eventItem?.event_type === 'JUNIOR_DOUBLE') && <Men color='#FA5000' className='me-2' />}
                                        {(eventItem?.event_type === 'WOMEN_SINGLE' || eventItem?.event_type === 'WOMEN_DOUBLE' || eventItem?.event_type === 'MIX_DOUBLE' || eventItem?.event_type === 'WOMEN_3_3' || eventItem?.event_type === 'MIX_3_3' || eventItem?.event_type === 'JUNIOR_SINGLE' || eventItem?.event_type === 'JUNIOR_DOUBLE') && <Women color='#FA5000' className='me-2' />}
                                        {eventItem?.event_type && convertEventType(eventItem?.event_type)}
                                    </div>
                                    <span className='text-secondary'>
                                        <span className='text-orange fw-bold'>{_.sumBy(props?.tournament?.event_details?.filter(item => item?.event_type === eventItem?.event_type), 'registered_players') || 0}/{_.sumBy(props?.tournament?.event_details?.filter(item => item?.event_type === eventItem?.event_type), 'allowed_registrations') || 0}</span> Participant
                                    </span>
                                </div>
                                {props?.tournament?.event_details?.filter(item => item?.event_type === eventItem?.event_type)?.length > 0 &&
                                    <Table className='border rounded mt-2' hover bordered>
                                        <thead>
                                            <tr>
                                                <th className="bg-secondary bg-opacity-10">Age</th>
                                                <th className="bg-secondary bg-opacity-10">Price</th>
                                                {eventItem?.event_type === 'MIX_3_3' && <th className="bg-secondary bg-opacity-10">M/F</th>}
                                                <th className="bg-secondary bg-opacity-10">Participant</th>
                                                <th className="bg-secondary bg-opacity-10">District to National Player</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props?.tournament?.event_details?.filter(item => item?.event_type === eventItem?.event_type)?.map((item, index) => {
                                                return <tr key={index}>
                                                    <td>{convertAgeGroup(item?.age_group, item?.age_value)}</td>
                                                    <td>{item?.price}</td>
                                                    {eventItem?.event_type === 'MIX_3_3' && <th>{item?.number_of_males}/{item?.number_of_females}</th>}
                                                    <td>{item?.registered_players}/{item?.allowed_registrations}</td>
                                                    <td>
                                                        <Form.Check
                                                            type='checkbox'
                                                            checked={item?.allow_proffesional_players}
                                                        />
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                }
                                {eventIndex !== _.uniqBy(props?.tournament?.event_details, 'event_type')?.length - 1 && <hr className='w-100' />}
                            </div>
                        })}
                    </div>

                    <div className='d-flex flex-column align-items-start mb-4 border rounded w-100 px-3 py-2'>
                        <div className='d-flex justify-content-between align-items-center w-100 mb-2'>
                            <span className='fs-18 fw-600'>Organizer</span>
                        </div>
                        <span className='text-orange fw-600'>{props?.tournament?.organizer?.name}</span>
                    </div>

                    <div className='d-flex flex-column align-items-start mb-4 border rounded w-100 px-3 py-2'>
                        <div className='d-flex justify-content-between align-items-center w-100 mb-2'>
                            <span className='fs-18 fw-600'>Location</span>
                        </div>
                        <span className='text-secondary'>{props?.tournament?.address}</span>
                    </div>

                    <div className='d-flex flex-column align-items-start mb-4 border rounded w-100 px-3 py-2'>
                        <div className='d-flex justify-content-between align-items-center w-100 mb-2'>
                            <span className='fs-18 fw-600'>Contacts</span>
                        </div>
                        <span className='text-secondary'>{props?.tournament?.contact}</span>
                        <span className='text-secondary fs-14'>{props?.tournament?.phone_number}</span>
                    </div>
                </Col>
            </Row>
        }
    </Container>
};

export default OverviewSection;