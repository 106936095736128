import { useState, useEffect } from 'react'
import { Modal, Form, InputGroup, Spinner } from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { BsCheck2 } from "react-icons/bs";
import classNames from "classnames";

// Custom Component
import ErrorText from '../../components/TextField/ErrorText'
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';
import { PrimaryButton, PrimaryOutlineButton } from '../../components/Button';

// API Service
import { addOrganizerService } from '../../services/organizer.service';
import { allCountryService } from '../../services/country.service';

const Add = (props) => {
    const [countryList, setCountryList] = useState([])
    const [profileImage, setProfileImage] = useState(null);
    const [address, setAddress] = useState();
    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const dispatch = useDispatch()
    const sidebarShow = useSelector((state) => state.sidebarShow)
    const loading = useSelector((state) => state.loading)

    const { register, setError, watch, reset, clearErrors, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    const watchPassword = watch("password")
    const watchPasswordConfirmation = watch("password_confirmation")

    useEffect(() => {
        clearErrors("password")
        clearErrors("password_confirmation")
    }, [watchPassword, watchPasswordConfirmation])

    /**
        * @function fetchCountryList
        * @params
        * @description fetch the list of country
    */
    const fetchCountryList = async () => {
        try {
            const result = await allCountryService({
                paginate: '',
                page: '',
                perPage: '',
                keyword: ''
            });
            if (result?.data?.status) {
                setCountryList(result?.data?.data?.country_list)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCountryList();
    }, [])

    /**
        * @function handleChange
        * @params address
        * @description used for changing the address
    */
    const handleChange = (address) => {
        setAddress(address)
    };

    /**
        * @function handleSelect
        * @params address
        * @description used for selecting the address
    */
    const handleSelect = async (address) => {
        try {
            const result = await geocodeByAddress(address);
            const latAndLang = await getLatLng(result[0])
            setAddress(address)
            setLatitude(latAndLang.lat)
            setLongitude(latAndLang.lng)
        } catch (error) {
            ErrorAlert(error)
        }
    };

    /**
        * @function handleShowPassword
        * @params
        * @description toggle the password input field visibilty
    */
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    /**
        * @function handleShowConfirmPassword
        * @params
        * @description toggle the confirm password input field visibilty
    */
    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    /**
        * @function onSubmit
        * @params formData
        * @description used to add organizer
    */
    const onSubmit = async (formData) => {
        try {
            if (!(formData?.password === formData?.password_confirmation)) {
                setError('password', {
                    type: "manual",
                    message: 'Please make sure your password match.',
                })
                setError('password_confirmation', {
                    type: "manual",
                    message: 'Please make sure your password match.',
                })
                return;
            }
            if (!address && !latitude && !longitude) {
                ErrorAlert('Please select address')
                return;
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const data = new FormData()
            data.append('name', formData?.name)
            data.append('phone', formData?.phone)
            data.append('password', formData?.password)
            data.append('password_confirmation', formData?.password_confirmation)
            if (profileImage instanceof File) {
                data.append('profile_pic', profileImage)
            }
            data.append('email', formData?.email)
            data.append('latitude', latitude)
            data.append('longitude', longitude)
            data.append('address', address)
            data.append('country_id', formData?.country_id)
            data.append('gender', formData?.gender)
            const result = await addOrganizerService(data);
            if (result?.data?.status) {
                SuccessAlert('Organizer added successfully')
                props?.fetchOrganizerList(props?.pageCount)
                reset()
                handleModalClose()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    /**
        * @function handleModalClose
        * @params
        * @description close add organizer modal
    */
    const handleModalClose = () => {
        reset()
        setAddress()
        setLatitude()
        setLongitude()
        setProfileImage()
        props.handleClose()
    }

    return <Modal show={props.show} onHide={handleModalClose} centered>
        <LoadingOverlay
            active={loading}
            spinner={<Spinner animation="border" />}
        >
            <Modal.Header className='fs-18 fw-600 border-0' closeButton>
                Add Organizer
            </Modal.Header>
            <Modal.Body className='d-flex flex-column align-items-center'>
                <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-4">
                        <Form.Label className='fs-14 fw-500'>Name*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Name"
                            {...register("name", {
                                required: "Name is required"
                            })}
                        />
                        {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
                    </Form.Group>

                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>Email address*</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            {...register("email", {
                                required: "Email is required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address"
                                }
                            })}
                        />
                        {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
                    </Form.Group>

                    {countryList.length > 0 &&
                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Phone number*</Form.Label>
                            <InputGroup className='rounded'>
                                <Form.Select
                                    {...register("country_id")}
                                >
                                    {countryList.map((item, index) => {
                                        return <option value={item?.id} key={index}>{item?.code}</option>
                                    })}
                                </Form.Select>
                                <Form.Control
                                    className='flex-grow-5'
                                    type="tel"
                                    placeholder="Enter Phone number"
                                    {...register("phone", {
                                        required: "Phone is required"
                                    })}
                                />
                            </InputGroup>
                            {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                        </Form.Group>
                    }

                    <PlacesAutocomplete
                        value={address}
                        onChange={handleChange}
                        onSelect={handleSelect}
                        debounce={300}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <Form.Group className="my-4">
                                <Form.Label className='fs-14 fw-500'>Address*</Form.Label>
                                <Form.Control
                                    {...getInputProps({
                                        placeholder: 'Search Address',
                                        className: 'bg-transparent rounded-3'
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div className='w-100 text-center mt-3'>
                                        <Spinner animation="border" />
                                    </div>
                                    }
                                    {suggestions.map(suggestion => {
                                        return <div className='mt-4' {...getSuggestionItemProps(suggestion)}>
                                            <span role='button' className={`${suggestion.active ? 'fw-bold' : 'fw-400'}`}>{suggestion.description}</span>
                                        </div>
                                    })}
                                </div>
                            </Form.Group>
                        )}
                    </PlacesAutocomplete>

                    <Form.Group className="mb-4">
                        <Form.Label className='fs-14 fw-500'>Gender</Form.Label>
                        <Form.Select
                            {...register("gender")}
                        >
                            <option value='MALE'>Male</option>
                            <option value='FEMALE'>Female</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label className='fs-14 fw-500'>Password*</Form.Label>
                        <InputGroup className='rounded'>
                            <Form.Control
                                className='border-end-0'
                                type={showPassword ? 'text' : 'password'}
                                placeholder='Enter password'
                                {...register('password', {
                                    required: "Password is required",
                                })}
                            />
                            <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowPassword}>{showPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                        </InputGroup>
                        {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
                        {watchPassword && (!/^(?=.*[A-Z])/.test(watchPassword) || !/^(?=.*[a-z])/.test(watchPassword) || !/^(?=.*[0-9])/.test(watchPassword) || !/^(?=.*\W)/.test(watchPassword) || watchPassword.length < 8) &&
                            <div className='d-flex flex-column align-items-start mt-1'>
                                <div className='d-flex align-items-center'>
                                    <ErrorText className='mb-3'>Your password must contain</ErrorText>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span className={classNames('fs-14', { 'text-success': /^(?=.*[A-Z])/.test(watchPassword) && /^(?=.*[a-z])/.test(watchPassword), 'text-secondary': !/^(?=.*[A-Z])/.test(watchPassword) && !/^(?=.*[a-z])/.test(watchPassword) })}><BsCheck2 className='me-2' size={20} />a lowercase and uppercase letter (a-z,A-Z)</span>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span className={classNames('fs-14', { 'text-success': /^(?=.*[0-9])/.test(watchPassword), 'text-secondary': !/^(?=.*[0-9])/.test(watchPassword) })}><BsCheck2 className='me-2' size={20} />a number</span>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span className={classNames('fs-14', { 'text-success': /^(?=.*\W)/.test(watchPassword), 'text-secondary': !/^(?=.*\W)/.test(watchPassword) })}><BsCheck2 className='me-2' size={20} />a special character($&@!#%^8)</span>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span className={classNames('fs-14', { 'text-success': watchPassword.length >= 8, 'text-secondary': watchPassword.length < 8 })}><BsCheck2 className='me-2' size={20} />at least 8 characters</span>
                                </div>
                            </div>
                        }
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label className='fs-14 fw-500'>Confirm Password*</Form.Label>
                        <InputGroup className='rounded'>
                            <Form.Control
                                className='border-end-0'
                                type={showConfirmPassword ? 'text' : 'password'}
                                placeholder='Enter password'
                                {...register('password_confirmation', {
                                    required: "Confirm Password is required",
                                })}
                            />
                            <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowConfirmPassword}>{showConfirmPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                        </InputGroup>
                        {errors.password_confirmation && <ErrorText>{errors.password_confirmation.message}</ErrorText>}
                    </Form.Group>

                    <input className='d-none' type='file' id="profilePhotoUpload" accept="image/*" onChange={(e) => {
                        if (e.target.files.length > 0) {
                            setProfileImage(e.target.files[0])
                        }
                    }} onClick={(e) => e.target.value = null} />
                    <label htmlFor="profilePhotoUpload">
                        {profileImage instanceof File
                            ?
                            <div role='button' className='d-inline-flex flex-column mb-4'>
                                <span className='mb-2'>Profile Image</span>
                                <img className='img-fluid w-100' src={URL.createObjectURL(profileImage)} alt={'profileImage'} />
                            </div>
                            :
                            profileImage
                                ?
                                <div role='button' className='d-inline-flex flex-column mb-4'>
                                    <span className='mb-2'>Flag</span>
                                    <img className='img-fluid w-100' src={profileImage} alt={'profileImage'} />
                                </div>
                                :
                                <div role='button' className='d-inline-flex flex-column justify-content-between align-items-center mb-4 border px-2 py-3'>
                                    <FaPlus />
                                    <span className='fs-14 fw-400 mt-2'>Add image</span>
                                </div>
                        }
                    </label>

                    <div className='d-flex justify-content-end align-items-center mb-4'>
                        <div className='me-3'>
                            <PrimaryOutlineButton type="button" className='fs-14 fw-500' onClick={handleModalClose}>Cancel</PrimaryOutlineButton>
                        </div>
                        <div>
                            <PrimaryButton className='fs-14 fw-500' type="submit">Add</PrimaryButton>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </LoadingOverlay>
    </Modal>
};

export default Add;