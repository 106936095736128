import axios from "axios"

/**
    * @function allCustomerVariableService
    * @param
    * @description This function is used to get all customer variable
*/
export const allCustomerVariableService = async() =>{
    try {
        const result = await axios.get(`/admin/customer-variable/list`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function withdrawStatusService
    * @param data
    * @description This function is used to update customer variable
*/
export const updateCustomerVariableService = async(data) =>{
    try {
        const result = await axios.post(`/admin/customer-variable/store`, {
            data
        })
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allOrganizerVariableService
    * @param
    * @description This function is used to get all organizer variable
*/
export const allOrganizerVariableService = async() =>{
    try {
        const result = await axios.get(`/admin/organizer-variable/list`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateOrganizerVariableService
    * @param data
    * @description This function is used to update organizer variable
*/
export const updateOrganizerVariableService = async(data) =>{
    try {
        const result = await axios.post(`/admin/organizer-variable/store`, {
            data
        })
        return result;
    } catch (error) {
        return error;
    }
}