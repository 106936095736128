import axios from "axios"

/**
    * @function allWithdrawService
    * @param { paginate, page, perPage, keyword }
    * @description This function is used to get all withdraw history
*/
export const allWithdrawService = async({ paginate, page, perPage, keyword }) =>{
    try {
        const result = await axios.get(`/admin/withdraw/organizer/list?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function withdrawStatusService
    * @param { id, data }
    * @description This function is used to change bank status
*/
export const withdrawStatusService = async({ id, data }) =>{
    try {
        const result = await axios.post(`/admin/withdraw/organizer/status/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function singleWithdrawReceiptService
    * @param { organizerId, transactionId }
    * @description This function is used to get single withdraw receipt
*/
export const singleWithdrawReceiptService = async({ organizerId, transactionId }) =>{
    try {
        const result = await axios.get(`/admin/withdraw/organizer/single-receipt/${organizerId}/${transactionId}`)
        return result;
    } catch (error) {
        return error;
    }
}