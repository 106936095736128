import axios from "axios"

/**
    * @function allOrganizerService
    * @param { paginate, page, perPage, keyword }
    * @description This function is used to get all organizer
*/
export const allOrganizerService = async({ paginate, page, perPage, keyword }) =>{
    try {
        const result = await axios.get(`/admin/organizer/list?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function addOrganizerService
    * @param data
    * @description This function is used to add organizer
*/
export const addOrganizerService = async(data) =>{
    try {
        const result = await axios.post(`/admin/organizer/store`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function organizerStatusService
    * @param id
    * @description This function is used to change organizer status
*/
export const organizerStatusService = async(id) =>{
    try {
        const result = await axios.post(`/admin/organizer/status/${id}`, {})
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateOrganizerService
    * @param { id, data }
    * @description This function is used to update organizer
*/
export const updateOrganizerService = async({ id, data }) =>{
    try {
        const result = await axios.post(`/admin/organizer/edit/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getSingleOrganizerService
    * @param id
    * @description This function is used to get single organizer details
*/
export const getSingleOrganizerService = async(id) =>{
    try {
        const result = await axios.get(`/admin/organizer/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function deleteOrganizerService
    * @param id
    * @description This function is used to delete organizer
*/
export const deleteOrganizerService = async(id) =>{
    try {
        const result = await axios.delete(`/admin/organizer/delete/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}