import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { BsCheck2Circle } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";

const MySwal = withReactContent(Swal)

const SuccessAlert = (message) => {
    MySwal.fire({
        html: (
            <div className="d-flex justify-content-between align-items-center border border-success rounded p-3">
                <div className="d-flex align-items-center">
                    <BsCheck2Circle className='text-success me-2' size={20} />
                    <span className='fs-14 fw-600 text-success'>{message || 'Succss'}</span>
                </div>
                <IoMdClose role='button' className='text-success' size={20} onClick={() => {
                    MySwal.close();
                }} />
            </div>
        ),
        position: 'top',
        timer: 3000
    })
}

export default SuccessAlert;