import { useState, useEffect } from 'react'
import { Modal, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import moment from 'moment';

// Utils
import { convertEventType, convertAgeGroup } from '../../utils/helper';

const PlayerDetailsModal = (props) => {
    const [currentPage, setCurrentPage] = useState(0)
    const [totalEntry, setTotalEntry] = useState(1)
    const [playerList, setPlayerList] = useState([])

    useEffect(() => {
        if (props?.receiptInfo) {
            setPlayerList(props?.receiptInfo?.player_details?.slice(0, 10))
            setTotalEntry(Math.ceil(props?.receiptInfo?.player_details?.length / 10) || 1)
        }
    }, [props?.receiptInfo])

    /**
        * @function handlePagination
        * @params page
        * @description used to handle Pagination
    */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
        setPlayerList(props?.receiptInfo?.player_details?.slice(page?.selected * 10, (page?.selected * 10) + 10))
    }

    /**
        * @function CustomPagination
        * @params
        * @description used for Custom Pagination
    */
    const CustomPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={totalEntry || 1}
            onPageChange={page => handlePagination(page)}
            containerClassName='d-none d-lg-flex pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
        />
    )

    const columns = [
        {
            name: 'Name',
            selector: (row) => row?.player_name ? <span className='fs-14 fw-600 text-capitalize'>{row?.player_name}</span> : '-',
            sortable: true
        },
        {
            name: 'Club Name',
            selector: (row) => row?.club_name ? <span className='fs-14 fw-600 text-capitalize'>{row?.club_name}</span> : '-',
            sortable: true
        },
        {
            name: 'Email address',
            selector: (row) => row?.player_email ? <span className='fs-14 text-secondary'>{row?.player_email}</span> : '-',
            sortable: true,
            minWidth: '300px'
        },
        {
            name: 'Phone number',
            selector: (row) => row?.player_contact ? <span className='fs-14 text-secondary'>{row?.player_contact}</span> : '-',
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Events',
            selector: (row) => row.event_type ? <div className='d-flex flex-column align-items-start fs-14 fw-400 text-secondary'>
                {(row?.team_event_type) ?
                    <span>Team - </span>
                    :
                    <span>Individual - </span>
                }
                {(row?.team_event_type) ?
                    <span>{row?.team_event_type?.map((teamItem, teamIndex) => {
                        return `(${convertEventType(teamItem)}${(row?.team_event_age_group?.length > 0 && row?.team_event_age_value?.length > 0) ? ` (${convertAgeGroup(row?.team_event_age_group[teamIndex], row?.team_event_age_value[teamIndex])})` : ""})`
                    })?.join(", ")}</span>
                    :
                    <span>
                        {row?.event_type && `(${convertEventType(row?.event_type)}${(row?.age_group && row?.age_value) ? ` (${convertAgeGroup(row?.age_group, row?.age_value)})` : ""})`}
                    </span>
                }
            </div> : '-',
            sortable: true,
            minWidth: '250px'
        },
        {
            name: 'Gender',
            selector: (row) => row?.player_gender ? <span className='fs-14 text-secondary'>{row?.player_gender === 'MALE' ? "Male" : 'Female'}</span> : '-',
            sortable: true
        },
        {
            name: 'Player Age',
            selector: (row) => row?.player_dob ? <span className='fs-14 text-secondary'>{`${(row?.player_dob && moment().diff(row?.player_dob, 'years'))}yrs`}</span> : '-',
            sortable: true
        },
        {
            name: 'IC/Passport number',
            selector: (row) => row?.player_idcard ? <span className='fs-14 text-secondary'>{row?.player_idcard}</span> : '-',
            sortable: true,
            minWidth: '180px'
        },
        {
            name: 'Country',
            selector: (row) => row?.nationality ? <span className='fs-14 text-secondary text-capitalize'>{row?.nationality}</span> : '-',
            sortable: true
        },
        {
            name: 'Status',
            selector: (row) => row?.player_status ? <span className='fs-14 text-secondary text-capitalize'>{(row?.player_status === "0" || row?.player_status === "ACTIVE") ? 'ACTIVE' : row?.player_status}</span> : '-',
        }
    ]

    return <Modal show={props.show} onHide={props.handleClose} centered size="lg">
        <Modal.Header className='fs-18 fw-600 border-0' closeButton>
            Player Details
        </Modal.Header>
        <Modal.Body className='d-flex flex-column align-items-center'>
            {playerList?.length > 0 &&
                <>
                    <DataTable
                        className='d-none d-lg-block'
                        columns={columns}
                        data={playerList}
                        pagination
                        paginationServer
                        paginationTotalRows={10}
                        paginationPerPage={10}
                        paginationComponentOptions={{ noRowsPerPage: true }}
                        paginationComponent={CustomPagination}
                        paginationDefaultPage={currentPage + 1}
                    />
                    <div className='w-100'>
                        {playerList?.map((item, index) => {
                            return <div className='d-flex d-lg-none flex-column align-items-start border rounded px-2 py-3 mb-3' key={index}>
                                <div className='d-flex justify-content-between align-items-center w-100 mb-2'>
                                    <span className='fs-14 fw-600 text-capitalize text-truncate'>{item?.player_name}</span>
                                    <div className='d-flex align-items-center'>
                                        <Form.Check
                                            type="switch"
                                            checked={item?.player_status === "0" || item?.player_status === "ACTIVE"}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex flex-column align-items-start w-100 mt-1'>
                                    <span className='fs-14 fw-400 text-secondary mb-1'>Club Name: {item?.club_name || "-"}</span>
                                    {item?.player_status ? <span className='fs-14 text-secondary text-capitalize'>Status: {(item?.player_status === "0" || item?.player_status === "ACTIVE") ? 'ACTIVE' : item?.player_status}</span> : 'Status: -'}
                                </div>
                                <div className='d-flex align-items-center w-100 mt-1'>
                                    <span className='fs-14 fw-400 text-secondary me-4'>{item?.player_gender === 'MALE' ? "Male" : 'Female'}</span>
                                    <span className='fs-14 fw-400 text-secondary'>Age {`${(item?.player_dob && moment().diff(item?.player_dob, 'years'))}yrs`}</span>
                                </div>
                                <div className='d-flex align-items-center w-100 mt-1'>
                                    <span className='fs-14 fw-400 text-secondary me-4'>{item?.player_email}</span>
                                    <span className='fs-14 fw-400 text-secondary'>{item?.player_contact}</span>
                                </div>
                                <div className='w-100 mt-1 fs-14 fw-400 text-secondary'>
                                    {(item?.team_event_type) ?
                                        <span>Team - </span>
                                        :
                                        <span>Individual - </span>
                                    }
                                    {(item?.team_event_type) ?
                                        <span>{item?.team_event_type?.map((teamItem, teamIndex) => {
                                            return `(${convertEventType(teamItem)}${(item?.team_event_age_group?.length > 0 && item?.team_event_age_value?.length > 0) ? ` (${convertAgeGroup(item?.team_event_age_group[teamIndex], item?.team_event_age_value[teamIndex])})` : ""})`
                                        })?.join(", ")}</span>
                                        :
                                        <span>
                                            {item?.event_type && `(${convertEventType(item?.event_type)}${(item?.age_group && item?.age_value) ? ` (${convertAgeGroup(item?.age_group, item?.age_value)})` : ""})`}
                                        </span>
                                    }
                                </div>
                                <div className='d-flex align-items-center w-100 mt-1'>
                                    <span className='fs-14 fw-400 text-secondary me-4'>IC/Passport number : {item?.player_idcard}</span>
                                </div>
                                <div className='d-flex align-items-center w-100 mt-1'>
                                    <span className='fs-14 fw-400 text-secondary text-capitalize me-4'>{item?.nationality}</span>
                                </div>
                            </div>
                        })}
                        <ReactPaginate
                            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                <span className='fs-14 fw-600 me-2'>Next</span>
                                <AiOutlineArrowRight className='text-secondary' size={20} />
                            </div>}
                            breakLabel='...'
                            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                                <span className='fs-14 fw-600'>Previous</span>
                            </div>}
                            pageRangeDisplayed={3}
                            forcePage={currentPage}
                            marginPagesDisplayed={3}
                            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
                            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
                            breakClassName='page-item me-3 d-flex align-items-center'
                            nextClassName='page-item next-item flex-grow-1 text-end'
                            previousClassName='page-item prev-item flex-grow-1 me-3'
                            disabledLinkClassName='btn disabled p-0 border-0'
                            pageCount={totalEntry || 1}
                            onPageChange={page => handlePagination(page)}
                            containerClassName='d-lg-none pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
                        />
                    </div>
                </>
            }
        </Modal.Body>
    </Modal>
};

export default PlayerDetailsModal;