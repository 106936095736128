import { useState } from 'react'
import { Modal, Form, Spinner } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";

// Custom Component
import ErrorText from '../../components/TextField/ErrorText'
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';
import { PrimaryButton, PrimaryOutlineButton } from '../../components/Button';

// API Service
import { addBannerService } from '../../services/banner.service';

const Add = (props) => {
    const [bannerImage, setBannerImage] = useState(null);

    const dispatch = useDispatch()
    const sidebarShow = useSelector((state) => state.sidebarShow)
    const loading = useSelector((state) => state.loading)

    const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

    /**
        * @function onSubmit
        * @params formData
        * @description used to add banner
    */
    const onSubmit = async (formData) => {
        try {
            if (!bannerImage) {
                ErrorAlert('Please upload banner image')
                return;
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const data = new FormData()
            data.append('title', formData?.title)
            data.append('type', formData?.type)
            data.append('url', formData?.url)
            data.append('image', bannerImage)
            const result = await addBannerService(data);
            if (result?.data?.status) {
                SuccessAlert('Banner added successfully')
                props?.fetchBannerList(props?.pageCount)
                handleModalClose()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    /**
        * @function handleModalClose
        * @params
        * @description close add banner modal
    */
    const handleModalClose = () => {
        setBannerImage()
        reset()
        props.handleClose()
    }

    return <Modal show={props.show} onHide={handleModalClose} centered>
        <LoadingOverlay
            active={loading}
            spinner={<Spinner animation="border" />}
        >
            <Modal.Header className='fs-18 fw-600 border-0' closeButton>
                Add Banner
            </Modal.Header>
            <Modal.Body className='d-flex flex-column align-items-center'>
                <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-4">
                        <Form.Label className='fs-14 fw-500'>Title*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Title"
                            {...register("title", {
                                required: "Title is required"
                            })}
                        />
                        {errors.title && <ErrorText>{errors.title.message}</ErrorText>}
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label className='fs-14 fw-500'>Type*</Form.Label>
                        <Form.Select
                            {...register("type", {
                                required: "Type is required"
                            })}
                        >
                            <option value='1'>Customer</option>
                            <option value='2'>Organizer</option>
                        </Form.Select>
                        {errors.type && <ErrorText>{errors.type.message}</ErrorText>}
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label className='fs-14 fw-500'>URL*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter URL"
                            {...register("url", {
                                required: "URL is required"
                            })}
                        />
                        {errors.url && <ErrorText>{errors.url.message}</ErrorText>}
                    </Form.Group>

                    <input className='d-none' type='file' id="bannerPhotoUpload" accept="image/*" onChange={(e) => {
                        if (e.target.files.length > 0) {
                            setBannerImage(e.target.files[0])
                        }
                    }} onClick={(e) => e.target.value = null} />
                    <label htmlFor="bannerPhotoUpload">
                        {bannerImage instanceof File
                            ?
                            <div role='button' className='d-inline-flex flex-column mb-4'>
                                <span className='mb-2'>Image</span>
                                <img className='img-fluid w-100' src={URL.createObjectURL(bannerImage)} alt={'bannerImage'} />
                            </div>
                            :
                            bannerImage
                                ?
                                <div role='button' className='d-inline-flex flex-column mb-4'>
                                    <span className='mb-2'>Image</span>
                                    <img className='img-fluid w-100' src={bannerImage} alt={'bannerImage'} />
                                </div>
                                :
                                <div role='button' className='d-inline-flex flex-column justify-content-between align-items-center mb-4 border px-2 py-3'>
                                    <FaPlus />
                                    <span className='fs-14 fw-400 mt-2'>Add image</span>
                                </div>
                        }
                    </label>

                    <div className='d-flex justify-content-end align-items-center mb-4'>
                        <div className='me-3'>
                            <PrimaryOutlineButton type="button" className='fs-14 fw-500' onClick={handleModalClose}>Cancel</PrimaryOutlineButton>
                        </div>
                        <div>
                            <PrimaryButton className='fs-14 fw-500' type="submit">Add</PrimaryButton>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </LoadingOverlay>
    </Modal>
};

export default Add;