import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

// SCSS
import './assets/scss/style.scss'

// Scrolling Component
import ScrollToTop from './components/ScrollToTop';

// Auth-Service
import PrivateRoute from './utils/PrivateRoute';

const loading = <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
</div>

// Containers
import DefaultLayout from './layout/DefaultLayout';

// Pages
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import Page404 from './pages/page404/Page404';
import Page500 from './pages/page500/Page500';

const App = () => {
    return <BrowserRouter>
        <ScrollToTop />
        <Suspense fallback={loading}>
            <Routes>
                <Route exact path="/login" name="Login Page" element={<Login />} />
                <Route exact path="/register" name="Register Page" element={<Register />} />
                <Route exact path="/404" name="Page 404" element={<Page404 />} />
                <Route exact path="/500" name="Page 500" element={<Page500 />} />
                {/* Private Route */}
                <Route element={<PrivateRoute />}>
                    <Route path="*" name="Home" element={<DefaultLayout />} />
                </Route>
            </Routes>
        </Suspense>
    </BrowserRouter>
}

export default App;
