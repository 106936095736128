import axios from "axios"

/**
    * @function allBannerService
    * @param { paginate, page, perPage, keyword }
    * @description This function is used to get all banner
*/
export const allBannerService = async({ paginate, page, perPage, keyword }) =>{
    try {
        const result = await axios.get(`/admin/banner/list?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function addCountryService
    * @param data
    * @description This function is used to add banner
*/
export const addBannerService = async(data) =>{
    try {
        const result = await axios.post(`/admin/banner/store`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function bannerStatusService
    * @param id
    * @description This function is used to change banner status
*/
export const bannerStatusService = async(id) =>{
    try {
        const result = await axios.post(`/admin/banner/status/${id}`, {})
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateBannerService
    * @param { id, data }
    * @description This function is used to update banner
*/
export const updateBannerService = async({ id, data }) =>{
    try {
        const result = await axios.post(`/admin/banner/update/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getSingleBannerService
    * @param id
    * @description This function is used to get single banner details
*/
export const getSingleBannerService = async(id) =>{
    try {
        const result = await axios.get(`/admin/banner/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function deleteBannerService
    * @param id
    * @description This function is used to delete banner
*/
export const deleteBannerService = async(id) =>{
    try {
        const result = await axios.delete(`/admin/banner/delete/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}