import { useState, useEffect } from 'react'
import { CContainer, CCol, CRow, CCard, CCardHeader } from '@coreui/react'
import DataTable from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux';
import { FaRegEdit } from "react-icons/fa";
import moment from 'moment'

// Custom Component
import Edit from './Edit'
import ErrorAlert from '../../components/Alert/ErrorAlert';

// API Service
import { allCustomerVariableService } from '../../services/variable.service';

const OrganizerString = () => {
    const [customerVariableList, setCustomerVariableList] = useState([])
    const [selectedData, setSelectedData] = useState(null);
    const [editModal, setEditModal] = useState(false)

    const dispatch = useDispatch()
    const sidebarShow = useSelector((state) => state.sidebarShow)

    /**
        * @function fetchListedCustomerVariable
        * @params
        * @description fetch the list of customer variable
    */
    const fetchListedCustomerVariable = async () => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await allCustomerVariableService();
            if (result?.data?.status) {
                setCustomerVariableList(result?.data?.data?.customer_variables_list)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    useEffect(() => {
        fetchListedCustomerVariable()
    }, [])

    /**
        * @function handleEditModal
        * @params row
        * @description set the visibility of edit modal
    */
    const handleEditModal = (row) => {
        setEditModal(!editModal)
        setSelectedData(row)
    }

    const columns = [
        {
            name: 'Sr. No.',
            selector: (row, index) => index + 1,
            width: '80px'
        },
        {
            name: 'Name',
            selector: (row) => row?.name ? row?.name : '--',
            sortable: true,
            wrap: true,
            width: '160px'
        },
        {
            name: 'Value',
            selector: (row) => row?.value ? row?.value : '--',
            sortable: true,
            minWidth: '320px',
            maxWidth: window.innerWidth > 1400 ? '60vw' : window.innerWidth > 575 ? '50vw' : '320px'
        },
        {
            name: 'Actions',
            allowOverflow: true,
            selector: (row) => <>
                <FaRegEdit role='button' className='text-orange' size={20} onClick={() => handleEditModal(row)} />
            </>,
            width: '80px'
        }
    ]

    return <div className="bg-light min-vh-100 d-flex flex-row">
        <CContainer fluid>
            <CRow>
                <CCol xs={12} className='px-0'>
                    <CCard className="mb-4">
                        <CCardHeader className='bg-transparent border-0 text-dark d-flex justify-content-between align-items-center'>
                            <h5>Customer Variable Management</h5>
                        </CCardHeader>
                    </CCard>
                    <div className='react-dataTable react-dataTable-selectable-rows'>
                        <DataTable
                            noHeader
                            columns={columns}
                            className='react-dataTable'
                            data={customerVariableList}
                        />
                    </div>
                </CCol>
            </CRow>
        </CContainer>

        {/* Edit Modal */}
        <Edit show={editModal} handleClose={handleEditModal} selectedData={selectedData} fetchListedCustomerVariable={fetchListedCustomerVariable} />
    </div>
};

export default OrganizerString;
