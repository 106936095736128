import { useState, useEffect } from 'react'
import { Modal, Form, Spinner } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";

// Custom Component
import ErrorText from '../../components/TextField/ErrorText'
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';
import { PrimaryButton, PrimaryOutlineButton } from '../../components/Button';

// API Service
import { updateCountryService } from '../../services/country.service';

const Edit = (props) => {
    const [flagImage, setFlagImage] = useState(null);

    const dispatch = useDispatch()
    const sidebarShow = useSelector((state) => state.sidebarShow)
    const loading = useSelector((state) => state.loading)

    const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

    useEffect(() => {
        if (props?.selectedData) {
            let defaultValues = {};
            defaultValues.name = props?.selectedData?.name
            defaultValues.code = props?.selectedData?.code
            setFlagImage(props?.selectedData?.flag)
            reset({ ...defaultValues })
        }
    }, [props?.selectedData])

    /**
        * @function onSubmit
        * @params formData
        * @description used to update country
    */
    const onSubmit = async (formData) => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const data = new FormData()
            data.append('_method', 'PUT')
            data.append('name', formData?.name)
            data.append('code', formData?.code)
            if (flagImage instanceof File) {
                data.append('flag', flagImage)
            }
            const result = await updateCountryService({
                id: props?.selectedData?.id,
                data
            });
            if (result?.data?.status) {
                SuccessAlert('Country updated successfully')
                props?.fetchListedCountry(props?.pageCount)
                handleModalClose()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    /**
        * @function handleModalClose
        * @params
        * @description close edit country modal
    */
    const handleModalClose = () => {
        setFlagImage()
        reset()
        props.handleClose()
    }

    return <Modal show={props.show} onHide={handleModalClose} centered>
        <LoadingOverlay
            active={loading}
            spinner={<Spinner animation="border" />}
        >
            <Modal.Header className='fs-18 fw-600 border-0' closeButton>
                Update Country
            </Modal.Header>
            <Modal.Body className='d-flex flex-column align-items-center'>
                <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-4">
                        <Form.Label className='fs-14 fw-500'>Name*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Name"
                            {...register("name", {
                                required: "Name is required"
                            })}
                        />
                        {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label className='fs-14 fw-500'>Code*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Code"
                            {...register("code", {
                                required: "Code is required"
                            })}
                        />
                        {errors.code && <ErrorText>{errors.code.message}</ErrorText>}
                    </Form.Group>

                    <input className='d-none' type='file' id="flagPhotoUpload" accept="image/*" onChange={(e) => {
                        if (e.target.files.length > 0) {
                            setFlagImage(e.target.files[0])
                        }
                    }} onClick={(e) => e.target.value = null} />
                    <p className='mb-2'>Flag</p>
                    <label htmlFor="flagPhotoUpload">
                        {flagImage instanceof File
                            ?
                            <div role='button' className='d-inline-flex flex-column'>
                                <img className='border' src={URL.createObjectURL(flagImage)} width={50} alt={'flagImage'} />
                            </div>
                            :
                            flagImage
                                ?
                                <div role='button' className='d-inline-flex flex-column'>
                                    <img className='border' src={flagImage} width={50} alt={'flagImage'} />
                                </div>
                                :
                                <div role='button' className='d-inline-flex flex-column justify-content-between align-items-center mb-4 border px-2 py-3'>
                                    <FaPlus />
                                    <span className='fs-14 fw-400 mt-2'>Add image</span>
                                </div>
                        }
                        <p role='button' className='mb-4'>Browse</p>
                    </label>

                    <div className='d-flex justify-content-end align-items-center mb-4'>
                        <div className='me-3'>
                            <PrimaryOutlineButton type="button" className='fs-14 fw-500' onClick={handleModalClose}>Cancel</PrimaryOutlineButton>
                        </div>
                        <div>
                            <PrimaryButton className='fs-14 fw-500' type="submit">Update</PrimaryButton>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </LoadingOverlay>
    </Modal>
};

export default Edit;