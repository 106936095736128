import axios from "axios"

/**
    * @function allCountryService
    * @param { paginate, page, perPage, keyword }
    * @description This function is used to get all country
*/
export const allCountryService = async({ paginate, page, perPage, keyword }) =>{
    try {
        const result = await axios.get(`/admin/country/list?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function addCountryService
    * @param data
    * @description This function is used to add country
*/
export const addCountryService = async(data) =>{
    try {
        const result = await axios.post(`/admin/country/store`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateCountryService
    * @param { id, data }
    * @description This function is used to update country
*/
export const updateCountryService = async({ id, data }) =>{
    try {
        const result = await axios.post(`/admin/country/update/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getSingleCountryService
    * @param { id }
    * @description This function is used to get single country details
*/
export const getSingleCountryService = async({ id }) =>{
    try {
        const result = await axios.get(`/admin/country/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function deleteCountryService
    * @param id
    * @description This function is used to delete country
*/
export const deleteCountryService = async(id) =>{
    try {
        const result = await axios.delete(`/admin/country/delete/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}