import { useState, useEffect } from 'react'
import { Modal, Spinner } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Custom Component
import { PrimaryButton, PrimaryOutlineButton } from '../../components/Button';
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';

// API Service
import { updateOrganizerVariableService } from '../../services/variable.service';

const Edit = (props) => {
    const [editorValue, setEditorValue] = useState()

    const dispatch = useDispatch()
    const sidebarShow = useSelector((state) => state.sidebarShow)
    const loading = useSelector((state) => state.loading)

    const fontSizeArr = ['32px', '40px', '45px']
    const Font = ReactQuill.Quill.import('formats/font')
    const alignClass = Quill.import('attributors/style/align')
    const Icons = Quill.import('ui/icons');
    Icons.align['left'] = Icons.align[''];

    const backgroundClass = ReactQuill.Quill.import('attributors/style/background')
    const colorClass = ReactQuill.Quill.import('attributors/style/color')
    const directionClass = ReactQuill.Quill.import('attributors/style/direction')
    const fontClass = ReactQuill.Quill.import('attributors/style/font')
    const sizeClass = ReactQuill.Quill.import('attributors/style/size')

    ReactQuill.Quill.register(alignClass, true)
    ReactQuill.Quill.register(backgroundClass, true)
    ReactQuill.Quill.register(colorClass, true)
    ReactQuill.Quill.register(directionClass, true)
    ReactQuill.Quill.register(fontClass, true)
    ReactQuill.Quill.register(sizeClass, true)
    ReactQuill.Quill.register(Font, true)

    sizeClass.whitelist = fontSizeArr
    alignClass.whitelist = ['left', 'center', 'right', 'justify'];
    fontClass.whitelist = ['poppins']

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ size: sizeClass.whitelist }],
            [{ font: fontClass.whitelist }],
            [{ align: alignClass.whitelist }],
            ["link", "image", "video"],
            ["clean"]
        ]

    }

    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "color",
        "indent",
        'link',
        'image',
        'color',
        'size',
        'video',
        'align',
        'background',
        'direction',
        'code-block',
        'code'
    ]

    useEffect(() => {
        if (props?.selectedData) {
            setEditorValue(props?.selectedData?.value)
        }
    }, [props?.selectedData])

    /**
        * @function onSubmit
        * @params
        * @description used to update organizer string
    */
    const onSubmit = async () => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const data = [
                {
                    name: props?.selectedData?.name,
                    value: editorValue
                }
            ];
            const result = await updateOrganizerVariableService(data);
            if (result?.data?.status) {
                SuccessAlert('Organizer String updated successfully')
                props?.fetchListedOrganizerVariable()
                handleModalClose()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    /**
        * @function handleModalClose
        * @params
        * @description close edit organizer string modal
    */
    const handleModalClose = () => {
        setEditorValue()
        props.handleClose()
    }

    return <Modal show={props.show} onHide={handleModalClose} size="lg" centered>
        <LoadingOverlay
            active={loading}
            spinner={<Spinner animation="border" />}
        >
            <Modal.Header className='fs-18 fw-600 border-0' closeButton>
                Update Organizer String
            </Modal.Header>
            <Modal.Body className='d-flex flex-column align-items-center'>
                <ReactQuill
                    theme="snow"
                    value={editorValue}
                    modules={modules}
                    formats={formats}
                    onChange={setEditorValue}
                />
                <div className='d-flex justify-content-end align-items-center w-100 my-4'>
                    <div className='me-3'>
                        <PrimaryOutlineButton className='fs-14 fw-500' onClick={handleModalClose}>Cancel</PrimaryOutlineButton>
                    </div>
                    <div>
                        <PrimaryButton className='fs-14 fw-500' onClick={onSubmit}>Update</PrimaryButton>
                    </div>
                </div>
            </Modal.Body>
        </LoadingOverlay>
    </Modal>
};

export default Edit;