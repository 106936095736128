import { Spinner } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import { useSelector } from 'react-redux'

// Custom Component
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/common'

const DefaultLayout = () => {
    const loading = useSelector((state) => state.loading)
    
    return <LoadingOverlay
        active={loading}
        spinner={<Spinner animation="border" />}
    >
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppHeader />
                <div className="body flex-grow-1 px-3">
                    <AppContent />
                </div>
                <AppFooter />
            </div>
        </div>
    </LoadingOverlay>
}

export default DefaultLayout
