import { Button } from 'react-bootstrap';

export const PrimaryButton = (props) => {
    return <Button className={`w-100 text-light ${props.className ? props.className : ''}`} variant="primary" type={props.type && props.type} onClick={props.onClick} disabled={props.disabled}>{props.children}</Button>;
};

export const PrimaryOutlineButton = (props) => {
    return <Button className={`w-100 ${props.className ? props.className : ''}`} variant="outline-primary" type={props.type && props.type} onClick={props.onClick}>{props.children}</Button>;
};

export const SecondaryButton = (props) => {
    return <Button className={`w-100 text-light p-2 ${props.className ? props.className : ''}`} variant="secondary" type={props.type && props.type} onClick={props.onClick} disabled={props.disabled}>{props.children}</Button>;
};

export const SuccessButton = (props) => {
    return <Button className={`w-100 text-light ${props.className ? props.className : ''}`} variant="success" type={props.type && props.type} onClick={props.onClick} disabled={props.disabled}>{props.children}</Button>;
};

export const DangerButton = (props) => {
    return <Button className={`w-100 text-light ${props.className ? props.className : ''}`} variant="danger" type={props.type && props.type} onClick={props.onClick} disabled={props.disabled}>{props.children}</Button>;
};