import { useState, useEffect, useRef } from 'react'
import { CContainer, CCol, CRow, CCard, CCardHeader } from '@coreui/react'
import { Form, Tabs, Tab, OverlayTrigger, Popover, Table } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import { CSVLink } from "react-csv";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { FaEye, FaDownload, FaFilePdf, FaFileExcel } from "react-icons/fa";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import classNames from "classnames";

// Custom Component
import { PrimaryButton } from '../../components/Button';
import PlayerDetailsModal from './PlayerDetailsModal'
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';

// API Service
import { allWithdrawService, withdrawStatusService, singleWithdrawReceiptService } from '../../services/withdraw.service';
import { allParticipantService, singleReceiptService } from '../../services/participant.service';

// Utils
import { convertEventType, convertAgeGroup } from '../../utils/helper';

const Withdraw = () => {
    const [withdrawList, setWithdrawList] = useState([])
    const [selectedTab, setSelectedTab] = useState('withdraw');
    const [withdrawCurrentPage, setWithdrawCurrentPage] = useState(0);
    const [withdrawTotalEntry, setWithdrawTotalEntry] = useState(1);
    const [participantsList, setParticipantsList] = useState([]);
    const [participantCurrentPage, setParticipantCurrentPage] = useState(0);
    const [participantTotalEntry, setParticipantTotalEntry] = useState(1);
    const [playerDetailsModal, setPlayerDetailsModal] = useState(false);
    const [receiptInfo, setReceiptInfo] = useState();
    const [withdrawCSVData, setWithdrawCSVData] = useState([]);
    const [withdrawReceiptCSVData, setWithdrawReceiptCSVData] = useState();
    const [selectedIndex, setSelectedIndex] = useState();
    const [pdfContentShow, setPdfContentShow] = useState(false);
    const [participantCSVData, setParticipantCSVData] = useState([]);
    const countPerPage = 10;

    const pdfRef = useRef(null);

    const dispatch = useDispatch();
    const sidebarShow = useSelector((state) => state.sidebarShow);

    const withdrawCSVHeaders = [
        {
            label: 'Name',
            key: 'name'
        },
        {
            label: 'Phone',
            key: 'phone'
        },
        {
            label: 'A/C Number',
            key: 'account_number'
        },
        {
            label: 'A/C Holder Name',
            key: 'holder_name'
        },
        {
            label: 'Bank',
            key: 'bank_name'
        },
        {
            label: 'Amount',
            key: 'amount'
        },
        {
            label: 'Status',
            key: 'is_withdrawn'
        },
        {
            label: 'Created Date',
            key: 'created_at'
        }
    ]

    const withdrawReceiptCSVHeaders = [
        {
            label: 'ID ',
            key: 'id'
        },
        {
            label: 'User Name',
            key: 'name'
        },
        {
            label: 'Phone No.',
            key: 'phone'
        },
        {
            label: 'Tournament Name',
            key: 'tournament_name'
        },
        {
            label: 'Event',
            key: 'event'
        },
        {
            label: 'No. of Players',
            key: 'no_of_players'
        },
        {
            label: 'Paid Amount',
            key: 'paid_amount'
        },
        {
            label: 'Joined Date',
            key: 'joined_date'
        }
    ]

    const participantCSVHeaders = [
        {
            label: 'User Name',
            key: 'customer_name'
        },
        {
            label: 'Phone No',
            key: 'customer_phone'
        },
        {
            label: 'Tournament Name',
            key: 'tournament_name'
        },
        {
            label: 'Event Name',
            key: 'event_type'
        },
        {
            label: 'Paid Amount',
            key: 'event_price'
        },
        {
            label: 'Joined Date',
            key: 'created_at'
        }
    ]

    /**
        * @function fetchListedWithdraw
        * @params page
        * @description fetch the list of withdraw
    */
    const fetchListedWithdraw = async (page) => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await allWithdrawService({
                paginate: 1,
                page: page + 1,
                perPage: 10,
                keyword: ""
            });
            if (result?.data?.status) {
                setWithdrawList(result?.data?.data?.withdraw_lists)
                setWithdrawTotalEntry(result?.data?.data?.pagination?.total_pages)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    /**
        * @function fetchAllListedWithdraw
        * @params 
        * @description fetch the list of all withdraw
    */
    const fetchAllListedWithdraw = async () => {
        try {
            const result = await allWithdrawService({
                paginate: 0,
                page: "",
                perPage: "",
                keyword: ""
            });
            if (result?.data?.status) {
                setWithdrawCSVData(result?.data?.data?.withdraw_lists?.map(item => { 
                    return {
                        name: item?.organization?.name || "",
                        phone: item?.organization?.phone ? `=""${item?.organization?.phone}""` : '-',
                        account_number: item?.organization_bank?.account_number ? `=""${item?.organization_bank?.account_number}""` : '-',
                        holder_name: item?.organization_bank?.holder_name || "",
                        bank_name: item?.bank_name || "",
                        amount: item?.amount || "",
                        is_withdrawn: item?.is_withdrawn || "",
                        created_at: moment(item.created_at).format("DD-MM-YYYY")
                    }
                }))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchListedWithdraw(withdrawCurrentPage)
        fetchAllListedWithdraw()
    }, [])

    useEffect(() => {
        if (withdrawReceiptCSVData && parseInt(selectedIndex) >= 0) {
            setTimeout(() => {
                const element = document.getElementById(`receipt${selectedIndex}`);
                if (element) {
                    dispatch({
                        type: 'set',
                        sidebarShow,
                        loading: true
                    })
                    element?.click();
                    setWithdrawReceiptCSVData();
                    setSelectedIndex();
                    dispatch({
                        type: 'set',
                        sidebarShow,
                        loading: false
                    })
                }
            });
        }
    }, [withdrawReceiptCSVData])

    /**
        * @function handleDownload
        * @params
        * @description used to download withdraw receipt pdf
    */
    const handleDownload = () => {
        setTimeout(() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            setPdfContentShow(true)
        }, 1000);
        setTimeout(() => {
            const content = pdfRef.current;
            console.log(content);
            html2canvas(content, {
                useCORS: true,
                allowTaint: true,
                width: content?.offsetWidth,
                height: content?.offsetHeight
            }).then((canvas) => {
                const imgData = canvas.toDataURL('img/png');
                const orientation = content?.offsetWidth >= content?.offsetHeight ? 'l' : 'p'
                const doc = new jsPDF({
                    orientation,
                    unit: 'px'
                })
                doc.internal.pageSize.width = content?.offsetWidth;
                doc.internal.pageSize.height = content?.offsetHeight;
                doc.addImage(imgData, 'PNG', 0, 0, content?.offsetWidth, content?.offsetHeight);
                doc.save(`withdraw_receipt.pdf`);
                dispatch({
                    type: 'set',
                    sidebarShow,
                    loading: false
                })
                setPdfContentShow(false)
                setWithdrawReceiptCSVData([])
                setSelectedIndex();
            })
        }, 2000);
    }

    /**
        * @function fetchListedParticipant
        * @params page
        * @description fetch the list of participant
    */
    const fetchListedParticipant = async (page) => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await allParticipantService({
                paginate: 1,
                page: page + 1,
                perPage: 10,
                keyword: ""
            });
            if (result?.data?.status) {
                setParticipantsList(result?.data?.data?.customer_lists?.data)
                setParticipantTotalEntry(result?.data?.data?.pagination?.total_pages)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    /**
        * @function fetchAllListedParticipant
        * @params
        * @description fetch the list of all participant
    */
    const fetchAllListedParticipant = async () => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await allParticipantService({
                paginate: 0,
                page: "",
                perPage: "",
                keyword: ""
            });
            if (result?.data?.status) {
                setParticipantCSVData(result?.data?.data?.customer_lists?.map(item => {
                    return {
                        customer_name: item?.customer_name || "",
                        customer_phone: item?.customer_phone ? `=""${item?.customer_phone}""` : '-',
                        tournament_name: item?.tournaments?.length > 0 ? item?.tournaments[0]?.tournament_name : '-',
                        event_type: item?.tournaments?.length > 0 ? (!item?.tournaments[0]?.players?.team_event_type ? `Individual - (${convertEventType(item?.tournaments[0]?.players?.event_type)}${(item?.tournaments[0]?.players?.age_group && item?.tournaments[0]?.players?.age_value) ? ` (${convertAgeGroup(item?.tournaments[0]?.players?.age_group, item?.tournaments[0]?.players?.age_value)})` : ""})}` : 'Team - ' + item?.tournaments[0]?.players?.team_event_type.map((teamItem, teamIndex) => {
                            return `(${convertEventType(teamItem)}${(item?.tournaments[0]?.players?.team_event_age_group?.length > 0 && item?.tournaments[0]?.players?.team_event_age_value?.length > 0) ? ` (${convertAgeGroup(item?.tournaments[0]?.players?.team_event_age_group[teamIndex], item?.tournaments[0]?.players?.team_event_age_value[teamIndex])})` : ""})`
                        }).join(" , ")) : '-',
                        event_price: item?.tournaments?.length > 0 ? item?.tournaments[0]?.players?.event_price : "",
                        created_at: item?.tournaments?.length > 0 ? moment(item?.tournaments[0]?.players.created_at).format("DD-MM-YYYY") : ""
                    }
                }))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    useEffect(() => {
        fetchListedParticipant(participantCurrentPage)
        fetchAllListedParticipant()
    }, [])

    /**
        * @function handlePlayerDetailsModal
        * @params receipt_no, tournamentId
        * @description set the visibility of player details modal
    */
    const handlePlayerDetailsModal = (receipt_no, tournamentId) => {
        if (receipt_no && tournamentId) {
            handleSingleReceipt(receipt_no, tournamentId)
        } else {
            setPlayerDetailsModal(!playerDetailsModal)
        }
    }

    /**
        * @function handleSingleReceipt
        * @params receipt_no, tournamentId
        * @description get the value of receipt
    */
    const handleSingleReceipt = async (receipt_no, tournamentId) => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await singleReceiptService({
                receipt_no,
                tournamentId
            });
            if (result?.data?.status) {
                setReceiptInfo(result?.data?.data)
                setPlayerDetailsModal(!playerDetailsModal)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    /**
        * @function handleSingleWithdrawReceipt
        * @params organizerId, transactionId, index, pdf
        * @description get the value of receipt for withdraw
    */
    const handleSingleWithdrawReceipt = async (organizerId, transactionId, index, pdf) => {
        try {
            document?.body?.click();
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await singleWithdrawReceiptService({
                organizerId,
                transactionId
            });
            if (result?.data?.status) {
                if (result?.data?.data?.wallet_transaction_details?.length > 0) {
                    setWithdrawReceiptCSVData(result?.data?.data?.wallet_transaction_details?.map((item) => {
                        return {
                            id: item?.id || "",
                            name: item?.customer?.username || "",
                            phone: item?.customer?.phone ? `=""${item?.customer?.phone}""` : '-',
                            tournament_name: item?.tournament?.name || "",
                            event: [...new Set(item?.tournament?.players?.map(playerItem => convertEventType(playerItem?.event_type)))]?.join(", ") || "",
                            no_of_players: item?.tournament?.players?.length || 0,
                            paid_amount: item?.updated_amount,
                            joined_date: moment(item.created_at).format("DD-MM-YYYY")
                        }
                    }))
                    if (pdf) {
                        handleDownload()
                    } else {
                        setSelectedIndex(index)
                    }
                } else {
                    setWithdrawReceiptCSVData([])
                    if (pdf) {
                        handleDownload()
                    } else {
                        setSelectedIndex(index)
                    }
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    /**
        * @function withdrawStatusUpdate
        * @params rowRecord
        * @description used to change status pf withdraw
    */
    const withdrawStatusUpdate = async (rowRecord) => { 
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const data = new FormData()
            data.append('is_approved', rowRecord?.is_withdrawn === 0 ? 1 : 0)
            const result = await withdrawStatusService({
                id: rowRecord?.id, 
                data
            });
            if (result?.data?.status) {
                SuccessAlert('Status updated successfully')
                fetchListedWithdraw(withdrawCurrentPage)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    const withdrawColumns = [
        {
            name: 'ID',
            selector: (row, index) => withdrawCurrentPage > 0 ? (withdrawCurrentPage * 10) + index + 1 : index + 1
        },
        {
            name: 'Name',
            selector: (row) => row?.organization?.name ? row?.organization?.name : '--',
            sortable: true
        },
        {
            name: 'Phone',
            selector: (row) => row?.organization?.phone ? row?.organization?.phone : '--',
            sortable: true
        },
        {
            name: 'A/C Number',
            selector: (row) => row?.organization_bank?.account_number ? row?.organization_bank?.account_number : '--',
            sortable: true
        },
        {
            name: 'A/C Holder Name',
            selector: (row) => row?.organization_bank?.holder_name ? row?.organization_bank?.holder_name : '--',
            sortable: true
        },
        {
            name: 'Bank',
            selector: (row) => row?.bank_name ? row?.bank_name : '--',
            sortable: true
        },
        {
            name: 'Amount',
            selector: (row) => row?.amount ? 'RM' + parseFloat(row?.amount)?.toFixed(2) : '--',
            sortable: true
        },
        {
            name: 'Status',
            selector: (row) => (
                <Form.Check
                    type="switch"
                    id="success-switch"
                    checked={row?.is_withdrawn}
                    onChange={() => withdrawStatusUpdate(row)}
                    defaultChecked={row?.is_withdrawn}
                />
            )
        },
        {
            name: 'Created Date',
            selector: (row) => moment(row.created_at).format("DD-MM-YYYY")
        },
        {
            name: '',
            selector: (row, index) => <>
                <OverlayTrigger
                    trigger="click"
                    placement="top"
                    rootClose
                    overlay={(
                        <Popover id={`popover-positioned-top${index}`}>
                            <div className='d-flex align-items-center p-2'>
                                <div className='me-2'>
                                    <FaFilePdf role='button' className='text-danger' size={20} onClick={() => handleSingleWithdrawReceipt(row?.organization?.id, row?.id, index, true)} />
                                </div>
                                <div>
                                    <FaFileExcel role='button' className='text-success' size={20} onClick={() => handleSingleWithdrawReceipt(row?.organization?.id, row?.id, index, false)} />
                                </div>
                            </div>
                        </Popover>
                    )}>
                    <div>
                        <FaDownload role='button' className='text-orange' size={20} />
                    </div>
                </OverlayTrigger>
                {withdrawReceiptCSVData &&
                    <CSVLink
                        filename={`withdraw_receipt.csv`}
                        data={withdrawReceiptCSVData}
                        headers={withdrawReceiptCSVHeaders}
                        id={`receipt${index}`}
                    />
                }
            </>
        }
    ]

    const ParticipantColumns = [
        {
            name: 'ID',
            selector: (row, index) => participantCurrentPage > 0 ? (participantCurrentPage * 10) + index + 1 : index + 1
        },
        {
            name: 'User Name',
            selector: (row) => row?.customer_name ? row?.customer_name : '--',
            sortable: true
        },
        {
            name: 'Phone No',
            selector: (row) => row?.customer_phone ? row?.customer_phone : '--',
            sortable: true
        },
        {
            name: 'Tournament Name',
            selector: (row) => row?.tournaments?.length > 0 ? row?.tournaments[0]?.tournament_name : '--',
            sortable: true
        },
        {
            name: 'Event Name',
            selector: (row) => row?.tournaments?.length > 0 ?
                <>
                    {(row?.tournaments[0]?.players?.team_event_type) ?
                        <span>{row?.tournaments[0]?.players?.team_event_type?.map((teamItem, teamIndex) => {
                            return `(${convertEventType(teamItem)}${(row?.tournaments[0]?.players?.team_event_age_group?.length > 0 && row?.tournaments[0]?.players?.team_event_age_value?.length > 0) ? ` (${convertAgeGroup(row?.tournaments[0]?.players?.team_event_age_group[teamIndex], row?.tournaments[0]?.players?.team_event_age_value[teamIndex])})` : ""})`
                        })?.join(", ")}</span>
                        :
                        <span>
                            {row?.tournaments[0]?.players?.event_type && `(${convertEventType(row?.tournaments[0]?.players?.event_type)}${(row?.tournaments[0]?.players?.age_group && row?.tournaments[0]?.players?.age_value) ? ` (${convertAgeGroup(row?.tournaments[0]?.players?.age_group, row?.tournaments[0]?.players?.age_value)})` : ""})`}
                        </span>
                    }
                </>
                : '--'
            ,
            sortable: true
        },
        {
            name: 'Players',
            selector: (row) => row?.tournaments?.length > 0 && row?.tournaments[0]?.players?.receipt_no ? <FaEye role='button' className='text-orange' size={20} onClick={() => handlePlayerDetailsModal(row?.tournaments[0]?.players?.receipt_no, row?.tournaments[0]?.tournament_id)} /> : '--',
            sortable: true
        },
        {
            name: 'Paid Amount',
            selector: (row) => row?.tournaments?.length > 0 ? 'RM' +parseFloat(row?.tournaments[0]?.players?.event_price)?.toFixed(2) : '--',
            sortable: true
        },
        {
            name: 'Joined Date',
            selector: (row) => row?.tournaments?.length > 0 ? moment(row?.tournaments[0]?.players.created_at).format("DD-MM-YYYY") : '--'
        }
    ]

    /**
        * @function handleWithdrawPagination
        * @params page
        * @description used to handle Pagination of Withdraw
    */
    const handleWithdrawPagination = (page) => {
        setWithdrawCurrentPage(page.selected)
        fetchListedWithdraw(page.selected)
    }

    /**
        * @function handleParticipantPagination
        * @params page
        * @description used to handle Pagination of Participant
    */
    const handleParticipantPagination = (page) => {
        setParticipantCurrentPage(page.selected)
        fetchListedParticipant(page.selected)
    }

    // ** Custom Pagination of Withdraw
    const CustomWithdrawPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={withdrawCurrentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={withdrawTotalEntry || 1}
            onPageChange={page => handleWithdrawPagination(page)}
            containerClassName='pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
        />
    )

    // ** Custom Pagination of Participant
    const CustomParticipantPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={participantCurrentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={participantTotalEntry || 1}
            onPageChange={page => handleParticipantPagination(page)}
            containerClassName='pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
        />
    )

    return <div className="bg-light min-vh-100 d-flex flex-row">
        <CContainer fluid>
            <CRow>
                <CCol xs={12} className='px-0'>
                    <Tabs
                        id="fill-tab-example"
                        className="mb-3 bg-gray p-1 rounded border-bottom-0"
                        activeKey={selectedTab}
                        onSelect={(k) => setSelectedTab(k)}
                    >
                        <Tab eventKey="withdraw" title={<span className={classNames({ 'text-secondary': selectedTab !== "withdraw", 'text-orange': selectedTab === "withdraw" })}>Withdraw Request</span>}>
                        </Tab>
                        <Tab eventKey="participant" title={<span className={classNames({ 'text-secondary': selectedTab !== "participant", 'text-orange': selectedTab === "participant" })}>Participant List</span>}>
                        </Tab>
                    </Tabs>
                </CCol>
                {selectedTab === "withdraw" &&
                    <CCol xs={12} className='px-0'>
                        <CCard className="mb-4">
                            <CCardHeader className='bg-transparent border-0 text-dark d-flex justify-content-between align-items-center'>
                                <h5>Withdraw Management</h5>
                                <div className='d-flex flex-wrap align-items-center'>
                                    <div className='me-3'>
                                        {withdrawCSVData?.length > 0 &&
                                            <CSVLink filename={`withdraw.csv`} data={withdrawCSVData} headers={withdrawCSVHeaders}>
                                                <PrimaryButton>Export</PrimaryButton>
                                            </CSVLink>
                                        }
                                    </div>
                                </div>
                            </CCardHeader>
                        </CCard>
                        <div className='react-dataTable react-dataTable-selectable-rows'>
                            <DataTable
                                noHeader
                                columns={withdrawColumns}
                                className='react-dataTable'
                                data={withdrawList}
                                pagination
                                paginationServer
                                paginationTotalRows={withdrawTotalEntry}
                                paginationPerPage={countPerPage}
                                paginationComponentOptions={{ noRowsPerPage: true }}
                                paginationComponent={CustomWithdrawPagination}
                                paginationDefaultPage={withdrawCurrentPage + 1}
                            />
                        </div>
                    </CCol>
                }
                {selectedTab === "participant" &&
                    <CCol xs={12} className='px-0'>
                        <CCard className="mb-4">
                            <CCardHeader className='bg-transparent border-0 text-dark d-flex justify-content-between align-items-center'>
                                <h5>Participants Management</h5>
                                <div className='d-flex flex-wrap align-items-center'>
                                    <div className='me-3'>
                                        {participantCSVData?.length > 0 &&
                                            <CSVLink filename={`participant.csv`} data={participantCSVData} headers={participantCSVHeaders}>
                                                <PrimaryButton>Export</PrimaryButton>
                                            </CSVLink>
                                        }
                                    </div>
                                </div>
                            </CCardHeader>
                        </CCard>
                        <div className='react-dataTable react-dataTable-selectable-rows'>
                            <DataTable
                                noHeader
                                columns={ParticipantColumns}
                                className='react-dataTable'
                                data={participantsList}
                                pagination
                                paginationServer
                                paginationTotalRows={participantTotalEntry}
                                paginationPerPage={countPerPage}
                                paginationComponentOptions={{ noRowsPerPage: true }}
                                paginationComponent={CustomParticipantPagination}
                                paginationDefaultPage={participantCurrentPage + 1}
                            />
                        </div>
                    </CCol>
                }
            </CRow>

            <Table ref={pdfRef} striped bordered hover className={classNames({
                'd-none': !pdfContentShow
            })}>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>User Name</th>
                        <th>Phone No.</th>
                        <th>Tournament Name</th>
                        <th>Event</th>
                        <th>No. of Players</th>
                        <th>Paid Amount</th>
                        <th>Joined Date</th>
                    </tr>
                </thead>
                <tbody>
                    {withdrawReceiptCSVData?.length > 0 && withdrawReceiptCSVData?.map((item, index) => {
                        return <tr key={index}>
                            <td>{item?.id}</td>
                            <td>{item?.name}</td>
                            <td>{item?.phone?.slice(3, -2)}</td>
                            <td>{item?.tournament_name}</td>
                            <td>{item?.event}</td>
                            <td>{item?.no_of_players}</td>
                            <td>{item?.paid_amount}</td>
                            <td>{item?.joined_date}</td>
                        </tr>
                    })}
                </tbody>
            </Table>

            {/* Player Details Modal */}
            <PlayerDetailsModal show={playerDetailsModal} handleClose={handlePlayerDetailsModal} receiptInfo={receiptInfo} />
        </CContainer>
    </div>
};

export default Withdraw;
