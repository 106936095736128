import { useState, useEffect } from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import classNames from "classnames";

// Custom Component
import OverviewSection from './OverviewSection'
import MyPlayer from './MyPlayer'
import MyEarning from './MyEarning'
import ErrorAlert from '../../components/Alert/ErrorAlert';

// Images
import { Logo } from '../../assets/images/svg'

// API Service
import { getSingleTournamentService } from '../../services/tournament.service';

const SingleTournament = () => {
    const [tournament, setTournament] = useState();
    const [selectedTab, setSelectedTab] = useState('overview');

    let { tournamentId } = useParams();

    /**
        * @function fetchSingleTournament
        * @params
        * @description fetch the single tournament details
    */
    const fetchSingleTournament = async () => {
        try {
            const result = await getSingleTournamentService({
                id: tournamentId
            });
            if (result?.data?.status) {
                setTournament(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchSingleTournament()
    }, [tournamentId])

    return <Container fluid>
        {tournament &&
            <>
                <div className='position-relative'>
                    <img className='img-fluid tournament-banner w-100' src={tournament?.banner_image} alt={tournament?.name} />
                </div>
                <Container fluid>
                    <Row>
                        <Col xs={12} lg={6} className='px-0'>
                            <Tabs
                                id="fill-tab-example"
                                className="mb-3 bg-gray p-1 rounded border-bottom-0"
                                activeKey={selectedTab}
                                onSelect={(k) => setSelectedTab(k)}
                                fill
                            >
                                <Tab eventKey="overview" title={<span className={classNames({ 'text-secondary': selectedTab !== "overview", 'text-orange': selectedTab === "overview" })}>Overview</span>}>
                                </Tab>
                                <Tab eventKey="players" title={<span className={classNames({ 'text-secondary': selectedTab !== "players", 'text-orange': selectedTab === "players" })}>Players</span>}>
                                </Tab>
                                <Tab eventKey="earnings" title={<span className={classNames({ 'text-secondary': selectedTab !== "earnings", 'text-orange': selectedTab === "earnings" })}>Earnings</span>}>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                    <div className='d-flex py-2 align-items-center'>
                        <div className='me-2'>
                            <Logo />
                        </div>
                        <span className='d-lg-none fs-16 fw-600'>{tournament?.name}</span>
                        <span className='d-none d-lg-block fs-22 fw-600'>{tournament?.name}</span>
                    </div>
                </Container>
                {selectedTab === 'overview' && <OverviewSection tournament={tournament} />}
                {selectedTab === 'players' && <MyPlayer tournament={tournament} />}
                {selectedTab === 'earnings' && <MyEarning tournament={tournament} />}
            </>
        }
    </Container>
};

export default SingleTournament;