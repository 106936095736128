import { useState, useEffect } from 'react'
import { CContainer, CCol, CRow, CCard, CCardHeader } from '@coreui/react'
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { FaTrashAlt, FaEye } from "react-icons/fa";
import moment from 'moment'

// Custom Component
import View from './View'
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';
import ConfirmAlert from '../../components/Alert/ConfirmAlert';

// API Service
import { allMessageService, deleteMessageService } from '../../services/contactus.service';

const Message = () => {
    const [messageList, setMessageList] = useState([])
    const [selectedData, setSelectedData] = useState(null);
    const [currentPage, setCurrentPage] = useState(0)
    const [totalEntry, setTotalEntry] = useState(1)
    const countPerPage = 10;

    const [editModal, setEditModal] = useState(false)

    const dispatch = useDispatch()
    const sidebarShow = useSelector((state) => state.sidebarShow)

    /**
        * @function fetchListedMessage
        * @params page
        * @description fetch the list of message
    */
    const fetchListedMessage = async (page) => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await allMessageService({
                paginate: 1,
                page: page + 1,
                perPage: 10,
                keyword: ""
            });
            if (result?.data?.status) {
                setMessageList(result?.data?.data?.customer_contact_us_lists?.data)
                setTotalEntry(result?.data?.data?.pagination?.total_pages)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    useEffect(() => {
        fetchListedMessage(currentPage)
    }, [])

    /**
        * @function handleViewModal
        * @params row
        * @description set the visibility of view modal
    */
    const handleViewModal = (row) => {
        setEditModal(!editModal)
        setSelectedData(row)
    }

    /**
        * @function deleteMessage
        * @params id
        * @description delete the message
    */
    const deleteMessage = async (id) => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await deleteMessageService(id);
            if (result?.data?.status) {
                SuccessAlert('Message deleted successfully')
                fetchListedMessage(currentPage)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    const columns = [
        {
            name: 'ID',
            selector: (row, index) => currentPage > 0 ? (currentPage * 10) + index + 1 : index + 1
        },
        {
            name: 'First Name',
            selector: (row) => row?.first_name ? row?.first_name : '--',
            sortable: true
        },
        {
            name: 'Last Name',
            selector: (row) => row?.last_name ? row?.last_name : '--',
            sortable: true
        },
        {
            name: 'Email',
            selector: (row) => row?.email ? row?.email : '--',
            sortable: true
        },
        {
            name: 'Message',
            selector: (row) => row?.message ? row?.message : '--',
            sortable: true
        },
        {
            name: 'Created Date',
            selector: (row) => moment(row?.created_at).format("DD-MM-YYYY")
        },
        {
            name: 'Actions',
            allowOverflow: true,
            selector: (row) => <>
                <FaTrashAlt role='button' className='text-danger me-2' size={20} onClick={() => ConfirmAlert(() => deleteMessage(row?.id))} />
                <FaEye role='button' className='text-orange' size={20} onClick={() => handleViewModal(row)} />
            </>
        }
    ]

    /**
        * @function handlePagination
        * @params page
        * @description used to handle Pagination
    */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
        fetchListedMessage(page.selected)
    }

    // ** Custom Pagination
    const CustomPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={totalEntry || 1}
            onPageChange={page => handlePagination(page)}
            containerClassName='pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
        />
    )

    return <div className="bg-light min-vh-100 d-flex flex-row">
        <CContainer fluid>
            <CRow>
                <CCol xs={12} className='px-0'>
                    <CCard className="mb-4">
                        <CCardHeader className='bg-transparent border-0 text-dark d-flex justify-content-between align-items-center'>
                            <h5>Contact Us Management</h5>
                        </CCardHeader>
                    </CCard>
                    <div className='react-dataTable react-dataTable-selectable-rows'>
                        <DataTable
                            noHeader
                            columns={columns}
                            className='react-dataTable'
                            data={messageList}
                            pagination
                            paginationServer
                            paginationTotalRows={totalEntry}
                            paginationPerPage={countPerPage}
                            paginationComponentOptions={{ noRowsPerPage: true }}
                            paginationComponent={CustomPagination}
                            paginationDefaultPage={currentPage + 1}
                        />
                    </div>
                </CCol>
            </CRow>
        </CContainer>

        {/* View Modal */}
        <View show={editModal} handleClose={handleViewModal} selectedData={selectedData} />
    </div>
};

export default Message;
